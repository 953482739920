import { all } from 'redux-saga/effects';

import appSaga from '@redux/app/saga';
import bundleSaga from '@redux/bundle/saga';
import courseSaga from '@redux/course/saga';
import degreeSaga from '@redux/degree/saga';
import enrollmentSaga from '@redux/enrollment/saga';
import interactiveContentSaga from '@redux/interactiveContent/saga';
import learningSaga from '@redux/learning/saga';
import manageUserSaga from '@redux/manageUser/saga';
import manageUserIdentitySaga from '@redux/manageUserIdentity/saga';
import universitySaga from '@redux/university/saga';
import userSaga from '@redux/user/saga';
import userIdentitySaga from '@redux/userIdentity/saga';

export default function* rootSaga() {
  yield all([
    appSaga(),
    courseSaga(),
    bundleSaga(),
    degreeSaga(),
    enrollmentSaga(),
    interactiveContentSaga(),
    learningSaga(),
    manageUserIdentitySaga(),
    manageUserSaga(),
    universitySaga(),
    userIdentitySaga(),
    userSaga(),
  ]);
}
