import Router from 'next/router';

import { all, call, takeEvery, put } from 'redux-saga/effects';

import actions from './actions';

import { ICoreApiResponse } from '@interfaces/common';
import {
  StartInteractionAnswerPayload,
  SubmitInteractionAnswerPayload,
  CloseInteractionAnswerPayload,
} from '@interfaces/learning';
import learning from 'src/api/learning';
import { ROUTES } from 'src/helpers/constants';
import { ResponseDataV2 } from 'src/helpers/httpClient';
import { HttpStatus } from 'src/types/common';

function* interactive_content_start_request(query: {
  type: string;
  interactiveContentId: number;
  payload: StartInteractionAnswerPayload;
}) {
  const { type, interactiveContentId, payload } = query;
  const response: ResponseDataV2<ICoreApiResponse<any, any>> = yield call(
    learning.startInteractionContent,
    interactiveContentId,
    payload,
  );

  if (response.data?.status === '000') {
    yield put({
      type: actions.INTERACTIVE_CONTENT_START_SUCCESS,
      data: response.data.data,
      status: response.data.status,
    });
  } else {
    yield put({
      type: actions.INTERACTIVE_CONTENT_START_ERROR,
      data: response.data?.data,
      status: response.data?.status || response.status,
    });
  }
}

function* interactive_content_submit_request(query: {
  type: string;
  interactiveContentId: number;
  interactiveContentSubmissionId: number;
  payload: SubmitInteractionAnswerPayload;
}) {
  const { interactiveContentId, interactiveContentSubmissionId, payload } = query;
  const response: ResponseDataV2<ICoreApiResponse<any, any>> = yield call(
    learning.submitInteractionContent,
    interactiveContentId,
    interactiveContentSubmissionId,
    payload,
  );

  if (response.data?.status === '000') {
    yield put({
      type: actions.INTERACTIVE_CONTENT_SUBMIT_SUCCESS,
      data: response.data.data,
      status: response.data.status,
    });
  } else {
    yield put({
      type: actions.INTERACTIVE_CONTENT_SUBMIT_ERROR,
      data: response.data?.data,
      status: response.data?.status || response.status,
    });
  }
}

function* interactive_content_send_answer_request(query: {
  type: string;
  interactiveContentId: number;
  interactiveContentSubmissionId: number;
  payload: SubmitInteractionAnswerPayload;
}) {
  const { interactiveContentId, interactiveContentSubmissionId, payload } = query;
  const response: ResponseDataV2<ICoreApiResponse<any, any>> = yield call(
    learning.sendAnswerInteractionContent,
    interactiveContentId,
    payload,
  );

  if (response.data?.status === '000') {
    yield put({
      type: actions.INTERACTIVE_CONTENT_SEND_ANSWER_SUCCESS,
      data: response.data.data,
      status: response.data.status,
    });
  } else {
    yield put({
      type: actions.INTERACTIVE_CONTENT_SEND_ANSWER_ERROR,
      data: response.data?.data,
      status: response.data?.status || response.status,
    });
  }
}

function* interactive_content_close_request(query: {
  type: string;
  interactiveContentId: number;
  interactiveContentSubmissionId: number;
  payload: CloseInteractionAnswerPayload;
}) {
  const { type, interactiveContentId, interactiveContentSubmissionId, payload } = query;
  const response: ResponseDataV2<ICoreApiResponse<any, any>> = yield call(
    learning.closeInteractionContent,
    interactiveContentId,
    interactiveContentSubmissionId,
    payload,
  );

  if (response.data?.status === '000') {
    yield put({
      type: actions.INTERACTIVE_CONTENT_CLOSE_SUCCESS,
      data: response.data.data,
      status: response.data.status,
    });
  } else {
    yield put({
      type: actions.INTERACTIVE_CONTENT_CLOSE_ERROR,
      data: response.data?.data,
      status: response.data?.status || response.status,
    });
  }
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.INTERACTIVE_CONTENT_START_REQUEST, interactive_content_start_request),
    takeEvery(actions.INTERACTIVE_CONTENT_SUBMIT_REQUEST, interactive_content_submit_request),
    takeEvery(actions.INTERACTIVE_CONTENT_SEND_ANSWER_REQUEST, interactive_content_send_answer_request),
    takeEvery(actions.INTERACTIVE_CONTENT_CLOSE_REQUEST, interactive_content_close_request),
  ]);
}
