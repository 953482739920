import { Record } from 'immutable';

import enrollmentActions from './actions';
import { EnrollmentStateType, EnrollmentReducerType } from './type';

import { defaultInitialState, failedState, loadingState, resetState, successStateWithData } from '@redux/state';

const initState = new (Record<EnrollmentStateType>({
  university_evaluation_results: [],
  university_evaluation_results_total: 0,
  university_evaluation_results_loading: false,
  university_evaluation_result_detail: null,
  update_evaluation_results_loading: false,
  courseGrades: [],
  createUserEnrollFree: defaultInitialState(),
}))();

export default function EnrollmentReducer(state = initState, { type, payload, error }: EnrollmentReducerType) {
  switch (type) {
    case enrollmentActions.FETCH_UNIVERSITY_EVALUATION_RESULT_LIST_REQUEST:
      return state.set('university_evaluation_results_loading', true);
    case enrollmentActions.FETCH_UNIVERSITY_EVALUATION_RESULT_LIST_SUCCESS:
      return state
        .set('university_evaluation_results', payload.items)
        .set('university_evaluation_results_total', payload.total)
        .set('university_evaluation_results_loading', false);
    case enrollmentActions.FETCH_UNIVERSITY_EVALUATION_RESULT_LIST_ERROR:
      return state
        .set('university_evaluation_results', [])
        .set('university_evaluation_results_total', 0)
        .set('university_evaluation_results_loading', false);

    case enrollmentActions.FETCH_UNIVERSITY_EVALUATION_RESULT_DETAIL_REQUEST:
      return state.set('university_evaluation_result_detail', null);
    case enrollmentActions.FETCH_UNIVERSITY_EVALUATION_RESULT_DETAIL_SUCCESS:
      return state.set('university_evaluation_result_detail', payload);
    case enrollmentActions.FETCH_UNIVERSITY_EVALUATION_RESULT_DETAIL_ERROR:
      return state.set('university_evaluation_result_detail', null);

    case enrollmentActions.FETCH_COURSE_GRADES_REQUEST:
      return state.set('courseGrades', []);
    case enrollmentActions.FETCH_COURSE_GRADES_SUCCESS:
      return state.set('courseGrades', payload);
    case enrollmentActions.FETCH_COURSE_GRADES_ERROR:
      return state.set('courseGrades', []);

    case enrollmentActions.UPDATE_UNIVERSITY_EVALUATION_RESULT_REQUEST:
      return state.set('update_evaluation_results_loading', true);
    case enrollmentActions.UPDATE_UNIVERSITY_EVALUATION_RESULT_SUCCESS:
      return state.set('update_evaluation_results_loading', false);
    case enrollmentActions.UPDATE_UNIVERSITY_EVALUATION_RESULT_ERROR:
      return state.set('update_evaluation_results_loading', false);

    case enrollmentActions.CREATE_USER_ENROLL_FREE_REQUEST:
      return loadingState(state, 'createUserEnrollFree', { shouldResetData: true });
    case enrollmentActions.CREATE_USER_ENROLL_FREE_SUCCESS:
      return successStateWithData(state, 'createUserEnrollFree', payload);
    case enrollmentActions.CREATE_USER_ENROLL_FREE_ERROR:
      return failedState(state, 'createUserEnrollFree', error);
    case enrollmentActions.CREATE_USER_ENROLL_FREE_RESET:
      return resetState(state, 'createUserEnrollFree');

    default:
      return state;
  }
}
