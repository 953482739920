import {
  FetchManageUserCourseIdentityLogsErrorAction,
  FetchManageUserCourseIdentityLogsRequestAction,
  FetchManageUserCourseIdentityLogsSuccessAction,
} from '@redux/manageUser/type/fetchManageUserCourseIdentityLogsType';
import {
  FetchManageUserIdentityListRequestAction,
  FetchManageUserIdentityListSuccessAction,
  FetchManageUserIdentityListErrorAction,
} from '@redux/manageUser/type/fetchManageUserIdentityListType';
import {
  FetchManageUserIdentityLogDetailErrorAction,
  FetchManageUserIdentityLogDetailRequestAction,
  FetchManageUserIdentityLogDetailSuccessAction,
} from '@redux/manageUser/type/fetchManageUserIdentityLogDetailType';
import {
  FetchManageUserIdentityLogSummaryErrorAction,
  FetchManageUserIdentityLogSummaryRequestAction,
  FetchManageUserIdentityLogSummarySuccessAction,
} from '@redux/manageUser/type/fetchManageUserIdentityLogSummaryType';
import {
  FetchManageUserIdentityLogErrorAction,
  FetchManageUserIdentityLogRequestAction,
  FetchManageUserIdentityLogSuccessAction,
} from '@redux/manageUser/type/fetchManageUserIdentityLogType';

const manageUserActions = {
  FETCH_MANAGE_USER_IDENTITY_LIST_REQUEST: 'MANAGE_USER/FETCH_MANAGE_USER_IDENTITY_LIST_REQUEST',
  FETCH_MANAGE_USER_IDENTITY_LIST_SUCCESS: 'MANAGE_USER/FETCH_MANAGE_USER_IDENTITY_LIST_SUCCESS',
  FETCH_MANAGE_USER_IDENTITY_LIST_ERROR: 'MANAGE_USER/FETCH_MANAGE_USER_IDENTITY_LIST_ERROR',
  FETCH_MANAGE_USER_IDENTITY_LIST_RESET: 'MANAGE_USER/FETCH_MANAGE_USER_IDENTITY_LIST_RESET',
  fetchManageUserIdentityListAction: {
    request: (payload: FetchManageUserIdentityListRequestAction) => ({
      type: manageUserActions.FETCH_MANAGE_USER_IDENTITY_LIST_REQUEST,
      payload,
    }),
    success: (payload: FetchManageUserIdentityListSuccessAction) => ({
      type: manageUserActions.FETCH_MANAGE_USER_IDENTITY_LIST_SUCCESS,
      payload,
    }),
    error: (error: FetchManageUserIdentityListErrorAction) => ({
      type: manageUserActions.FETCH_MANAGE_USER_IDENTITY_LIST_ERROR,
      error,
    }),
    reset: () => ({
      type: manageUserActions.FETCH_MANAGE_USER_IDENTITY_LIST_RESET,
    }),
  },

  FETCH_MANAGE_USER_COURSE_IDENTITY_LOGS_REQUEST: 'MANAGE_USER/FETCH_MANAGE_USER_COURSE_IDENTITY_LOGS_REQUEST',
  FETCH_MANAGE_USER_COURSE_IDENTITY_LOGS_SUCCESS: 'MANAGE_USER/FETCH_MANAGE_USER_COURSE_IDENTITY_LOGS_SUCCESS',
  FETCH_MANAGE_USER_COURSE_IDENTITY_LOGS_ERROR: 'MANAGE_USER/FETCH_MANAGE_USER_COURSE_IDENTITY_LOGS_ERROR',
  FETCH_MANAGE_USER_COURSE_IDENTITY_LOGS_RESET: 'MANAGE_USER/FETCH_MANAGE_USER_COURSE_IDENTITY_LOGS_RESET',
  fetchManageUserCourseIdentityLogsAction: {
    request: (payload: FetchManageUserCourseIdentityLogsRequestAction) => ({
      type: manageUserActions.FETCH_MANAGE_USER_COURSE_IDENTITY_LOGS_REQUEST,
      payload,
    }),
    success: (payload: FetchManageUserCourseIdentityLogsSuccessAction) => ({
      type: manageUserActions.FETCH_MANAGE_USER_COURSE_IDENTITY_LOGS_SUCCESS,
      payload,
    }),
    error: (error: FetchManageUserCourseIdentityLogsErrorAction) => ({
      type: manageUserActions.FETCH_MANAGE_USER_COURSE_IDENTITY_LOGS_ERROR,
      error,
    }),
    reset: () => ({
      type: manageUserActions.FETCH_MANAGE_USER_COURSE_IDENTITY_LOGS_RESET,
    }),
  },

  FETCH_MANAGE_USER_IDENTITY_LOG_REQUEST: 'MANAGE_USER/FETCH_MANAGE_USER_IDENTITY_LOG_REQUEST',
  FETCH_MANAGE_USER_IDENTITY_LOG_SUCCESS: 'MANAGE_USER/FETCH_MANAGE_USER_IDENTITY_LOG_SUCCESS',
  FETCH_MANAGE_USER_IDENTITY_LOG_ERROR: 'MANAGE_USER/FETCH_MANAGE_USER_IDENTITY_LOG_ERROR',
  FETCH_MANAGE_USER_IDENTITY_LOG_RESET: 'MANAGE_USER/FETCH_MANAGE_USER_IDENTITY_LOG_RESET',
  fetchManageUserIdentityLogAction: {
    request: (payload: FetchManageUserIdentityLogRequestAction) => ({
      type: manageUserActions.FETCH_MANAGE_USER_IDENTITY_LOG_REQUEST,
      payload,
    }),
    success: (payload: FetchManageUserIdentityLogSuccessAction) => ({
      type: manageUserActions.FETCH_MANAGE_USER_IDENTITY_LOG_SUCCESS,
      payload,
    }),
    error: (error: FetchManageUserIdentityLogErrorAction) => ({
      type: manageUserActions.FETCH_MANAGE_USER_IDENTITY_LOG_ERROR,
      error,
    }),
    reset: () => ({
      type: manageUserActions.FETCH_MANAGE_USER_IDENTITY_LOG_RESET,
    }),
  },

  FETCH_MANAGE_USER_IDENTITY_LOG_SUMMARY_REQUEST: 'MANAGE_USER/FETCH_MANAGE_USER_IDENTITY_LOG_SUMMARY_REQUEST',
  FETCH_MANAGE_USER_IDENTITY_LOG_SUMMARY_SUCCESS: 'MANAGE_USER/FETCH_MANAGE_USER_IDENTITY_LOG_SUMMARY_SUCCESS',
  FETCH_MANAGE_USER_IDENTITY_LOG_SUMMARY_ERROR: 'MANAGE_USER/FETCH_MANAGE_USER_IDENTITY_LOG_SUMMARY_ERROR',
  FETCH_MANAGE_USER_IDENTITY_LOG_SUMMARY_RESET: 'MANAGE_USER/FETCH_MANAGE_USER_IDENTITY_LOG_SUMMARY_RESET',
  fetchManageUserIdentityLogSummaryAction: {
    request: (payload: FetchManageUserIdentityLogSummaryRequestAction) => ({
      type: manageUserActions.FETCH_MANAGE_USER_IDENTITY_LOG_SUMMARY_REQUEST,
      payload,
    }),
    success: (payload: FetchManageUserIdentityLogSummarySuccessAction) => ({
      type: manageUserActions.FETCH_MANAGE_USER_IDENTITY_LOG_SUMMARY_SUCCESS,
      payload,
    }),
    error: (error: FetchManageUserIdentityLogSummaryErrorAction) => ({
      type: manageUserActions.FETCH_MANAGE_USER_IDENTITY_LOG_SUMMARY_ERROR,
      error,
    }),
    reset: () => ({
      type: manageUserActions.FETCH_MANAGE_USER_IDENTITY_LOG_SUMMARY_RESET,
    }),
  },

  FETCH_MANAGE_USER_IDENTITY_LOG_DETAIL_REQUEST: 'MANAGE_USER/FETCH_MANAGE_USER_IDENTITY_LOG_DETAIL_REQUEST',
  FETCH_MANAGE_USER_IDENTITY_LOG_DETAIL_SUCCESS: 'MANAGE_USER/FETCH_MANAGE_USER_IDENTITY_LOG_DETAIL_SUCCESS',
  FETCH_MANAGE_USER_IDENTITY_LOG_DETAIL_ERROR: 'MANAGE_USER/FETCH_MANAGE_USER_IDENTITY_LOG_DETAIL_ERROR',
  FETCH_MANAGE_USER_IDENTITY_LOG_DETAIL_RESET: 'MANAGE_USER/FETCH_MANAGE_USER_IDENTITY_LOG_DETAIL_RESET',
  fetchManageUserIdentityLogDetailAction: {
    request: (payload: FetchManageUserIdentityLogDetailRequestAction) => ({
      type: manageUserActions.FETCH_MANAGE_USER_IDENTITY_LOG_DETAIL_REQUEST,
      payload,
    }),
    success: (payload: FetchManageUserIdentityLogDetailSuccessAction) => ({
      type: manageUserActions.FETCH_MANAGE_USER_IDENTITY_LOG_DETAIL_SUCCESS,
      payload,
    }),
    error: (error: FetchManageUserIdentityLogDetailErrorAction) => ({
      type: manageUserActions.FETCH_MANAGE_USER_IDENTITY_LOG_DETAIL_ERROR,
      error,
    }),
    reset: () => ({
      type: manageUserActions.FETCH_MANAGE_USER_IDENTITY_LOG_DETAIL_RESET,
    }),
  },
};

export default manageUserActions;
