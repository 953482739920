import { Record } from 'immutable';

import Actions from './actions';
import { IUserState } from './interface';

const initState = new (Record<IUserState>({
  networkInfo: undefined,
  isPendingCreateOfficialProfile: false,
  isCreateOfficialProfileError: false,
  createOfficialProfileError: undefined,

  officialProfile: null,
  haveOfficialProfile: false,
  haveStudentId: false,
  isPendingGetOfficialProfile: false,
  isCreateOfficialProfileSuccess: false,
  isGetOfficialProfileError: false,
  getOfficialProfileError: undefined,

  countries: [],
  isPendingGetCountries: false,
  isGetCountriesError: false,
  getCountriesError: undefined,

  otp: undefined,
  otpType: undefined,
  isPendingGetOtp: false,
  isGetOtpError: false,
  getOtpError: undefined,

  myCertificates: [],
  getMyCertificatesError: undefined,
  myCertificateLoading: true,
}))();

export default function reducer(state = initState, { type, payload, status }: any) {
  switch (type) {
    case Actions.FETCH_IP_ADDRESS_SUCCESS:
      return state.set('networkInfo', payload);
    case Actions.FETCH_IP_ADDRESS_REQUEST:
    case Actions.FETCH_IP_ADDRESS_ERROR:
    case Actions.CLEAR_IP_ADDRESS:
      return state.set('networkInfo', undefined);
    case Actions.CLEAR_OTP:
      return state
        .set('otp', undefined)
        .set('otpType', undefined)
        .set('isPendingGetOtp', false)
        .set('isGetOtpError', false)
        .set('getOtpError', undefined);
    case Actions.GET_OTP:
      return state
        .set('isPendingGetOtp', true)
        .set('isGetOtpError', false)
        .set('getOtpError', undefined);
    case Actions.GET_OTP_ERROR:
      return state
        .set('isPendingGetOtp', false)
        .set('isGetOtpError', true)
        .set('getOtpError', status);
    case Actions.GET_OTP_SUCCESS:
      return state.set('isPendingGetOtp', false).set('otp', payload);
    case Actions.GET_COUNTRIES:
      return state
        .set('isPendingGetCountries', true)
        .set('isGetCountriesError', false)
        .set('getCountriesError', undefined);
    case Actions.GET_COUNTRIES_ERROR:
      return state
        .set('isPendingGetCountries', false)
        .set('isGetCountriesError', true)
        .set('getCountriesError', status);
    case Actions.GET_COUNTRIES_SUCCESS:
      return state.set('isPendingGetCountries', false).set('countries', payload);
    case Actions.GET_OFFICIAL_PROFILE:
      return state
        .set('isPendingGetOfficialProfile', true)
        .set('isGetOfficialProfileError', false)
        .set('getOfficialProfileError', undefined)
        .set('haveOfficialProfile', false)
        .set('haveStudentId', false);
    case Actions.GET_OFFICIAL_PROFILE_ERROR:
      return state
        .set('isPendingGetOfficialProfile', false)
        .set('isGetOfficialProfileError', true)
        .set('getOfficialProfileError', status);
    case Actions.GET_OFFICIAL_PROFILE_SUCCESS:
      return state
        .set('isPendingGetOfficialProfile', false)
        .set('officialProfile', payload)
        .set('haveOfficialProfile', payload.haveOfficialProfile)
        .set('haveStudentId', payload.haveStudentId);
    case Actions.CREATE_OFFICIAL_PROFILE:
      return state
        .set('isPendingCreateOfficialProfile', true)
        .set('isCreateOfficialProfileError', false)
        .set('isCreateOfficialProfileSuccess', false)
        .set('createOfficialProfileError', undefined);
    case Actions.CREATE_OFFICIAL_PROFILE_ERROR:
      return state
        .set('isPendingCreateOfficialProfile', false)
        .set('isCreateOfficialProfileError', true)
        .set('isCreateOfficialProfileSuccess', false)
        .set('createOfficialProfileError', status);
    case Actions.CREATE_OFFICIAL_PROFILE_SUCCESS:
      return state.set('isPendingCreateOfficialProfile', false).set('isCreateOfficialProfileSuccess', true);
    case Actions.GET_MY_CERTIFICATES:
      return state.set('myCertificateLoading', true);
    case Actions.GET_MY_CERTIFICATES_SUCCESS:
      return state.set('myCertificates', payload).set('myCertificateLoading', false);
    case Actions.GET_MY_CERTIFICATES_ERROR:
      return state.set('myCertificates', []).set('myCertificateLoading', false);

    default:
      return state;
  }
}
