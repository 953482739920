import config from 'src/config';
import Http from 'src/helpers/httpClient';

export default {
  fetchAppData() {
    const apiUrl = `${config.API_DOMAIN}/v1/app-data`;
    return Http.get(apiUrl, { isPublic: true });
  },

  fetchB2BContentStatistic() {
    const apiUrl = `${config.API_DOMAIN}/v1/b2b-content-statistic`;
    return Http.get(apiUrl, { isPublic: true });
  },

  fetchWatermarkPeriod() {
    const apiUrl = `${config.API_DOMAIN}/v1/watermark-period`;
    return Http.get(apiUrl, { isPublic: true });
  },
};
