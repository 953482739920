export type CoreApiResponse<TData, TStatus extends string> = {
  status: TStatus;
  message: string;
  timestamp: number;
  data: TData;
};

export type CoreApiStatusResponse = typeof ApiStatusResponse.SUCCESS | string;

export const ApiStatusResponse = {
  SUCCESS: '000',
} as const;
