import React, { FC, ReactNode, useMemo } from 'react';

import { Box, Button, Typography, useTheme } from '@mui/material';

import BackgroundImage from './BackgroundImage';

export type ErrorPageProps = {
  title: ReactNode;
  subtitle?: ReactNode;
  errorCode?: ReactNode;
  extra?: ReactNode;
  buttonTitle: string;
  buttonAction: () => void;
  backgroundImageUrl?: string;
};

const ErrorPage: FC<ErrorPageProps> = (props) => {
  const { title, subtitle, errorCode, extra, buttonTitle, buttonAction, backgroundImageUrl } = props;

  const theme = useTheme();
  const handleClick = () => {
    buttonAction?.();
  };

  const renderTitle = useMemo(() => {
    if (typeof title === 'string') {
      return (
        <Typography variant="h4" textAlign="center" color={theme.palette.textV2.secondary}>
          {title}
        </Typography>
      );
    }
    return title;
  }, [title]);

  const renderSubTitle = useMemo(() => {
    if (!subtitle) {
      return null;
    }

    if (typeof subtitle === 'string') {
      return (
        <Typography variant="h4" textAlign="center" color={theme.palette.textV2.secondary}>
          {subtitle}
        </Typography>
      );
    }
    return subtitle;
  }, [subtitle]);

  const renderErrorCode = useMemo(() => {
    if (typeof errorCode === 'string') {
      return (
        <Typography variant="subtitle1" textAlign="center" color={theme.palette.textV2.secondary}>
          {errorCode}
        </Typography>
      );
    }
    return errorCode;
  }, [errorCode]);

  const renderExtra = useMemo(() => {
    if (!extra) return null;

    if (typeof extra === 'string') {
      return (
        <Typography variant="subtitle1" textAlign="center" color={theme.palette.textV2.secondary}>
          {extra}
        </Typography>
      );
    }
    return extra;
  }, [extra]);

  const renderBackgroundImage = useMemo(() => {
    if (!backgroundImageUrl) return null;

    return <BackgroundImage imgUrl={backgroundImageUrl} />;
  }, [backgroundImageUrl]);

  return (
    <>
      <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginTop: { md: 6, xs: 3 }, mx: 3 }}>
        {renderTitle}

        <Box display="flex" flexDirection="column" gap={1}>
          {renderSubTitle}
          {renderErrorCode}
        </Box>
        {renderExtra}
        <Button
          variant="outlined"
          sx={{
            padding: '8px 16px 8px 16px',
            marginTop: 3,
            backgroundColor: '#fff',
            '&:focus': {
              backgroundColor: '#fff',
            },
            '&:hover': {
              backgroundColor: '#fff',
            },
          }}
          onClick={handleClick}
        >
          {buttonTitle}
        </Button>
      </Box>

      {renderBackgroundImage}
    </>
  );
};

export default ErrorPage;
