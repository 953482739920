import {
  FetchCourseDetailGlancesErrorAction,
  FetchCourseDetailGlancesRequestAction,
  FetchCourseDetailGlancesSuccessAction,
  FetchCourseDetailErrorAction,
  FetchCourseDetailRequestAction,
  FetchCourseDetailSeoErrorAction,
  FetchCourseDetailSeoSuccessAction,
  FetchCourseDetailSuccessAction,
  FetchCourseEnrollmentErrorAction,
  FetchCourseEnrollmentRequestAction,
  FetchCourseEnrollmentSuccessAction,
  FetchCourseDetailCurriculumRequestAction,
  FetchCourseDetailCurriculumSuccessAction,
  FetchCourseDetailCurriculumErrorAction,
  FetchCourseReviewListRequestAction,
  FetchCourseReviewListSuccessAction,
  FetchCourseReviewListErrorAction,
  FetchCourseReviewSummaryRequestAction,
  FetchCourseReviewSummarySuccessAction,
  FetchCourseReviewSummaryErrorAction,
  CreateOrUpdateCourseReviewRequestAction,
  CreateOrUpdateCourseReviewSuccessAction,
  CreateOrUpdateCourseReviewErrorAction,
  FetchCourseReviewMeRequestAction,
  FetchCourseReviewMeSuccessAction,
  FetchCourseReviewMeErrorAction,
  FetchCourseDiscussionListSuccessAction,
  FetchCourseDiscussionListErrorAction,
  FetchCourseDiscussionListRequestAction,
  CreateCourseDiscussionRequestAction,
  CreateCourseDiscussionErrorAction,
  CreateCourseDiscussionSuccessAction,
  AddCourseDiscussionReplyRequestAction,
  AddCourseDiscussionReplySuccessAction,
  AddCourseDiscussionReplyErrorAction,
  FetchCourseSuggestionsRequestAction,
  FetchCourseSuggestionsSuccessAction,
  FetchCourseSuggestionsErrorAction,
  FetchCourseDetailSeoRequestAction,
} from '@redux/course/type/courseDetailType';
import {
  FetchCourseSeminarErrorAction,
  FetchCourseSeminarRequestAction,
  FetchCourseSeminarSuccessAction,
} from '@redux/course/type/seminarType';

const courseActions = {
  ADD_COURSE_API_QUERY: 'ADD_COURSE_API_QUERY',
  SET_COURSE_API_SORT_ORDER: 'SET_COURSE_API_SORT_ORDER',
  CLEAR_COURSE_API_QUERY: 'CLEAR_COURSE_API_QUERY',
  CLEAR_COURSE_FILTER: 'CLEAR_COURSE_FILTER',

  add_course_api_query: (queryString: any) => ({
    type: courseActions.ADD_COURSE_API_QUERY,
    payload: queryString,
  }),

  set_course_api_sort_order: (sortOrder: any) => ({
    type: courseActions.SET_COURSE_API_SORT_ORDER,
    payload: sortOrder,
  }),

  clear_course_api_query: () => ({
    type: courseActions.CLEAR_COURSE_API_QUERY,
  }),

  clear_course_filter: () => ({
    type: courseActions.CLEAR_COURSE_FILTER,
  }),

  FETCH_COURSE_DETAIL_SEO_REQUEST: 'COURSE_DETAIL/FETCH_COURSE_DETAIL_SEO_REQUEST',
  FETCH_COURSE_DETAIL_SEO_SUCCESS: 'COURSE_DETAIL/FETCH_COURSE_DETAIL_SEO_SUCCESS',
  FETCH_COURSE_DETAIL_SEO_ERROR: 'COURSE_DETAIL/FETCH_COURSE_DETAIL_SEO_ERROR',
  FETCH_COURSE_DETAIL_SEO_RESET: 'COURSE_DETAIL/FETCH_COURSE_DETAIL_SEO_RESET',
  fetchCourseDetailSeoAction: {
    request: (payload: FetchCourseDetailSeoRequestAction) => ({
      type: courseActions.FETCH_COURSE_DETAIL_SEO_REQUEST,
      payload,
    }),
    success: (payload: FetchCourseDetailSeoSuccessAction) => ({
      type: courseActions.FETCH_COURSE_DETAIL_SEO_SUCCESS,
      payload,
    }),
    error: (payload: FetchCourseDetailSeoErrorAction) => ({
      type: courseActions.FETCH_COURSE_DETAIL_SEO_ERROR,
      payload,
    }),
    reset: () => ({
      type: courseActions.FETCH_COURSE_DETAIL_SEO_RESET,
    }),
  },

  FETCH_COURSE_DETAIL_REQUEST: 'COURSE_DETAIL/FETCH_COURSE_DETAIL_REQUEST',
  FETCH_COURSE_DETAIL_SUCCESS: 'COURSE_DETAIL/FETCH_COURSE_DETAIL_SUCCESS',
  FETCH_COURSE_DETAIL_ERROR: 'COURSE_DETAIL/FETCH_COURSE_DETAIL_ERROR',
  FETCH_COURSE_DETAIL_RESET: 'COURSE_DETAIL/FETCH_COURSE_DETAIL_RESET',
  fetchCourseDetailAction: {
    request: (payload: FetchCourseDetailRequestAction) => ({
      type: courseActions.FETCH_COURSE_DETAIL_REQUEST,
      payload,
    }),
    success: (payload: FetchCourseDetailSuccessAction) => ({
      type: courseActions.FETCH_COURSE_DETAIL_SUCCESS,
      payload,
    }),
    error: (payload: FetchCourseDetailErrorAction) => ({
      type: courseActions.FETCH_COURSE_DETAIL_ERROR,
      payload,
    }),
    reset: () => ({
      type: courseActions.FETCH_COURSE_DETAIL_RESET,
    }),
  },

  FETCH_COURSE_ENROLLMENT_REQUEST: 'COURSE_DETAIL/FETCH_COURSE_ENROLLMENT_REQUEST',
  FETCH_COURSE_ENROLLMENT_SUCCESS: 'COURSE_DETAIL/FETCH_COURSE_ENROLLMENT_SUCCESS',
  FETCH_COURSE_ENROLLMENT_ERROR: 'COURSE_DETAIL/FETCH_COURSE_ENROLLMENT_ERROR',
  FETCH_COURSE_ENROLLMENT_RESET: 'COURSE_DETAIL/FETCH_COURSE_ENROLLMENT_RESET',
  fetchCourseEnrollmentAction: {
    request: (payload: FetchCourseEnrollmentRequestAction) => ({
      type: courseActions.FETCH_COURSE_ENROLLMENT_REQUEST,
      payload,
    }),
    success: (payload: FetchCourseEnrollmentSuccessAction) => ({
      type: courseActions.FETCH_COURSE_ENROLLMENT_SUCCESS,
      payload,
    }),
    error: (payload: FetchCourseEnrollmentErrorAction) => ({
      type: courseActions.FETCH_COURSE_ENROLLMENT_ERROR,
      payload,
    }),
    reset: () => ({
      type: courseActions.FETCH_COURSE_ENROLLMENT_RESET,
    }),
  },

  FETCH_COURSE_DETAIL_GLANCES_REQUEST: 'COURSE_DETAIL/FETCH_COURSE_DETAIL_GLANCES_REQUEST',
  FETCH_COURSE_DETAIL_GLANCES_SUCCESS: 'COURSE_DETAIL/FETCH_COURSE_DETAIL_GLANCES_SUCCESS',
  FETCH_COURSE_DETAIL_GLANCES_ERROR: 'COURSE_DETAIL/FETCH_COURSE_DETAIL_GLANCES_ERROR',
  FETCH_COURSE_DETAIL_GLANCES_RESET: 'COURSE_DETAIL/FETCH_COURSE_DETAIL_GLANCES_RESET',
  fetchCourseDetailGlancesAction: {
    request: (payload: FetchCourseDetailGlancesRequestAction) => ({
      type: courseActions.FETCH_COURSE_DETAIL_GLANCES_REQUEST,
      payload,
    }),
    success: (payload: FetchCourseDetailGlancesSuccessAction) => ({
      type: courseActions.FETCH_COURSE_DETAIL_GLANCES_SUCCESS,
      payload,
    }),
    error: (payload: FetchCourseDetailGlancesErrorAction) => ({
      type: courseActions.FETCH_COURSE_DETAIL_GLANCES_ERROR,
      payload,
    }),
    reset: () => ({
      type: courseActions.FETCH_COURSE_DETAIL_GLANCES_RESET,
    }),
  },

  FETCH_COURSE_DETAIL_CURRICULUM_REQUEST: 'COURSE_DETAIL/FETCH_COURSE_DETAIL_CURRICULUM_REQUEST',
  FETCH_COURSE_DETAIL_CURRICULUM_SUCCESS: 'COURSE_DETAIL/FETCH_COURSE_DETAIL_CURRICULUM_SUCCESS',
  FETCH_COURSE_DETAIL_CURRICULUM_ERROR: 'COURSE_DETAIL/FETCH_COURSE_DETAIL_CURRICULUM_ERROR',
  FETCH_COURSE_DETAIL_CURRICULUM_RESET: 'COURSE_DETAIL/FETCH_COURSE_DETAIL_CURRICULUM_RESET',
  fetchCourseDetailCurriculumAction: {
    request: (payload: FetchCourseDetailCurriculumRequestAction) => ({
      type: courseActions.FETCH_COURSE_DETAIL_CURRICULUM_REQUEST,
      payload,
    }),
    success: (payload: FetchCourseDetailCurriculumSuccessAction) => ({
      type: courseActions.FETCH_COURSE_DETAIL_CURRICULUM_SUCCESS,
      payload,
    }),
    error: (payload: FetchCourseDetailCurriculumErrorAction) => ({
      type: courseActions.FETCH_COURSE_DETAIL_CURRICULUM_ERROR,
      payload,
    }),
    reset: () => ({
      type: courseActions.FETCH_COURSE_DETAIL_CURRICULUM_RESET,
    }),
  },

  FETCH_COURSE_DETAIL_REVIEW_LIST_REQUEST: 'COURSE_DETAIL/FETCH_COURSE_DETAIL_REVIEW_LIST_REQUEST',
  FETCH_COURSE_DETAIL_REVIEW_LIST_SUCCESS: 'COURSE_DETAIL/FETCH_COURSE_DETAIL_REVIEW_LIST_SUCCESS',
  FETCH_COURSE_DETAIL_REVIEW_LIST_ERROR: 'COURSE_DETAIL/FETCH_COURSE_DETAIL_REVIEW_LIST_ERROR',
  FETCH_COURSE_DETAIL_REVIEW_LIST_RESET: 'COURSE_DETAIL/FETCH_COURSE_DETAIL_REVIEW_LIST_RESET',
  fetchCourseReviewListAction: {
    request: (payload: FetchCourseReviewListRequestAction) => ({
      type: courseActions.FETCH_COURSE_DETAIL_REVIEW_LIST_REQUEST,
      payload,
    }),
    success: (payload: FetchCourseReviewListSuccessAction) => ({
      type: courseActions.FETCH_COURSE_DETAIL_REVIEW_LIST_SUCCESS,
      payload,
    }),
    error: (payload: FetchCourseReviewListErrorAction) => ({
      type: courseActions.FETCH_COURSE_DETAIL_REVIEW_LIST_ERROR,
      payload,
    }),
    reset: () => ({
      type: courseActions.FETCH_COURSE_DETAIL_REVIEW_LIST_RESET,
    }),
  },

  FETCH_COURSE_DETAIL_REVIEW_ME_REQUEST: 'COURSE_DETAIL/FETCH_COURSE_DETAIL_REVIEW_ME_REQUEST',
  FETCH_COURSE_DETAIL_REVIEW_ME_SUCCESS: 'COURSE_DETAIL/FETCH_COURSE_DETAIL_REVIEW_ME_SUCCESS',
  FETCH_COURSE_DETAIL_REVIEW_ME_ERROR: 'COURSE_DETAIL/FETCH_COURSE_DETAIL_REVIEW_ME_ERROR',
  FETCH_COURSE_DETAIL_REVIEW_ME_RESET: 'COURSE_DETAIL/FETCH_COURSE_DETAIL_REVIEW_ME_RESET',
  fetchCourseReviewMeAction: {
    request: (payload: FetchCourseReviewMeRequestAction) => ({
      type: courseActions.FETCH_COURSE_DETAIL_REVIEW_ME_REQUEST,
      payload,
    }),
    success: (payload: FetchCourseReviewMeSuccessAction) => ({
      type: courseActions.FETCH_COURSE_DETAIL_REVIEW_ME_SUCCESS,
      payload,
    }),
    error: (payload: FetchCourseReviewMeErrorAction) => ({
      type: courseActions.FETCH_COURSE_DETAIL_REVIEW_ME_ERROR,
      payload,
    }),
    reset: () => ({
      type: courseActions.FETCH_COURSE_DETAIL_REVIEW_ME_RESET,
    }),
  },

  FETCH_COURSE_DETAIL_REVIEW_SUMMARY_REQUEST: 'COURSE_DETAIL/FETCH_COURSE_DETAIL_REVIEW_SUMMARY_REQUEST',
  FETCH_COURSE_DETAIL_REVIEW_SUMMARY_SUCCESS: 'COURSE_DETAIL/FETCH_COURSE_DETAIL_REVIEW_SUMMARY_SUCCESS',
  FETCH_COURSE_DETAIL_REVIEW_SUMMARY_ERROR: 'COURSE_DETAIL/FETCH_COURSE_DETAIL_REVIEW_SUMMARY_ERROR',
  FETCH_COURSE_DETAIL_REVIEW_SUMMARY_RESET: 'COURSE_DETAIL/FETCH_COURSE_DETAIL_REVIEW_SUMMARY_RESET',
  fetchCourseReviewSummaryAction: {
    request: (payload: FetchCourseReviewSummaryRequestAction) => ({
      type: courseActions.FETCH_COURSE_DETAIL_REVIEW_SUMMARY_REQUEST,
      payload,
    }),
    success: (payload: FetchCourseReviewSummarySuccessAction) => ({
      type: courseActions.FETCH_COURSE_DETAIL_REVIEW_SUMMARY_SUCCESS,
      payload,
    }),
    error: (payload: FetchCourseReviewSummaryErrorAction) => ({
      type: courseActions.FETCH_COURSE_DETAIL_REVIEW_SUMMARY_ERROR,
      payload,
    }),
    reset: () => ({
      type: courseActions.FETCH_COURSE_DETAIL_REVIEW_SUMMARY_RESET,
    }),
  },

  CREATE_OR_UPDATE_COURSE_DETAIL_REVIEW_REQUEST: 'COURSE_DETAIL/CREATE_OR_UPDATE_COURSE_DETAIL_REVIEW_REQUEST',
  CREATE_OR_UPDATE_COURSE_DETAIL_REVIEW_SUCCESS: 'COURSE_DETAIL/CREATE_OR_UPDATE_COURSE_DETAIL_REVIEW_SUCCESS',
  CREATE_OR_UPDATE_COURSE_DETAIL_REVIEW_ERROR: 'COURSE_DETAIL/CREATE_OR_UPDATE_COURSE_DETAIL_REVIEW_ERROR',
  CREATE_OR_UPDATE_COURSE_DETAIL_REVIEW_RESET: 'COURSE_DETAIL/CREATE_OR_UPDATE_COURSE_DETAIL_REVIEW_RESET',
  createOrUpdateCourseReviewAction: {
    request: (payload: CreateOrUpdateCourseReviewRequestAction) => ({
      type: courseActions.CREATE_OR_UPDATE_COURSE_DETAIL_REVIEW_REQUEST,
      payload,
    }),
    success: (payload: CreateOrUpdateCourseReviewSuccessAction) => ({
      type: courseActions.CREATE_OR_UPDATE_COURSE_DETAIL_REVIEW_SUCCESS,
      payload,
    }),
    error: (payload: CreateOrUpdateCourseReviewErrorAction) => ({
      type: courseActions.CREATE_OR_UPDATE_COURSE_DETAIL_REVIEW_ERROR,
      payload,
    }),
    reset: () => ({
      type: courseActions.CREATE_OR_UPDATE_COURSE_DETAIL_REVIEW_RESET,
    }),
  },

  FETCH_COURSE_DETAIL_DISCUSSION_LIST_REQUEST: 'COURSE_DETAIL/FETCH_COURSE_DETAIL_DISCUSSION_LIST_REQUEST',
  FETCH_COURSE_DETAIL_DISCUSSION_LIST_SUCCESS: 'COURSE_DETAIL/FETCH_COURSE_DETAIL_DISCUSSION_LIST_SUCCESS',
  FETCH_COURSE_DETAIL_DISCUSSION_LIST_ERROR: 'COURSE_DETAIL/FETCH_COURSE_DETAIL_DISCUSSION_LIST_ERROR',
  FETCH_COURSE_DETAIL_DISCUSSION_LIST_RESET: 'COURSE_DETAIL/FETCH_COURSE_DETAIL_DISCUSSION_LIST_RESET',
  fetchCourseDiscussionListAction: {
    request: (payload: FetchCourseDiscussionListRequestAction) => ({
      type: courseActions.FETCH_COURSE_DETAIL_DISCUSSION_LIST_REQUEST,
      payload,
    }),
    success: (payload: FetchCourseDiscussionListSuccessAction) => ({
      type: courseActions.FETCH_COURSE_DETAIL_DISCUSSION_LIST_SUCCESS,
      payload,
    }),
    error: (payload: FetchCourseDiscussionListErrorAction) => ({
      type: courseActions.FETCH_COURSE_DETAIL_DISCUSSION_LIST_ERROR,
      payload,
    }),
    reset: () => ({
      type: courseActions.FETCH_COURSE_DETAIL_DISCUSSION_LIST_RESET,
    }),
  },

  CREATE_COURSE_DISCUSSION_REQUEST: 'COURSE_DETAIL/CREATE_COURSE_DISCUSSION_REQUEST',
  CREATE_COURSE_DISCUSSION_SUCCESS: 'COURSE_DETAIL/CREATE_COURSE_DISCUSSION_SUCCESS',
  CREATE_COURSE_DISCUSSION_ERROR: 'COURSE_DETAIL/CREATE_COURSE_DISCUSSION_ERROR',
  CREATE_COURSE_DISCUSSION_RESET: 'COURSE_DETAIL/CREATE_COURSE_DISCUSSION_RESET',
  createCourseDiscussionAction: {
    request: (payload: CreateCourseDiscussionRequestAction) => ({
      type: courseActions.CREATE_COURSE_DISCUSSION_REQUEST,
      payload,
    }),
    success: (payload: CreateCourseDiscussionSuccessAction) => ({
      type: courseActions.CREATE_COURSE_DISCUSSION_SUCCESS,
      payload,
    }),
    error: (payload: CreateCourseDiscussionErrorAction) => ({
      type: courseActions.CREATE_COURSE_DISCUSSION_ERROR,
      payload,
    }),
    reset: () => ({
      type: courseActions.CREATE_COURSE_DISCUSSION_RESET,
    }),
  },

  ADD_COURSE_DISCUSSION_REPLY_REQUEST: 'COURSE_DETAIL/ADD_COURSE_DISCUSSION_REPLY_REQUEST',
  ADD_COURSE_DISCUSSION_REPLY_SUCCESS: 'COURSE_DETAIL/ADD_COURSE_DISCUSSION_REPLY_SUCCESS',
  ADD_COURSE_DISCUSSION_REPLY_ERROR: 'COURSE_DETAIL/ADD_COURSE_DISCUSSION_REPLY_ERROR',
  ADD_COURSE_DISCUSSION_REPLY_RESET: 'COURSE_DETAIL/ADD_COURSE_DISCUSSION_REPLY_RESET',
  addCourseDiscussionReplyAction: {
    request: (payload: AddCourseDiscussionReplyRequestAction) => ({
      type: courseActions.ADD_COURSE_DISCUSSION_REPLY_REQUEST,
      payload,
    }),
    success: (payload: AddCourseDiscussionReplySuccessAction) => ({
      type: courseActions.ADD_COURSE_DISCUSSION_REPLY_SUCCESS,
      payload,
    }),
    error: (payload: AddCourseDiscussionReplyErrorAction) => ({
      type: courseActions.ADD_COURSE_DISCUSSION_REPLY_ERROR,
      payload,
    }),
    reset: () => ({
      type: courseActions.ADD_COURSE_DISCUSSION_REPLY_RESET,
    }),
  },

  FETCH_COURSE_SEMINAR_REQUEST: 'COURSE_DETAIL/FETCH_COURSE_SEMINAR_REQUEST',
  FETCH_COURSE_SEMINAR_SUCCESS: 'COURSE_DETAIL/FETCH_COURSE_SEMINAR_SUCCESS',
  FETCH_COURSE_SEMINAR_ERROR: 'COURSE_DETAIL/FETCH_COURSE_SEMINAR_ERROR',
  FETCH_COURSE_SEMINAR_RESET: 'COURSE_DETAIL/FETCH_COURSE_SEMINAR_RESET',
  fetchCourseSeminarAction: {
    request: (payload: FetchCourseSeminarRequestAction) => ({
      type: courseActions.FETCH_COURSE_SEMINAR_REQUEST,
      payload,
    }),
    success: (payload: FetchCourseSeminarSuccessAction) => ({
      type: courseActions.FETCH_COURSE_SEMINAR_SUCCESS,
      payload,
    }),
    error: (payload: FetchCourseSeminarErrorAction) => ({
      type: courseActions.FETCH_COURSE_SEMINAR_ERROR,
      payload,
    }),
    reset: () => ({
      type: courseActions.FETCH_COURSE_SEMINAR_RESET,
    }),
  },

  FETCH_COURSE_SUGGESTIONS_REQUEST: 'COURSE_DETAIL/FETCH_COURSE_SUGGESTIONS_REQUEST',
  FETCH_COURSE_SUGGESTIONS_SUCCESS: 'COURSE_DETAIL/FETCH_COURSE_SUGGESTIONS_SUCCESS',
  FETCH_COURSE_SUGGESTIONS_ERROR: 'COURSE_DETAIL/FETCH_COURSE_SUGGESTIONS_ERROR',
  FETCH_COURSE_SUGGESTIONS_RESET: 'COURSE_DETAIL/FETCH_COURSE_SUGGESTIONS_RESET',
  fetchCourseSuggestionsAction: {
    request: (payload: FetchCourseSuggestionsRequestAction) => ({
      type: courseActions.FETCH_COURSE_SUGGESTIONS_REQUEST,
      payload,
    }),
    success: (payload: FetchCourseSuggestionsSuccessAction) => ({
      type: courseActions.FETCH_COURSE_SUGGESTIONS_SUCCESS,
      payload,
    }),
    error: (payload: FetchCourseSuggestionsErrorAction) => ({
      type: courseActions.FETCH_COURSE_SUGGESTIONS_ERROR,
      payload,
    }),
    reset: () => ({
      type: courseActions.FETCH_COURSE_SUGGESTIONS_RESET,
    }),
  },
};
export default courseActions;
