import { Record } from 'immutable';

import actions from './actions';

import { WATERMARK_PERIOD_SECOND } from 'src/helpers/constants';

const B2B_CONTENT_STATISTIC_INITIAL_DATA = {
  course_online: 0,
  leading_experts: 0,
  content_category: 0,
};

export type B2BContentStatisticType = {
  course_online: number;
  leading_experts: number;
  content_category: number;
};

export type AppStateType = {
  categories: [];
  logo: any;
  isLoading: boolean;
  isLoadingCoverPhoto: boolean;
  isLoadingFirstPage: boolean;
  b2bContentStatistic: B2BContentStatisticType;
  isUserMenuDrawerOpen: boolean;
  isNotificationDialogOpen: boolean;
  isAdminMenuSiderCollapsed: boolean;
  isAdminMenuSiderOpenDrawer: boolean;
  watermarkPeriod: number;
  isDialogOpen: boolean;
};

type AppReducerType = {
  state?: AppStateType;
  type?: string;
  payload?: any;
};

const initState = new (Record<AppStateType>({
  isLoading: false,
  isLoadingCoverPhoto: false,
  isLoadingFirstPage: true,
  categories: [],
  logo: null,
  b2bContentStatistic: B2B_CONTENT_STATISTIC_INITIAL_DATA,
  isUserMenuDrawerOpen: false,
  isNotificationDialogOpen: false,
  isAdminMenuSiderCollapsed: false,
  isAdminMenuSiderOpenDrawer: false,
  watermarkPeriod: WATERMARK_PERIOD_SECOND,
  isDialogOpen: false,
}))();

export default function AppReducer(state = initState, { type, payload }: AppReducerType) {
  switch (type) {
    case actions.FETCH_APP_DATA_REQUEST:
      return state.set('isLoading', true);
    case actions.FETCH_APP_DATA_SUCCESS:
      return state
        .set('isLoading', false)
        .set('categories', payload.categories)
        .set('logo', payload.logo);
    case actions.FETCH_APP_DATA_ERROR:
      return state.set('isLoading', false);

    case actions.FETCH_B2B_CONTENT_STATISTIC_REQUEST:
    case actions.FETCH_B2B_CONTENT_STATISTIC_ERROR:
      return state.set('b2bContentStatistic', B2B_CONTENT_STATISTIC_INITIAL_DATA);
    case actions.FETCH_B2B_CONTENT_STATISTIC_SUCCESS:
      return state.set('b2bContentStatistic', payload);

    case actions.FETCH_WATERMARK_PERIOD_REQUEST:
    case actions.FETCH_WATERMARK_PERIOD_ERROR:
      return state.set('watermarkPeriod', WATERMARK_PERIOD_SECOND);
    case actions.FETCH_WATERMARK_PERIOD_SUCCESS:
      return state.set('watermarkPeriod', payload);

    case actions.SET_IS_USER_MENU_DRAWER_OPEN:
      return state.set('isUserMenuDrawerOpen', payload);

    case actions.TOGGLE_ADMIN_MENU_SIDER_COLLAPSED:
      return state.set('isAdminMenuSiderCollapsed', payload.isCollapsed);
    case actions.TOGGLE_ADMIN_MENU_SIDER_OPEN_DRAWER:
      return state.set('isAdminMenuSiderOpenDrawer', payload.isOpenDrawer);

    case actions.SET_IS_NOTIFICATION_DIALOG_OPEN:
      return state.set('isNotificationDialogOpen', payload);

    case actions.SET_IS_LOADING_FIRST_PAGE:
      return state.set('isLoadingFirstPage', payload);
    case actions.SET_IS_DIALOG_OPEN:
      return state.set('isDialogOpen', payload);
    default:
      return state;
  }
}
