import { Record } from 'immutable';

import manageUserActions from '@redux/manageUser/action';
import { ManageUserReduxState } from '@redux/manageUser/type/state';
import { defaultInitialState, failedState, loadingState, resetState, successStateWithData } from '@redux/state';

const initManageUserState = new (Record<ManageUserReduxState>({
  fetchManageUserIdentityListState: defaultInitialState(),
  fetchManageUserCourseIdentityLogsState: defaultInitialState(),
  fetchManageUserIdentityLogState: defaultInitialState(),
  fetchManageUserIdentityLogSummaryState: defaultInitialState(),
  fetchManageUserIdentityLogDetailState: defaultInitialState(),
}))();

export default function manageUserReducer(state = initManageUserState, { type, payload, error }: any) {
  switch (type) {
    case manageUserActions.FETCH_MANAGE_USER_IDENTITY_LIST_REQUEST:
      return loadingState(state, 'fetchManageUserIdentityListState');
    case manageUserActions.FETCH_MANAGE_USER_IDENTITY_LIST_SUCCESS:
      return successStateWithData(state, 'fetchManageUserIdentityListState', payload);
    case manageUserActions.FETCH_MANAGE_USER_IDENTITY_LIST_ERROR:
      return failedState(state, 'fetchManageUserIdentityListState', error);
    case manageUserActions.FETCH_MANAGE_USER_IDENTITY_LIST_RESET:
      return resetState(state, 'fetchManageUserIdentityListState');

    case manageUserActions.FETCH_MANAGE_USER_COURSE_IDENTITY_LOGS_REQUEST:
      return loadingState(state, 'fetchManageUserCourseIdentityLogsState');
    case manageUserActions.FETCH_MANAGE_USER_COURSE_IDENTITY_LOGS_SUCCESS:
      return successStateWithData(state, 'fetchManageUserCourseIdentityLogsState', payload);
    case manageUserActions.FETCH_MANAGE_USER_COURSE_IDENTITY_LOGS_ERROR:
      return failedState(state, 'fetchManageUserCourseIdentityLogsState', error);
    case manageUserActions.FETCH_MANAGE_USER_COURSE_IDENTITY_LOGS_RESET:
      return resetState(state, 'fetchManageUserCourseIdentityLogsState');

    case manageUserActions.FETCH_MANAGE_USER_IDENTITY_LOG_REQUEST:
      return loadingState(state, 'fetchManageUserIdentityLogState');
    case manageUserActions.FETCH_MANAGE_USER_IDENTITY_LOG_SUCCESS:
      return successStateWithData(state, 'fetchManageUserIdentityLogState', payload);
    case manageUserActions.FETCH_MANAGE_USER_IDENTITY_LOG_ERROR:
      return failedState(state, 'fetchManageUserIdentityLogState', error);
    case manageUserActions.FETCH_MANAGE_USER_IDENTITY_LOG_RESET:
      return resetState(state, 'fetchManageUserIdentityLogState');

    case manageUserActions.FETCH_MANAGE_USER_IDENTITY_LOG_SUMMARY_REQUEST:
      return loadingState(state, 'fetchManageUserIdentityLogSummaryState');
    case manageUserActions.FETCH_MANAGE_USER_IDENTITY_LOG_SUMMARY_SUCCESS:
      return successStateWithData(state, 'fetchManageUserIdentityLogSummaryState', payload);
    case manageUserActions.FETCH_MANAGE_USER_IDENTITY_LOG_SUMMARY_ERROR:
      return failedState(state, 'fetchManageUserIdentityLogSummaryState', error);
    case manageUserActions.FETCH_MANAGE_USER_IDENTITY_LOG_SUMMARY_RESET:
      return resetState(state, 'fetchManageUserIdentityLogSummaryState');

    case manageUserActions.FETCH_MANAGE_USER_IDENTITY_LOG_DETAIL_REQUEST:
      return loadingState(state, 'fetchManageUserIdentityLogDetailState');
    case manageUserActions.FETCH_MANAGE_USER_IDENTITY_LOG_DETAIL_SUCCESS:
      return successStateWithData(state, 'fetchManageUserIdentityLogDetailState', payload);
    case manageUserActions.FETCH_MANAGE_USER_IDENTITY_LOG_DETAIL_ERROR:
      return failedState(state, 'fetchManageUserIdentityLogDetailState', error);
    case manageUserActions.FETCH_MANAGE_USER_IDENTITY_LOG_DETAIL_RESET:
      return resetState(state, 'fetchManageUserIdentityLogDetailState');

    default:
      return state;
  }
}
