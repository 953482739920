import { isApiResponseSuccess } from '@common/utility/http';
import { all, call, put, takeEvery } from 'redux-saga/effects';

import manageUserActions from '@redux/manageUser/action';
import manageUserAPI from '@redux/manageUser/api';
import {
  FetchManageUserCourseIdentityLogsRequestAction,
  HttpFetchManageUserCourseIdentityLogsApiResponse,
} from '@redux/manageUser/type/fetchManageUserCourseIdentityLogsType';
import {
  FetchManageUserIdentityListRequestAction,
  HttpFetchManageUserIdentityListApiResponse,
} from '@redux/manageUser/type/fetchManageUserIdentityListType';
import {
  FetchManageUserIdentityLogDetailRequestAction,
  HttpFetchManageUserIdentityLogDetailApiResponse,
} from '@redux/manageUser/type/fetchManageUserIdentityLogDetailType';
import {
  FetchManageUserIdentityLogSummaryRequestAction,
  HttpFetchManageUserIdentityLogSummaryApiResponse,
} from '@redux/manageUser/type/fetchManageUserIdentityLogSummaryType';
import {
  FetchManageUserIdentityLogRequestAction,
  HttpFetchManageUserIdentityLogApiResponse,
} from '@redux/manageUser/type/fetchManageUserIdentityLogType';

const {
  fetchManageUserIdentityListAction,
  fetchManageUserCourseIdentityLogsAction,
  fetchManageUserIdentityLogAction,
  fetchManageUserIdentityLogSummaryAction,
  fetchManageUserIdentityLogDetailAction,
} = manageUserActions;

function* fetchManageUserIdentityListSaga(action: {
  type: typeof manageUserActions;
  payload: FetchManageUserIdentityListRequestAction;
}): any {
  const apiResponse: HttpFetchManageUserIdentityListApiResponse = yield call(
    manageUserAPI.fetchManageUserIdentityList,
    action.payload,
  );
  if (isApiResponseSuccess(apiResponse.status)) {
    yield put(fetchManageUserIdentityListAction.success(apiResponse.data));
  } else {
    yield put(fetchManageUserIdentityListAction.error(apiResponse));
  }
}

function* fetchManageUserCourseIdentityLogsSaga(action: {
  type: typeof manageUserActions;
  payload: FetchManageUserCourseIdentityLogsRequestAction;
}): any {
  const apiResponse: HttpFetchManageUserCourseIdentityLogsApiResponse = yield call(
    manageUserAPI.fetchManageUserCourseIdentityLogs,
    action.payload,
  );
  if (isApiResponseSuccess(apiResponse.status)) {
    yield put(fetchManageUserCourseIdentityLogsAction.success(apiResponse.data));
  } else {
    yield put(fetchManageUserCourseIdentityLogsAction.error(apiResponse));
  }
}

function* fetchManageUserIdentityLogSaga(action: {
  type: typeof manageUserActions;
  payload: FetchManageUserIdentityLogRequestAction;
}): any {
  const apiResponse: HttpFetchManageUserIdentityLogApiResponse = yield call(
    manageUserAPI.fetchManageUserIdentityLog,
    action.payload,
  );
  if (isApiResponseSuccess(apiResponse.status)) {
    yield put(fetchManageUserIdentityLogAction.success(apiResponse.data));
  } else {
    yield put(fetchManageUserIdentityLogAction.error(apiResponse));
  }
}

function* fetchManageUserIdentityLogSummarySaga(action: {
  type: typeof manageUserActions;
  payload: FetchManageUserIdentityLogSummaryRequestAction;
}): any {
  const apiResponse: HttpFetchManageUserIdentityLogSummaryApiResponse = yield call(
    manageUserAPI.fetchManageUserIdentityLogSummary,
    action.payload,
  );
  if (isApiResponseSuccess(apiResponse.status)) {
    yield put(fetchManageUserIdentityLogSummaryAction.success(apiResponse.data));
  } else {
    yield put(fetchManageUserIdentityLogSummaryAction.error(apiResponse));
  }
}

function* fetchManageUserIdentityLogDetailSaga(action: {
  type: typeof manageUserActions;
  payload: FetchManageUserIdentityLogDetailRequestAction;
}): any {
  const apiResponse: HttpFetchManageUserIdentityLogDetailApiResponse = yield call(
    manageUserAPI.fetchManageUserIdentityLogDetail,
    action.payload,
  );
  if (isApiResponseSuccess(apiResponse.status)) {
    yield put(fetchManageUserIdentityLogDetailAction.success(apiResponse.data));
  } else {
    yield put(fetchManageUserIdentityLogDetailAction.error(apiResponse));
  }
}

export default function* manageUserIdentitySaga() {
  yield all([takeEvery(manageUserActions.FETCH_MANAGE_USER_IDENTITY_LIST_REQUEST, fetchManageUserIdentityListSaga)]);
  yield all([
    takeEvery(manageUserActions.FETCH_MANAGE_USER_COURSE_IDENTITY_LOGS_REQUEST, fetchManageUserCourseIdentityLogsSaga),
  ]);
  yield all([takeEvery(manageUserActions.FETCH_MANAGE_USER_IDENTITY_LOG_REQUEST, fetchManageUserIdentityLogSaga)]);
  yield all([
    takeEvery(manageUserActions.FETCH_MANAGE_USER_IDENTITY_LOG_SUMMARY_REQUEST, fetchManageUserIdentityLogSummarySaga),
  ]);
  yield all([
    takeEvery(manageUserActions.FETCH_MANAGE_USER_IDENTITY_LOG_DETAIL_REQUEST, fetchManageUserIdentityLogDetailSaga),
  ]);
}
