import React from 'react';

import { Box, Link, Typography, useTheme } from '@mui/material';
import { useTranslation } from 'next-i18next';

import ErrorPage from '..';

import config from 'src/config';

const ComponentErrorBoundary = () => {
  const theme = useTheme();
  const { t } = useTranslation();

  const handleClick = () => {
    window.location.href = '/';
  };

  return (
    <>
      <ErrorPage
        title={
          <Typography
            sx={{
              fontWeight: 700,
              fontSize: { md: 48, xs: 36 },
              lineHeight: { md: '72px', xs: '43px' },
            }}
            textAlign="center"
            color={theme.palette.textV2.primary}
          >
            {t('error.boundary.title')}
          </Typography>
        }
        subtitle={
          <Box display="flex" flexDirection="column">
            <Typography variant="body1lanna" textAlign="center" color={theme.palette.textV2.secondary}>
              {t('error.boundary.subtitle')}
            </Typography>
            <Typography variant="body1lanna" textAlign="center" color={theme.palette.textV2.secondary}>
              {t('error.boundary.contact.line')}
              <Link
                href={config.LINE_OFFICIAL}
                target="_blank"
                color={theme.palette.primary.main}
                sx={{ textDecorationThickness: '1px' }}
              >
                {t('error.boundary.contact.line.value')}
              </Link>
            </Typography>
            <Typography variant="body1lanna" textAlign="center" color={theme.palette.textV2.secondary}>
              {t('error.boundary.contact.facebook')}
              <Link
                href={config.FACEBOOK_URL}
                target="_blank"
                color={theme.palette.primary.main}
                sx={{ textDecorationThickness: '1px' }}
              >
                {config.FACEBOOK_URL}
              </Link>
            </Typography>
            <Typography variant="body1lanna" textAlign="center" color={theme.palette.textV2.secondary}>
              {t('error.boundary.contact.tel')}
              <Link
                href={`tel:${config.TELEPHONE}`}
                color={theme.palette.primary.main}
                sx={{ textDecorationThickness: '1px' }}
              >
                {config.TELEPHONE}
              </Link>
            </Typography>
          </Box>
        }
        buttonTitle={t('error.500.button.title')}
        buttonAction={handleClick}
        backgroundImageUrl="/static/images/common/bg-500-server-error.png"
      />
    </>
  );
};

export default ComponentErrorBoundary;
