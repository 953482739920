import { Record } from 'immutable';

import { defaultInitialState, failedState, loadingState, resetState, successStateWithData } from '@redux/state';
import userIdentityActions from '@redux/userIdentity/action';
import { UserIdentityReduxState } from '@redux/userIdentity/type';

const initUserIdentityState = new (Record<UserIdentityReduxState>({
  identityDocumentValidation: defaultInitialState(),
  identityFaceValidation: defaultInitialState(),
  identityDocumentExtraction: defaultInitialState(),
  identityDocumentConfirmation: defaultInitialState(),
  identityFaceComparison: defaultInitialState(),
  identityDocumentInformation: defaultInitialState(),
}))();

export default function userIdentityReducer(state = initUserIdentityState, { type, payload, error }: any) {
  switch (type) {
    case userIdentityActions.IDENTITY_DOCUMENT_VALIDATION_REQUEST:
      return loadingState(state, 'identityDocumentValidation', { shouldResetData: true });
    case userIdentityActions.IDENTITY_DOCUMENT_VALIDATION_SUCCESS:
      return successStateWithData(state, 'identityDocumentValidation', payload);
    case userIdentityActions.IDENTITY_DOCUMENT_VALIDATION_ERROR:
      return failedState(state, 'identityDocumentValidation', error);
    case userIdentityActions.IDENTITY_DOCUMENT_VALIDATION_RESET:
      return resetState(state, 'identityDocumentValidation');

    case userIdentityActions.IDENTITY_FACE_VALIDATION_REQUEST:
      return loadingState(state, 'identityFaceValidation', { shouldResetData: true });
    case userIdentityActions.IDENTITY_FACE_VALIDATION_SUCCESS:
      return successStateWithData(state, 'identityFaceValidation', payload);
    case userIdentityActions.IDENTITY_FACE_VALIDATION_ERROR:
      return failedState(state, 'identityFaceValidation', error);
    case userIdentityActions.IDENTITY_FACE_VALIDATION_RESET:
      return resetState(state, 'identityFaceValidation');

    case userIdentityActions.IDENTITY_DOCUMENT_EXTRACTION_REQUEST:
      return loadingState(state, 'identityDocumentExtraction', { shouldResetData: true });
    case userIdentityActions.IDENTITY_DOCUMENT_EXTRACTION_SUCCESS:
      return successStateWithData(state, 'identityDocumentExtraction', payload);
    case userIdentityActions.IDENTITY_DOCUMENT_EXTRACTION_ERROR:
      return failedState(state, 'identityDocumentExtraction', error);
    case userIdentityActions.IDENTITY_DOCUMENT_EXTRACTION_RESET:
      return resetState(state, 'identityDocumentExtraction');

    case userIdentityActions.IDENTITY_DOCUMENT_CONFIRMATION_REQUEST:
      return loadingState(state, 'identityDocumentConfirmation', { shouldResetData: true });
    case userIdentityActions.IDENTITY_DOCUMENT_CONFIRMATION_SUCCESS:
      return successStateWithData(state, 'identityDocumentConfirmation', payload);
    case userIdentityActions.IDENTITY_DOCUMENT_CONFIRMATION_ERROR:
      return failedState(state, 'identityDocumentConfirmation', error);
    case userIdentityActions.IDENTITY_DOCUMENT_CONFIRMATION_RESET:
      return resetState(state, 'identityDocumentConfirmation');

    case userIdentityActions.IDENTITY_FACE_COMPARISON_REQUEST:
      return loadingState(state, 'identityFaceComparison', { shouldResetData: true });
    case userIdentityActions.IDENTITY_FACE_COMPARISON_SUCCESS:
      return successStateWithData(state, 'identityFaceComparison', payload);
    case userIdentityActions.IDENTITY_FACE_COMPARISON_ERROR:
      return failedState(state, 'identityFaceComparison', error);
    case userIdentityActions.IDENTITY_FACE_COMPARISON_RESET:
      return resetState(state, 'identityFaceComparison');

    case userIdentityActions.IDENTITY_DOCUMENT_INFORMATION_REQUEST:
      return loadingState(state, 'identityDocumentInformation', { shouldResetData: true });
    case userIdentityActions.IDENTITY_DOCUMENT_INFORMATION_SUCCESS:
      return successStateWithData(state, 'identityDocumentInformation', payload);
    case userIdentityActions.IDENTITY_DOCUMENT_INFORMATION_ERROR:
      return failedState(state, 'identityDocumentInformation', error);
    case userIdentityActions.IDENTITY_DOCUMENT_INFORMATION_RESET:
      return resetState(state, 'identityDocumentInformation');

    case userIdentityActions.RESET_ALL_STATE:
      return initUserIdentityState;

    default:
      return state;
  }
}
