import { datadogRum } from '@datadog/browser-rum';

import config from './config';

export const initialDataDog = () => {
  if (!config.DATADOG_ENABLE) return;

  datadogRum.init({
    applicationId: config.DATADOG_APPLICATION_ID,
    clientToken: config.DATADOG_CLIENT_TOKEN,
    site: config.DATADOG_SITE,
    service: config.DATADOG_SERVICE_NAME,
    env: config.ENV_NAME,
    version: config.APP_VERSION,
    sampleRate: config.DATADOG_SAMPLE_RATE,
    sessionReplaySampleRate: config.DATADOG_SESSION_ON_REPLAY_SAMPLE_RATE,
    trackInteractions: config.DATADOG_SESSION_TRACK_INTERACTIONS_ENABLE,
    trackResources: config.DATADOG_SESSION_TRACK_RESOURCES_ENABLE,
    trackLongTasks: config.DATADOG_SESSION_TRACK_LONG_TASKS_ENABLE,
    defaultPrivacyLevel: config.DATADOG_SESSION_DEFAULT_PRIVACY_LEVEL,
  });

  datadogRum.startSessionReplayRecording();
};
