import config from 'src/config';
import Http from 'src/helpers/httpClient';
import { IdOrIdName } from 'src/types/common';

const END_POINT = 'v1/universities';

export const UNIVERSITY_ENDPOINT = {
  DEGREE_LANDING_PAGE: (universityIdName: IdOrIdName, degreeIdName: IdOrIdName) =>
    `${END_POINT}/${universityIdName}/degrees/${degreeIdName}/landing-page`,
  UNIVERSITY_INFORMATION_LANDING_PAGE: (universityIdName: IdOrIdName) =>
    `${END_POINT}/${universityIdName}?hasLearningInformation=true&hasLearnWithUsInformation=true`,
  DEGREE_UNIVERSITY_LANDING_PAGE: (universityId: number) => `${END_POINT}/${universityId}/degrees`,
  GET_UNIVERSITY_MASTER_DEGREE_LIST: `${END_POINT}/get-university-master-degree-list`,
};

const universityAPI = {
  getDegreeLandingPageData(universityIdName: IdOrIdName, degreeIdName: IdOrIdName) {
    const apiUrl = `${config.API_DOMAIN}/${UNIVERSITY_ENDPOINT.DEGREE_LANDING_PAGE(universityIdName, degreeIdName)}`;

    return Http.get(apiUrl);
  },
  getUniversityInfomation(universityIdName: IdOrIdName) {
    const apiUrl = `${config.API_DOMAIN}/${UNIVERSITY_ENDPOINT.UNIVERSITY_INFORMATION_LANDING_PAGE(universityIdName)}`;

    return Http.get(apiUrl);
  },
  getDegreeUniversityLandingPageData(universityId: number) {
    const apiUrl = `${config.API_DOMAIN}/${UNIVERSITY_ENDPOINT.DEGREE_UNIVERSITY_LANDING_PAGE(universityId)}`;

    return Http.get(apiUrl);
  },
  getUniversityMasterDegreeList() {
    const apiUrl = new URL(UNIVERSITY_ENDPOINT.GET_UNIVERSITY_MASTER_DEGREE_LIST, config.API_DOMAIN);
    return Http.get(apiUrl.toString(), { isPublic: true });
  },
};

export default universityAPI;
