import { useRouter } from 'next/router';
import { useEffect, useState, useMemo, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import useMediaQuery from '@mui/material/useMediaQuery';
import { createStyles, makeStyles } from '@mui/styles';
import { AxiosResponse, AxiosError } from 'axios';
import _flatMap from 'lodash/flatMap';
import _groupBy from 'lodash/groupBy';
import _isEmpty from 'lodash/isEmpty';
import _mapValues from 'lodash/mapValues';
import _sortBy from 'lodash/sortBy';
import { useTranslation } from 'next-i18next';
import appAPI, { APP_END_POINT } from 'src/api/app';
import bundleAPI, { BUNDLE_END_POINT } from 'src/api/bundle';
import courseAPI, { COURSE_END_POINT } from 'src/api/course';
import cpdAPI, { CPDCourseQuery, CPD_END_POINT } from 'src/api/cpd';
import degreeAPI, { DEGREE_END_POINT } from 'src/api/degree';
import enrollmentAPI, { ENROLLMENT_END_POINT } from 'src/api/enrollment';
import learningAPI, { LEARNING_END_POINT } from 'src/api/learning';
import universityAPI, { UNIVERSITY_ENDPOINT } from 'src/api/university';
import userAPI, { USER_END_POINT } from 'src/api/user';
import appConfig from 'src/config';
import { ICourse, IWishlist, IEnrollment, IAppData, ICategory, IEnrollmentStep } from 'src/interfaces';
import { ICPDBundleEnrollment } from 'src/interfaces/bundle';
import {
  ApplicantTypeEnum,
  CourseFiltersKey,
  CourseInclude,
  CPDEnrollmentStatusEnum,
  CPDLicenseTypeEnum,
  ICourseInteractionList,
  ICourseInteractionSummary,
  ICPDCourseEnrollment,
} from 'src/interfaces/course';
import { IProductDetail } from 'src/interfaces/product';
import { IUserIDSProfile, IUserProfile } from 'src/interfaces/user';
import appActions from 'src/redux/app/actions';
import { AppStateType } from 'src/redux/app/reducer';
import { RootState } from 'src/redux/reducer';
import theme from 'src/theme';
import { IdOrIdName } from 'src/types/common';
import useSWR, { Fetcher, SWRConfiguration, SWRResponse } from 'swr';
import * as thaiIdCard from 'thai-id-card';

import { EnrollmentStatus, CourseInteractionStatisticStatusCode, LearningStatusCode } from './constants';

import { getToken } from '@helpers/auth';
import {
  convertObjectToQueryString,
  getAllSlugFromUrl,
  getSortingByRegulatorType,
  swrImmutableOptions,
} from '@helpers/utils';
import { ICoreApiResponse } from '@interfaces/common';
import { IDegreeCoursesList } from '@interfaces/degree';
import { ILearningPageCurriculum } from '@interfaces/learning';
import {
  DegreeCourseStatus,
  DegreeCourseType,
  DegreeUniversity,
  IDegreeLandingPageData,
  IUniversityLandingPageData,
} from '@interfaces/university';

/**
 *  set maxDigit = 0 if no max digit limit
 * */
export const useFormatNumber = (number: number, maxDigit: number = 0): string => {
  const [formatted, setFormatted] = useState('');
  const numberWithCommas = (value: number | string) => value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');

  useEffect(() => {
    if (number.toString().length > maxDigit && maxDigit !== 0) {
      const maxNumber = '+'.padStart(maxDigit + 1, '9');
      setFormatted(numberWithCommas(maxNumber));
    } else {
      setFormatted(numberWithCommas(number));
    }
  }, [maxDigit, number]);

  return formatted;
};

interface Size {
  width: number | undefined;
  height: number | undefined;
}
export function useWindowSize(): Size {
  const [windowSize, setWindowSize] = useState<Size>({
    width: undefined,
    height: undefined,
  });
  function handleResize() {
    setWindowSize({
      width: window.innerWidth,
      height: window.innerHeight,
    });
  }

  useEffect(() => {
    handleResize();
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);
  return windowSize;
}

/** @deprecated use getCourseUrlV2 instaed */
export const useBreakpoint = () => {
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const isTablet = useMediaQuery(theme.breakpoints.between('sm', 'md'));
  const isDesktop = useMediaQuery(theme.breakpoints.up('md'));

  return {
    isMobile,
    isTablet,
    isDesktop,
  };
};

export const useBreakpointV2 = () => {
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const isTablet = useMediaQuery(theme.breakpoints.between('sm', 'lg'));
  const isDesktop = useMediaQuery(theme.breakpoints.up('lg'));

  return {
    isMobile,
    isTablet,
    isDesktop,
  };
};

export const useToggleState = (defaultState: boolean = false): [boolean, any, any] => {
  const [open, setOpen] = useState(defaultState);

  function handleSetOpen() {
    setOpen(true);
  }

  function handleSetClose() {
    setOpen(false);
  }

  return [open, handleSetOpen, handleSetClose];
};

export const useScrollPosition = (elementId: string) => {
  const [scrollPosition, setScrollPosition] = useState(0);
  const { width } = useWindowSize();

  const [hasScroll, setHasScroll] = useState(false);

  useEffect(() => {
    const element = document.getElementById(elementId);

    const updatePosition = () => {
      if (!element) return;

      const scrollWidth = element.scrollWidth - element.clientWidth;

      setScrollPosition(element.scrollLeft / scrollWidth);
      setHasScroll(element.scrollWidth > element.clientWidth);
    };

    if (element) element.addEventListener('scroll', updatePosition);
    updatePosition();
    return () => {
      if (element) element.removeEventListener('scroll', updatePosition);
    };
  }, [width]);

  return [hasScroll, scrollPosition];
};

export const useStyles: any = (styles: any) => makeStyles((theme: any) => createStyles(styles(theme)))();

export type KeyedMutator<Data> = () => Promise<Readonly<Data> | undefined>;
interface Return<Data, Error>
  extends Pick<SWRResponse<AxiosResponse<Data>, AxiosError<Error>>, 'isValidating' | 'error' | 'mutate'> {
  data: Data | undefined;
  isLoading: boolean;
  isError: boolean;
  response?: AxiosResponse<Data>;
  // mutate?: KeyedMutator<AxiosResponse<Data>>;
  // | { httpStatus: number; status: string; message: string; error: any; domain?: undefined; data?: undefined }
  // | { httpStatus: any; status: any; message: any; domain: any; data: any; error?: undefined };
  // mutate:
  //   | AxiosResponse<Data>
}

export interface Config<Data = unknown, Error = unknown>
  extends Omit<SWRConfiguration<AxiosResponse<Data>, AxiosError<Error>>, 'fallbackData'> {
  fallbackData?: Data;
}

/** @deprecated use `redux/saga` pattern instead, team we migrate to redux/saga pattern */
export const useRequest = <Data = unknown, Error = unknown>(
  key: string | Array<string | object> | null,
  fetchFunction?: () => any,
  { fallbackData, ...config }: Config<Data, Error> = {},
): Return<Data, Error> => {
  const { data: response, error, isValidating, isLoading, mutate } = useSWR<AxiosResponse<Data>, AxiosError<Error>>(
    key,
    /**
     * NOTE: Typescript thinks `request` can be `null` here, but the fetcher
     * function is actually only called by `useSWR` when it isn't.
     */
    fetchFunction || null,
    {
      ...config,
      fallbackData: fallbackData && {
        status: 200,
        statusText: 'InitialData',
        config: { url: `${appConfig.API_DOMAIN}/v1/${key}` },
        headers: {},
        data: fallbackData,
      },
    },
  );

  return {
    data: response && response.data,
    response,
    isLoading,
    error,
    isError: !_isEmpty(error),
    isValidating,
    mutate,
  };
};

/**
 * @deprecated let use `src/hooks/useProfile.tsx` instead
 * */
export const useProfile = (options?: SWRConfiguration) => {
  const hasAccessToken = getToken();
  const { data: profile, isLoading, isValidating, mutate } = useRequest<IUserProfile>(
    hasAccessToken ? USER_END_POINT.USER_ME : null,
    userAPI.fetchProfile,
    { ...options, ...swrImmutableOptions },
  );

  return {
    profile: profile ? { ...profile } : undefined,
    isLoading,
    isValidating,
    mutate,
  };
};

export const useLearningCourse = (
  courseIdName: string,
): {
  learningCourse: ILearningPageCurriculum | undefined;
  isLoading: boolean;
  error: string | null;
  message?: string;
  status?: LearningStatusCode;
} => {
  const { data: learningCourse, isLoading, response } = useRequest<
    ICoreApiResponse<ILearningPageCurriculum, LearningStatusCode>
  >(LEARNING_END_POINT.course(courseIdName), () => learningAPI.fetchLearningCourse(courseIdName));
  let error: string | null = null;

  if (response?.status === 401) {
    error = 'Unauthorized';
  }

  return {
    learningCourse: learningCourse?.data,
    isLoading,
    error,
    message: learningCourse?.message,
    status: learningCourse?.status,
  };
};

export const useCourseEnrollment = (course: ICourse) => {
  const { profile } = useProfile();
  const { data: wishlist } = useRequest<IWishlist>(profile ? USER_END_POINT.USER_ME_WISHLIST_COURSES : null, () =>
    userAPI.fetchUserCourseWishlist(course.id),
  );

  course.wishlist = wishlist;
  return { courseData: course };
};

export const useCoursesEnrollment = (courses: ICourse[]) => {
  const { profile } = useProfile();
  const { data: enrollments } = useRequest<IEnrollment[]>(
    profile ? ENROLLMENT_END_POINT.ENROLLMENTS_ME : null,
    enrollmentAPI.fetchCoursesEnrollment,
  );
  const { data: wishlists } = useRequest<IWishlist[]>(
    profile ? USER_END_POINT.USER_ME_WISHLIST : null,
    userAPI.fetchUserWishlist,
  );

  const coursesData = profile
    ? courses?.map((course: ICourse) => {
        if (course.bundle_items) {
          return {
            ...course,
            bundle_items: course.bundle_items?.map((course) => ({
              ...course,
              enrollment: enrollments?.find((item: IEnrollment) => item.course_id === course.id && item) || undefined,
            })),
          };
        }
        return {
          wishlist: wishlists?.find((item: IWishlist) => item.course_id === course.id && item) || undefined,
          enrollment: enrollments?.find((item: IEnrollment) => item.course_id === course.id && item) || undefined,
          ...course,
        };
      })
    : courses;
  return { coursesData };
};

export const useCpdCourseEnrollment = (
  courseId: number,
  citizenId: string,
  salePrice?: number,
  roundId?: string,
): { cpdCourseEnrollment?: ICPDCourseEnrollment; isLoading: boolean } => {
  const isValidToFetchData = citizenId && courseId;
  const key = isValidToFetchData ? CPD_END_POINT.courseCPDEnrollmentForCitizen(courseId, citizenId, roundId) : null;
  const { data: cpdCourseEnrollment, isLoading } = useRequest<ICPDCourseEnrollment>(key, () =>
    cpdAPI.fetchCPDEnrollment(courseId, citizenId, roundId),
  );

  if (isValidToFetchData) {
    return {
      cpdCourseEnrollment,
      isLoading,
    };
  }

  return {
    cpdCourseEnrollment: {
      citizenId: null,
      email: null,
      productSKUId: null,
      licenses: [],
      status: CPDEnrollmentStatusEnum.Available,
      isRequestForLicense: false,
      isCpdCourseBundle: false,
      preEnrollmentDetail: null,
      canEnrollToCourse: true,
      enrollStatus: salePrice ? EnrollmentStatus.AVAILABLE : EnrollmentStatus.FREE_COURSE,
    },
    isLoading: false,
  };
};

export const useCpdBundleEnrollment = (
  citizenId: string,
  productId?: string,
  salePrice?: number,
  roundId?: string,
): { cpdBundleEnrollment?: ICPDBundleEnrollment; isLoading: boolean } => {
  const isValidToFetchData = citizenId && productId;

  const key = isValidToFetchData ? CPD_END_POINT.bundleCPDEnrollmentForCitizen(Number(productId), citizenId) : null;
  const { data: cpdBundleEnrollment, isLoading } = useRequest<ICPDBundleEnrollment>(key, () =>
    cpdAPI.fetchCPDBundleEnrollment(Number(productId), citizenId),
  );

  if (isValidToFetchData) {
    return {
      cpdBundleEnrollment,
      isLoading,
    };
  }

  return {
    cpdBundleEnrollment: {
      citizenId: null,
      email: null,
      cpdEnrollment: [],
      canEnrollToBundle: true,
      status: CPDEnrollmentStatusEnum.Available,
      isLicenseOic: false,
      isLicenseTsi: false,
      enrollStatus: EnrollmentStatus.AVAILABLE,
      sameRounds: [],
    },
    isLoading: false,
  };
};

export const useCpdEnrollmentStatus = (cpdEnrollmentStatus?: CPDEnrollmentStatusEnum) => {
  const canSelectRoundToEnroll =
    !cpdEnrollmentStatus ||
    cpdEnrollmentStatus === CPDEnrollmentStatusEnum.Available ||
    cpdEnrollmentStatus === CPDEnrollmentStatusEnum.RegulatorUnChecked ||
    cpdEnrollmentStatus === CPDEnrollmentStatusEnum.LicenseSuspended;

  const cannotEnrollToCourse =
    !canSelectRoundToEnroll && cpdEnrollmentStatus === CPDEnrollmentStatusEnum.CourseUnMatched;
  const alreadyEnrolled =
    !canSelectRoundToEnroll && !cannotEnrollToCourse && cpdEnrollmentStatus === CPDEnrollmentStatusEnum.Enrolled;

  return { canSelectRoundToEnroll, cannotEnrollToCourse, alreadyEnrolled };
};

// only apply with app redux state
export const useBooleanReduxState = (key: keyof AppStateType, actionName: keyof typeof appActions) => {
  const { [actionName]: action } = appActions;
  const dispatch = useDispatch();
  const { value } = useSelector((state: RootState) => ({
    value: state.App.get(key),
  }));

  const setValue = (value: boolean) => {
    if (typeof action === 'function') {
      dispatch(action(value));
    }
  };
  return [value, setValue];
};

export const useAppData = () => {
  const { data: appData, isLoading, isValidating } = useRequest<IAppData>(APP_END_POINT.APP_DATA, appAPI.fetchAppData);
  return {
    appData,
    isLoading: isLoading && isValidating,
    isValidating,
  };
};

export const useQuery = () => {
  const router = useRouter();
  const hasQueryParams = /\[.+\]/.test(router.route) || /\?./.test(router.asPath);
  const ready = !hasQueryParams || Object.keys(router.query).length > 0;
  if (!ready) return null;

  const slugs = getAllSlugFromUrl(router.route);
  if (!slugs) {
    return router.query;
  }
  const query = { ...router.query };
  slugs.forEach((slug) => delete query[slug]);

  if (Object.keys(query).length === 0) return null;
  return query;
};

export const useDebounce = (value: any, delay: number) => {
  const [debouncedValue, setDebouncedValue] = useState(value);
  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedValue(value);
    }, delay);

    return () => {
      clearTimeout(handler);
    };
  }, [value, delay]);
  return debouncedValue;
};

export const useFilterItems = (categories?: ICategory[], isSubcategory?: boolean) => {
  const { t } = useTranslation('common');
  return useMemo(() => {
    const filters = [];
    // For Page: all course and search result
    if (categories) {
      filters.push({
        key: isSubcategory ? CourseFiltersKey.SUBCATEGORY : CourseFiltersKey.CATEGORY,
        name: isSubcategory ? t('all_course.filter.subcategory_title') : t('all_course.filter.category_title'),
        list: categories?.map((category) => ({ value: `${category.id}`, name: category.name })) || [],
        isShowMore: !(categories.length < 5),
      });
    }

    filters.push({
      key: CourseFiltersKey.COURSE_INCLUDE,
      name: t('category.filter.course_include_title'),
      list: [
        { value: CourseInclude.Certificate, name: t('category.filter.course_include_certificate') },
        { value: CourseInclude.Quiz, name: t('category.filter.course_include_quiz') },
        { value: CourseInclude.Attachment, name: t('category.filter.course_include_attachment') },
      ],
      isShowMore: false,
    });

    filters.push({
      key: CourseFiltersKey.PRICE,
      name: t('all_course.filter.price_title'),
      list: [
        { value: '1', name: t('all_course.filter.price_option1') },
        { value: '2', name: t('all_course.filter.price_option2') },
        { value: '3', name: t('all_course.filter.price_option3') },
        { value: '4', name: t('all_course.filter.price_option4') },
      ],
      isShowMore: false,
    });
    return {
      filters,
    };
  }, [categories]);
};

export const useProgressiveImage = (src: string) => {
  const [sourceLoaded, setSourceLoaded] = useState<string | null>(null);

  useEffect(() => {
    const img = new Image();
    img.src = src;
    img.onload = () => setSourceLoaded(src);
  }, [src]);

  return sourceLoaded;
};

export const useMediaQueryDevice = () => {
  const matchesLargeDesktop = useMediaQuery('(max-width: 6000px) and (min-width: 2000px)');
  const matchesMediumDesktop = useMediaQuery('(max-width: 1999px) and (min-width: 1701px)');
  const matchesDesktop = useMediaQuery('(max-width: 1700px) and (min-width: 1200px)');
  const matchesIpad = useMediaQuery('(max-width: 1199px) and (min-width: 768px)');
  const matchesMobile = useMediaQuery('(max-width: 767px)');
  return {
    matchesIpad,
    matchesMobile,
    matchesDesktop,
    matchesMediumDesktop,
    matchesLargeDesktop,
  };
};

export const useCountdown = (targetDate: Date) => {
  const countDownDate = new Date(targetDate).getTime();

  const [countDown, setCountDown] = useState(countDownDate - new Date().getTime());

  useEffect(() => {
    const interval = setInterval(() => {
      setCountDown(countDownDate - new Date().getTime());
    }, 1000);

    return () => clearInterval(interval);
  }, [countDownDate]);

  return getSeparateTime(countDown);
};

const getSeparateTime = (countDown: number) => {
  // calculate time left
  const days = Math.floor(countDown / (1000 * 60 * 60 * 24));
  const hours = Math.floor((countDown % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
  const minutes = Math.floor((countDown % (1000 * 60 * 60)) / (1000 * 60));
  const seconds = Math.floor((countDown % (1000 * 60)) / 1000);

  return { days, hours, minutes, seconds };
};

type ValidationResult = { isError: boolean; message: string };

const initialValidationResult: ValidationResult = {
  isError: false,
  message: '',
};

export const useFormCitizenId = () => {
  const { t } = useTranslation();
  const [citizenId, setCitizenId] = useState<string>('');
  const [isValidCitizenId, setIsValidCitizenId] = useState<boolean>(false);
  const [citizenIdValidationResult, setCitizenIdValidationResult] = useState<ValidationResult>(initialValidationResult);

  const handleSubmitError = (value: string) => {
    const validationResult = validateCitizenId(value);
    setCitizenIdValidationResult(validationResult);
  };

  const validateCitizenId = (value: string) => {
    if (!value) {
      return {
        isError: true,
        message: t('cpd_course.fill_citizen_id_placeholder'),
      };
    }

    if (!thaiIdCard.verify(value)) {
      return {
        isError: true,
        message: t('cpd_course.invalid_citizen_id_format'),
      };
    }

    return {
      ...initialValidationResult,
    };
  };

  return {
    citizenId,
    isValidCitizenId,
    citizenIdValidationResult,
    setIsValidCitizenId,
    setCitizenId,
    setCitizenIdValidationResult,
    handleSubmitError,
    validateCitizenId,
  };
};

const DEFAULT_SORT_BY = 'published_at';
const DEFAULT_ORDER_BY = 'desc';

export const useCPDCourses = (query: CPDCourseQuery) => {
  if (query.regulatorType === 'all') {
    delete query.regulatorType;
  }
  const { data, isLoading } = useRequest<any>(
    query.citizenId ? `${CPD_END_POINT.COURSES}?${convertObjectToQueryString(query!)}` : null,
    () =>
      cpdAPI.fetchCPDCourses({
        ...query,
        sortBy: query.sortBy || DEFAULT_SORT_BY,
        orderBy: query.orderBy || DEFAULT_ORDER_BY,
      }),
  );

  const courses = getSortingByRegulatorType(data?.courses);
  return {
    data: {
      status: data?.status,
      courses,
      isLoading,
      bundles: data?.bundles,
    },
  };
};

export const useBundleDetail = (idName: string): IProductDetail | undefined => {
  const { data: bundle, isLoading, isValidating, mutate } = useRequest<IProductDetail>(
    BUNDLE_END_POINT.BUNDLE(idName),
    () => bundleAPI.fetchBundle(idName),
  );

  return bundle;
};

export const useMultiStepForm = (steps: IEnrollmentStep[], handleSubmitForm?: Function, bindingNextStep?: Function) => {
  const [initialSteps, setInitialSteps] = useState(steps);
  const [currentStepNum, setCurrentStepNum] = useState(1);
  const [activeStep, setActiveStep] = useState(steps[0]);
  const LAST_INDEX = steps.length - 1;

  const handleNext = async (data?: any) => {
    const nextStep = steps.find((step) => step.id === activeStep.id + 1);

    if (bindingNextStep?.(activeStep, data)) return;

    setActiveStep(nextStep || activeStep);
    if (nextStep) {
      setCurrentStepNum((prevState) => prevState + 1);
    }

    if (currentStepNum - 1 === LAST_INDEX) {
      handleSubmitForm?.(data);
    }
  };

  const handleBack = () => {
    const previousStep = steps.find((step) => step.id === activeStep.id - 1);
    setActiveStep(previousStep || activeStep);
    if (previousStep) {
      setCurrentStepNum((prevState) => prevState - 1);
    }
  };

  const handleStep = (enrollStep: IEnrollmentStep['id'] | IEnrollmentStep['key']) => {
    const toStep = steps.find((step) => enrollStep === step.id || enrollStep === step.key);
    setActiveStep(toStep || activeStep);
    if (toStep) {
      setCurrentStepNum(toStep.id + 1);
    }
  };

  return {
    lastIndex: LAST_INDEX,
    currentStepNum,
    activeStep: { ...activeStep },
    handleNext,
    handleBack,
    handleStep,
  };
};

export const useCustomDialog = () => {
  const [opened, setOpened] = useState(false);

  const open = useCallback(() => {
    setOpened(true);
  }, []);

  const close = useCallback(() => {
    setOpened(false);
  }, []);

  return {
    opened,
    open,
    close,
  };
};

export const useOICLicense = () => {
  const { t } = useTranslation();
  const applicantTypeMapper: Record<ApplicantTypeEnum, string> = {
    [ApplicantTypeEnum.AGENT]: t('cpd_course.applicant_type.agent'),
    [ApplicantTypeEnum.BROKER]: t('cpd_course.applicant_type.broker'),
    [ApplicantTypeEnum.ADVISOR]: '',
    [ApplicantTypeEnum.PLANNER]: '',
    [ApplicantTypeEnum.ANALYST]: '',
  };

  const licenseTypeMapper: Record<CPDLicenseTypeEnum, string> = {
    [CPDLicenseTypeEnum.LIFE]: t('cpd_course.license_types.oic.life'),
    [CPDLicenseTypeEnum.NON_LIFE]: t('cpd_course.license_types.oic.non_life'),
    [CPDLicenseTypeEnum.BOTH]: '',
    [CPDLicenseTypeEnum.INVESTMENT]: '',
  };

  const getLicenseDetailText = (applicantType?: ApplicantTypeEnum, licenseType?: CPDLicenseTypeEnum) => {
    const applicantTypeText = applicantType ? applicantTypeMapper[applicantType] : '';
    const licenseTypeText = licenseType ? licenseTypeMapper[licenseType] : '';
    return {
      applicantTypeText,
      licenseTypeText,
    };
  };

  return {
    getLicenseDetailText,
  };
};

export const useDegreeCourse = (degreeIdName: number | string, params: Record<string, unknown>) => {
  const { data, isLoading, isValidating, mutate } = useRequest<ICoreApiResponse<IDegreeCoursesList, string>>(
    [DEGREE_END_POINT.COURSES(degreeIdName), params],
    () => degreeAPI.fetchDegreeCourses(degreeIdName, params),
  );

  const { coursesData } = useCoursesEnrollment(data?.data?.list?.map((course) => course.course) || []);

  if (!data?.data?.list || !coursesData) return { courses: [], isLoading, isValidating, mutate };

  const courses = coursesData.map((course) => ({
    ...course,
    degree_courses: course.degree_courses.map((degreeCourse) => ({
      ...degreeCourse,
      coming_soon:
        data?.data?.list.find((item) => item.courseId === course.id)?.status === DegreeCourseStatus.COMING_SOON,
    })),
  }));

  const grouped = _mapValues(_groupBy(data?.data?.list, 'status'), (group) => _groupBy(group, 'degreeCourseType'));

  const getGroupedList = (
    group: Record<string, any>,
    status: DegreeCourseStatus,
    type: DegreeCourseType,
  ): IDegreeCoursesList['list'] => {
    return group[status] ? group[status][type] : [];
  };

  const mandatoryEnabled = getGroupedList(grouped, DegreeCourseStatus.ENABLED, DegreeCourseType.MANDATORY);
  const mandatoryComingSoon = getGroupedList(grouped, DegreeCourseStatus.COMING_SOON, DegreeCourseType.MANDATORY);
  const electiveEnabled = getGroupedList(grouped, DegreeCourseStatus.ENABLED, DegreeCourseType.ELECTIVE);
  const electiveComingSoon = getGroupedList(grouped, DegreeCourseStatus.COMING_SOON, DegreeCourseType.ELECTIVE);

  function sortByPosition(degreeCoursesList: IDegreeCoursesList['list']) {
    return _sortBy(degreeCoursesList, 'position');
  }

  const mergedArray = [
    ...sortByPosition(mandatoryEnabled),
    ...sortByPosition(electiveEnabled),
    ...sortByPosition(mandatoryComingSoon),
    ...sortByPosition(electiveComingSoon),
  ];

  const sortedCourseIdList = mergedArray.map((item) => item.courseId);
  const sortedCourses = sortedCourseIdList.map((courseId) => courses.find((course) => course.id === courseId));

  return { courses: sortedCourses, isLoading, isValidating, mutate };
};

export const useDegreeLandingPageData = (universityIdName: number | string, degreeIdName: number | string) => {
  const fetcher: Fetcher<ICoreApiResponse<IDegreeLandingPageData, string>> = async () => {
    const response = await universityAPI.getDegreeLandingPageData(universityIdName, degreeIdName);
    return response.data;
  };

  const { data, isLoading, isValidating, mutate } = useSWR(
    UNIVERSITY_ENDPOINT.DEGREE_LANDING_PAGE(universityIdName, degreeIdName),
    fetcher,
  );

  return { landingPage: data?.data, isLoading, isValidating, mutate };
};

export const useUniversityInformation = (universityIdName: IdOrIdName) => {
  const universityDataFetcher: Fetcher<ICoreApiResponse<
    { university: IUniversityLandingPageData },
    string
  >> = async () => {
    const universityInformation = await universityAPI.getUniversityInfomation(universityIdName);
    return universityInformation.data;
  };

  const { data, isLoading, isValidating, mutate } = useSWR(
    UNIVERSITY_ENDPOINT.UNIVERSITY_INFORMATION_LANDING_PAGE(universityIdName),
    universityDataFetcher,
  );

  return { landingPage: data?.data?.university, isLoading, isValidating, mutate };
};

export const useDegreeUniversityLandingPageData = (universityId?: number) => {
  if (!universityId) return {};
  const degreeUniversityDataFetcher: Fetcher<ICoreApiResponse<{ degrees: DegreeUniversity[] }, string>> = async () => {
    const degreeUniversity = await universityAPI.getDegreeUniversityLandingPageData(universityId);
    return degreeUniversity.data;
  };

  const { data, isLoading, isValidating, mutate } = useSWR(
    UNIVERSITY_ENDPOINT.DEGREE_UNIVERSITY_LANDING_PAGE(universityId),
    degreeUniversityDataFetcher,
  );

  return { degreeUniversityLandingPage: data?.data?.degrees, isLoading, isValidating, mutate };
};

export const useInteractionSummary = (idName: string | number) => {
  const { data, isLoading, isValidating, mutate, response } = useRequest<
    ICoreApiResponse<ICourseInteractionSummary, CourseInteractionStatisticStatusCode>
  >(COURSE_END_POINT.GET_INTERACTION_SUMMARY(idName), () => courseAPI.fetchInteractionSummary(idName));

  return {
    summary: data?.data,
    isLoading,
    isValidating,
    mutate,
    response,
  };
};

export const useInteractionList = (idName: IdOrIdName, params?: Record<string, unknown>) => {
  const { data, isLoading, isValidating, mutate } = useRequest<ICoreApiResponse<ICourseInteractionList, string>>(
    [COURSE_END_POINT.GET_INTERACTION_LIST(idName), params || {}],
    () => courseAPI.fetchInteractionList(idName, params),
    {
      keepPreviousData: true,
    },
  );

  return { list: data?.data, isLoading, isValidating, mutate };
};
