import { EditedUniversityEvaluationResult, EvaluationResultQuery } from 'src/types/enrollment';

import {
  CreateUserEnrollFreeErrorAction,
  CreateUserEnrollFreeRequestAction,
  CreateUserEnrollFreeSuccessAction,
} from './type';

const enrollmentActions = {
  FETCH_UNIVERSITY_EVALUATION_RESULT_LIST_REQUEST: 'FETCH_UNIVERSITY_EVALUATION_RESULT_LIST_REQUEST',
  FETCH_UNIVERSITY_EVALUATION_RESULT_LIST_SUCCESS: 'FETCH_UNIVERSITY_EVALUATION_RESULT_LIST_SUCCESS',
  FETCH_UNIVERSITY_EVALUATION_RESULT_LIST_ERROR: 'FETCH_UNIVERSITY_EVALUATION_RESULT_LIST_ERROR',

  FETCH_UNIVERSITY_EVALUATION_RESULT_DETAIL_REQUEST: 'FETCH_UNIVERSITY_EVALUATION_RESULT_DETAIL_REQUEST',
  FETCH_UNIVERSITY_EVALUATION_RESULT_DETAIL_SUCCESS: 'FETCH_UNIVERSITY_EVALUATION_RESULT_DETAIL_SUCCESS',
  FETCH_UNIVERSITY_EVALUATION_RESULT_DETAIL_ERROR: 'FETCH_UNIVERSITY_EVALUATION_RESULT_DETAIL_ERROR',

  FETCH_COURSE_GRADES_REQUEST: 'FETCH_COURSE_GRADES_REQUEST',
  FETCH_COURSE_GRADES_SUCCESS: 'FETCH_COURSE_GRADES_SUCCESS',
  FETCH_COURSE_GRADES_ERROR: 'FETCH_COURSE_GRADES_ERROR',

  UPDATE_UNIVERSITY_EVALUATION_RESULT_REQUEST: 'UPDATE_UNIVERSITY_EVALUATION_RESULT_REQUEST',
  UPDATE_UNIVERSITY_EVALUATION_RESULT_SUCCESS: 'UPDATE_UNIVERSITY_EVALUATION_RESULT_SUCCESS',
  UPDATE_UNIVERSITY_EVALUATION_RESULT_ERROR: 'UPDATE_UNIVERSITY_EVALUATION_RESULT_ERROR',

  fetch_university_evaluation_result_list: (query: EvaluationResultQuery) => ({
    type: enrollmentActions.FETCH_UNIVERSITY_EVALUATION_RESULT_LIST_REQUEST,
    query,
  }),
  fetch_university_evaluation_result_detail: (id: number) => ({
    type: enrollmentActions.FETCH_UNIVERSITY_EVALUATION_RESULT_DETAIL_REQUEST,
    id,
  }),
  fetch_course_grades: (id: number) => ({
    type: enrollmentActions.FETCH_COURSE_GRADES_REQUEST,
    id,
  }),
  update_university_evaluation_result: (data: EditedUniversityEvaluationResult, callback: any) => ({
    type: enrollmentActions.UPDATE_UNIVERSITY_EVALUATION_RESULT_REQUEST,
    data,
    callback,
  }),
  CREATE_USER_ENROLL_FREE_REQUEST: 'USER_IDENTITY/CREATE_USER_ENROLL_FREE_REQUEST',
  CREATE_USER_ENROLL_FREE_SUCCESS: 'USER_IDENTITY/CREATE_USER_ENROLL_FREE_SUCCESS',
  CREATE_USER_ENROLL_FREE_ERROR: 'USER_IDENTITY/CREATE_USER_ENROLL_FREE_ERROR',
  CREATE_USER_ENROLL_FREE_RESET: 'USER_IDENTITY/CREATE_USER_ENROLL_FREE_RESET',
  createUserEnrollFreeAction: {
    request: (payload: CreateUserEnrollFreeRequestAction) => ({
      type: enrollmentActions.CREATE_USER_ENROLL_FREE_REQUEST,
      payload,
    }),
    success: (payload: CreateUserEnrollFreeSuccessAction) => ({
      type: enrollmentActions.CREATE_USER_ENROLL_FREE_SUCCESS,
      payload,
    }),
    error: (error: CreateUserEnrollFreeErrorAction) => ({
      type: enrollmentActions.CREATE_USER_ENROLL_FREE_ERROR,
      error,
    }),
    reset: () => ({
      type: enrollmentActions.CREATE_USER_ENROLL_FREE_RESET,
    }),
  },
};

export default enrollmentActions;
