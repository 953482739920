/* eslint-disable no-param-reassign */
import { Record } from 'immutable';

import manageUserIdentityActions from '@redux/manageUserIdentity/action';
import { ManageUserIdentityReduxState } from '@redux/manageUserIdentity/type';
import { defaultInitialState, failedState, loadingState, resetState, successStateWithData } from '@redux/state';

const initManageUserIdentityState = new (Record<ManageUserIdentityReduxState>({
  userIdentityDocumentValidation: defaultInitialState(),
  userIdentityDocumentExtraction: defaultInitialState(),
  userIdentityDocumentConfirmation: defaultInitialState(),
  userIdentityDocumentInfo: defaultInitialState(),
  userIdentityFaceInfo: defaultInitialState(),
  userProfile: defaultInitialState(),
  manageIdentityFaceValidation: defaultInitialState(),
  manageIdentityFaceUpload: defaultInitialState(),
}))();

export default function manageUserIdentityReducer(state = initManageUserIdentityState, { type, payload, error }: any) {
  switch (type) {
    case manageUserIdentityActions.FETCH_USER_IDENTITY_INFO_REQUEST:
      state = loadingState(state, 'userIdentityDocumentInfo', { shouldResetData: true });
      state = loadingState(state, 'userIdentityFaceInfo', { shouldResetData: true });
      state = loadingState(state, 'userProfile', { shouldResetData: true });
      return state;
    case manageUserIdentityActions.FETCH_USER_IDENTITY_INFO_SUCCESS:
      state = successStateWithData(state, 'userIdentityDocumentInfo', payload.userIdentityDocumentInfo);
      state = successStateWithData(state, 'userIdentityFaceInfo', payload.userIdentityFaceInfo);
      state = successStateWithData(state, 'userProfile', payload.userProfile);
      return state;
    case manageUserIdentityActions.FETCH_USER_IDENTITY_INFO_ERROR:
      state = failedState(state, 'userIdentityDocumentInfo', error);
      state = failedState(state, 'userIdentityFaceInfo', error);
      state = failedState(state, 'userProfile', error);
      return state;
    case manageUserIdentityActions.FETCH_USER_IDENTITY_INFO_RESET:
      state = resetState(state, 'userIdentityDocumentInfo');
      state = resetState(state, 'userIdentityFaceInfo');
      state = resetState(state, 'userProfile');
      return state;

    case manageUserIdentityActions.DELETE_USER_IDENTITY_FACE_REQUEST:
      return resetState(state, 'userIdentityFaceInfo');

    case manageUserIdentityActions.DELETE_USER_IDENTITY_DOCUMENT_REQUEST:
      return loadingState(state, 'userIdentityDocumentInfo');
    case manageUserIdentityActions.DELETE_USER_IDENTITY_DOCUMENT_SUCCESS:
      return resetState(state, 'userIdentityDocumentInfo');
    case manageUserIdentityActions.DELETE_USER_IDENTITY_DOCUMENT_ERROR:
      return failedState(state, 'userIdentityDocumentInfo', error, { shouldResetData: false });

    case manageUserIdentityActions.UPDATE_USER_IDENTITY_DOCUMENT_INFORMATION_REQUEST:
      return loadingState(state, 'userIdentityDocumentInfo');
    case manageUserIdentityActions.UPDATE_USER_IDENTITY_DOCUMENT_INFORMATION_SUCCESS:
      return successStateWithData(state, 'userIdentityDocumentInfo', payload);
    case manageUserIdentityActions.UPDATE_USER_IDENTITY_DOCUMENT_INFORMATION_ERROR:
      return failedState(state, 'userIdentityDocumentInfo', error, { shouldResetData: false });

    case manageUserIdentityActions.MANAGE_IDENTITY_FACE_VALIDATION_REQUEST:
      return loadingState(state, 'manageIdentityFaceValidation');
    case manageUserIdentityActions.MANAGE_IDENTITY_FACE_VALIDATION_SUCCESS:
      return successStateWithData(state, 'manageIdentityFaceValidation', payload);
    case manageUserIdentityActions.MANAGE_IDENTITY_FACE_VALIDATION_ERROR:
      return failedState(state, 'manageIdentityFaceValidation', error, { shouldResetData: false });
    case manageUserIdentityActions.MANAGE_IDENTITY_FACE_VALIDATION_RESET:
      return resetState(state, 'manageIdentityFaceValidation');

    case manageUserIdentityActions.MANAGE_IDENTITY_FACE_UPLOAD_REQUEST:
      return loadingState(state, 'manageIdentityFaceUpload');
    case manageUserIdentityActions.MANAGE_IDENTITY_FACE_UPLOAD_SUCCESS:
      state = successStateWithData(state, 'userIdentityFaceInfo', payload);
      state = successStateWithData(state, 'manageIdentityFaceUpload', payload);
      return state;
    case manageUserIdentityActions.MANAGE_IDENTITY_FACE_UPLOAD_ERROR:
      return failedState(state, 'manageIdentityFaceUpload', error, { shouldResetData: false });
    case manageUserIdentityActions.MANAGE_IDENTITY_FACE_UPLOAD_RESET:
      return resetState(state, 'manageIdentityFaceUpload');

    case manageUserIdentityActions.UPDATE_USER_IDENTITY_FACE_SUCCESS:
      return successStateWithData(state, 'userIdentityFaceInfo', payload);
    case manageUserIdentityActions.IDENTITY_DOCUMENT_VALIDATION_REQUEST:
      return loadingState(state, 'userIdentityDocumentValidation');
    case manageUserIdentityActions.IDENTITY_DOCUMENT_VALIDATION_SUCCESS:
      return successStateWithData(state, 'userIdentityDocumentValidation', payload);
    case manageUserIdentityActions.IDENTITY_DOCUMENT_VALIDATION_ERROR:
      return failedState(state, 'userIdentityDocumentValidation', error, { shouldResetData: false });
    case manageUserIdentityActions.IDENTITY_DOCUMENT_VALIDATION_RESET:
      return resetState(state, 'userIdentityDocumentValidation');

    case manageUserIdentityActions.IDENTITY_DOCUMENT_EXTRACTION_REQUEST:
      return loadingState(state, 'userIdentityDocumentExtraction');
    case manageUserIdentityActions.IDENTITY_DOCUMENT_EXTRACTION_SUCCESS:
      return successStateWithData(state, 'userIdentityDocumentExtraction', payload);
    case manageUserIdentityActions.IDENTITY_DOCUMENT_EXTRACTION_ERROR:
      return failedState(state, 'userIdentityDocumentExtraction', error, { shouldResetData: false });
    case manageUserIdentityActions.IDENTITY_DOCUMENT_EXTRACTION_RESET:
      return resetState(state, 'userIdentityDocumentExtraction');

    case manageUserIdentityActions.IDENTITY_DOCUMENT_CONFIRMATION_REQUEST:
      return loadingState(state, 'userIdentityDocumentConfirmation');
    case manageUserIdentityActions.IDENTITY_DOCUMENT_CONFIRMATION_SUCCESS:
      state = resetState(state, 'userIdentityDocumentValidation', payload);
      state = resetState(state, 'userIdentityDocumentExtraction', payload);
      state = resetState(state, 'userIdentityDocumentConfirmation', payload);
      state = successStateWithData(state, 'userIdentityDocumentInfo', payload);
      return state;
    case manageUserIdentityActions.IDENTITY_DOCUMENT_CONFIRMATION_ERROR:
      return failedState(state, 'userIdentityDocumentConfirmation', error, { shouldResetData: false });
    case manageUserIdentityActions.IDENTITY_DOCUMENT_CONFIRMATION_RESET:
      return resetState(state, 'userIdentityDocumentConfirmation');

    case manageUserIdentityActions.RESET_ALL_STATE:
      return initManageUserIdentityState;

    default:
      return state;
  }
}
