const actions = {
  FETCH_MY_DEGREE_LIST_REQUEST: 'degree/FETCH_MY_DEGREE_LIST_REQUEST',
  FETCH_MY_DEGREE_LIST_SUCCESS: 'degree/FETCH_MY_DEGREE_LIST_SUCCESS',
  FETCH_MY_DEGREE_LIST_ERROR: 'degree/FETCH_MY_DEGREE_LIST_ERROR',

  fetch_my_degree_list: (query?: any) => ({
    type: actions.FETCH_MY_DEGREE_LIST_REQUEST,
    query,
  }),
};

export default actions;
