import { ACADEMIC_TYPE } from '@common/type/CourseTypes';

export const ROUTES = {
  COURSE: {
    VIEW_DETAIL: (idName: string) => `/courses/${idName}`,
    PAYMENT: (idName: string) => `/courses/${idName}/payment`,
    PAYMENT_ACADEMIC: (idName: string, academicType: ACADEMIC_TYPE) =>
      `/courses/${idName}/payment?academic_type=${academicType}`,
    LEARNING: (idName: string) => `/learning/${idName}`,
    EDIT: (idName: string) => `/system_admins/courses/${idName}/edit`,
    SEMINAR: (idName: string) => `/courses/${idName}/seminar`,
    CPD: (idName: string) => `/cpd/courses/${idName}`,
  },
  BUNDLE: {
    VIEW_DETAIL: (idName: string) => `/bundles/${idName}`,
    CPD: (idName: string) => `/cpd/bundles/${idName}`,
    LIST: () => '/sections/bundle',
    PAYMENT: (idName: string) => `/bundles/${idName}/payment`,
    EDIT: (idName: string) => `/system_admins/course_bundles/${idName}/edit`,
  },
  MANAGE_USER_IDENTITY: {
    LIST: () => '/admin/management-users/e-kyc',
    VIEW_DETAIL: (userId: number) => `/admin/management-users/e-kyc/${userId}`,
    MANAGE_EKYC_USER: (userId: number, quizId: number) =>
      `/admin/management-users/e-kyc/${userId}/quizzes/${quizId}/identity-logs`,
  },
  MANAGE_COURSE: {
    EDIT: (courseId: number) => `/system_admins/courses/${courseId}/edit`,
  },
  USER: {
    MY_COURSE: () => '/user?tab=my-courses'
  }
} as const;
