import Router from 'next/router';

import { Path } from 'path-parser';
import config from 'src/config';
import { IUserOTP } from 'src/interfaces/user';
import Cookies from 'universal-cookie';

import { ROUTES } from './constants';

const setAuthStatus = () => {
  const cookies = new Cookies();
  cookies.set('auth_status', 'request', { path: '/' });
};

export const setRedirectAfterLogin = (url: string) => {
  const cookies = new Cookies();
  cookies.set('skl_referer', url, { path: '/' });
};

export const setEnrollCookie = (url: string) => {
  const cookies = new Cookies();
  if (url && url.includes('courses') && !url.includes('payment')) {
    cookies.set('enroll', true, { path: '/' });
  } else {
    cookies.remove('enroll', { path: '/' });
  }
};

export const setBundleEnrollCookie = (pathname: string) => {
  const cookies = new Cookies();
  if (new Path('/bundles/:id').test(pathname)) {
    const enrollUrl = new URL(`${pathname}/enroll/`, config.APP_DOMAIN);
    cookies.set('bundle_enroll', enrollUrl.toString(), { path: '/' });
  } else {
    cookies.remove('bundle_enroll', { path: '/' });
  }
}

export const setAcademicEnrollCookie = (pathname: string, academicType: string) => {
  const cookies = new Cookies();
  if (new Path('/courses/:id').test(pathname)) {
    const enrollUrl = new URL(`${pathname}/enroll/`, config.APP_DOMAIN);
    enrollUrl.searchParams.append('academic_type', academicType);
    cookies.set('academic_enroll', enrollUrl.toString(), { path: '/' });
  } else {
    cookies.remove('academic_enroll', { path: '/' });
  }
};

export const getToken = () => {
  if (typeof window !== 'undefined') {
    const cookies = new Cookies();
    return cookies.get('access_token');
  }
  return '';
};

export const login = (url: string = '') => {
  setAuthStatus();
  if (url) {
    setRedirectAfterLogin(url);
  } else {
    setRedirectAfterLogin(window.location.href);
  }
  Router.push(ROUTES.LOGIN);
};

export const register = () => {
  setAuthStatus();
  Router.push(ROUTES.REGISTER);
};

export const checkIsAuthSuccess = (): boolean => {
  const cookies = new Cookies();
  const authStatus = cookies.get('auth_status');
  cookies.remove('auth_status', { path: '/' });
  if (authStatus === 'success') {
    return true;
  }
  return false;
};

export const setOTPCookie = (data: IUserOTP) => {
  const cookies = new Cookies();
  cookies.set('otp', data, { path: '/' });
};

export const getOTPCookie = () => {
  const cookies = new Cookies();
  const otp: IUserOTP = cookies.get('otp');
  if (new Date(otp?.expiredAt) > new Date()) {
    return otp;
  }
  return null;
};

export const removeOTPCookie = () => {
  const cookies = new Cookies();
  cookies.remove('otp', { path: '/' });
};

export const getCPDCoursesPath = () => {
  const cookies = new Cookies();
  const redirectPath = cookies.get('skl_referer');
  const convertUrl = redirectPath.split('/');
  if (convertUrl.includes('cpd')) {
    return redirectPath;
  }
  return '';
};

export const setConfirmEmailRedirectCookie = (sklCookie = '') => {
  const cookies = new Cookies();
  let redirectURL = sklCookie || cookies.get('skl_referer');
  if (sklCookie) {
    cookies.remove('academic_enroll', { path: '/' });
  }
  let convertUrl = redirectURL.split('/');
  if (convertUrl.includes('cpd')) {
    return;
  }
  const coursesIndex = convertUrl.indexOf('courses');
  const bundlesIndex = convertUrl.indexOf('bundles');
  const courseBundlesIndex = convertUrl.indexOf('course_bundles');

  const enrollIndex = convertUrl.lastIndexOf('enroll');
  const paymentIndex = convertUrl.lastIndexOf('payment');

  if (enrollIndex > 0) {
    convertUrl = convertUrl.splice(0, enrollIndex);
    redirectURL = `${convertUrl.join('/')}`;
  }

  if (paymentIndex > 0) {
    convertUrl = convertUrl.splice(0, paymentIndex);
    redirectURL = convertUrl.join('/');
  }
  cookies.set('enroll', true, { path: '/' });
  if (coursesIndex > 0) {
    redirectURL = `${config.APP_DOMAIN}/${convertUrl.slice(coursesIndex).join('/')}`;
  } else if (bundlesIndex > 0) {
    cookies.remove('enroll', { path: '/' });
    redirectURL = `${config.APP_DOMAIN}/${convertUrl.slice(bundlesIndex).join('/')}/payment`;
  } else if (courseBundlesIndex > 0) {
    cookies.remove('enroll', { path: '/' });
    convertUrl[courseBundlesIndex] = 'bundles';
    redirectURL = `${config.APP_DOMAIN}/${convertUrl.slice(courseBundlesIndex).join('/')}/payment`;
  }

  cookies.set('skl_referer', redirectURL, { path: '/' });
  cookies.set('skl_confirm_email_referer', redirectURL, { path: '/' });
};

export const isConfirmEmailCookieExists = () => {
  const cookies = new Cookies();
  const url = cookies.get('skl_confirm_email_referer');
  if (url) {
    return true;
  }
  return false;
};

export const removeConfirmEmailCookie = () => {
  const cookies = new Cookies();
  cookies.remove('skl_confirm_email_referer', { path: '/' });
};

export const reloadAfterMergedAccount = () => {
  const cookies = new Cookies();
  const newUser = cookies.get('merge_account');
  if (newUser) {
    cookies.remove('merge_account');
    Router.reload();
  }
};
