import config from 'src/config';
import Http from 'src/helpers/httpClient';

export const APP_END_POINT = {
  APP_DATA: 'v1/app-data',
  COVER_PHOTOS: 'v1/cover-photos',
  B2B_CONTENT_STATISTIC: 'v1/b2b-content-statistic',
  BASE_CONFIG: 'v1/base-config',
  CATEGORIES: 'v1/categories',
};

export default {
  fetchAppData() {
    const apiUrl = `${config.API_DOMAIN}/${APP_END_POINT.APP_DATA}`;
    return Http.get(apiUrl, { isPublic: true });
  },

  fetchCoverPhotos() {
    const apiUrl = `${config.API_DOMAIN}/${APP_END_POINT.COVER_PHOTOS}`;
    return Http.get(apiUrl, { isPublic: true });
  },

  fetchB2BContentStatistic() {
    const apiUrl = `${config.API_DOMAIN}/${APP_END_POINT.B2B_CONTENT_STATISTIC}`;
    return Http.get(apiUrl, { isPublic: true });
  },
};
