import React from 'react';

import { Box } from '@mui/material';

type ImageBackgroundProps = {
  imgUrl: string;
};

const BackgroundImage = ({ imgUrl }: ImageBackgroundProps) => {
  return (
    <Box
      component="img"
      src={imgUrl}
      width="100%"
      sx={{
        height: { md: '100vh', xs: 'unset' },
        bottom: 0,
        position: 'absolute',
        objectFit: { md: 'cover', sx: 'contain' },
        zIndex: '-1',
      }}
    />
  );
};

export default BackgroundImage;
