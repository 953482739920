import {
  DeleteUserIdentityFaceErrorAction,
  DeleteUserIdentityFaceRequestAction,
  DeleteUserIdentityFaceSuccessAction,
  ManageUserIdentityErrorAction,
  ManageUserIdentityRequestAction,
  ManageUserIdentitySuccessAction,
  DeleteUserIdentityDocumentErrorAction,
  DeleteUserIdentityDocumentRequestAction,
  DeleteUserIdentityDocumentSuccessAction,
  UpdateUserIdentityDocumentErrorAction,
  UpdateUserIdentityDocumentRequestAction,
  UpdateUserIdentityDocumentSuccessAction,
  UpdateUserIdentityFaceSuccessAction,
  ManageIdentityFaceUploadErrorAction,
  ManageIdentityFaceUploadRequestAction,
  ManageIdentityFaceUploadSuccessAction,
  ManageIdentityImageValidationRequestAction,
  ManageIdentityImageValidationSuccessAction,
  ManageIdentityImageValidationErrorAction,
  ManageIdentityDocumentExtractionRequestAction,
  ManageIdentityDocumentConfirmationRequestAction,
  ManageIdentityDocumentConfirmationErrorAction,
  ManageIdentityDocumentConfirmationSuccessAction,
  ManageIdentityDocumentExtractionErrorAction,
  ManageIdentityDocumentExtractionSuccessAction,
} from '@redux/manageUserIdentity/type';

const manageUserIdentityActions = {
  RESET_ALL_STATE: 'MANAGE_USER_IDENTITY/RESET_ALL_STATE',
  resetAllStateAction: () => ({
    type: manageUserIdentityActions.RESET_ALL_STATE,
  }),
  FETCH_USER_IDENTITY_INFO_REQUEST: 'MANAGE_USER_IDENTITY/FETCH_USER_IDENTITY_INFO_REQUEST',
  FETCH_USER_IDENTITY_INFO_SUCCESS: 'MANAGE_USER_IDENTITY/FETCH_USER_IDENTITY_INFO_SUCCESS',
  FETCH_USER_IDENTITY_INFO_ERROR: 'MANAGE_USER_IDENTITY/FETCH_USER_IDENTITY_INFO_ERROR',
  FETCH_USER_IDENTITY_INFO_RESET: 'MANAGE_USER_IDENTITY/FETCH_USER_IDENTITY_INFO_RESET',
  fetchUserIdentityInfoAction: {
    request: (payload: ManageUserIdentityRequestAction) => ({
      type: manageUserIdentityActions.FETCH_USER_IDENTITY_INFO_REQUEST,
      payload,
    }),
    success: (payload: ManageUserIdentitySuccessAction) => ({
      type: manageUserIdentityActions.FETCH_USER_IDENTITY_INFO_SUCCESS,
      payload,
    }),
    error: (error: ManageUserIdentityErrorAction) => ({
      type: manageUserIdentityActions.FETCH_USER_IDENTITY_INFO_ERROR,
      error,
    }),
    reset: () => ({
      type: manageUserIdentityActions.FETCH_USER_IDENTITY_INFO_RESET,
    }),
  },

  DELETE_USER_IDENTITY_FACE_REQUEST: 'MANAGE_USER_IDENTITY/DELETE_USER_IDENTITY_FACE_REQUEST',
  DELETE_USER_IDENTITY_FACE_SUCCESS: 'MANAGE_USER_IDENTITY/DELETE_USER_IDENTITY_FACE_SUCCESS',
  DELETE_USER_IDENTITY_FACE_ERROR: 'MANAGE_USER_IDENTITY/DELETE_USER_IDENTITY_FACE_ERROR',
  DELETE_USER_IDENTITY_FACE_RESET: 'MANAGE_USER_IDENTITY/DELETE_USER_IDENTITY_FACE_RESET',
  deleteUserIdentityFaceAction: {
    request: (payload: DeleteUserIdentityFaceRequestAction) => ({
      type: manageUserIdentityActions.DELETE_USER_IDENTITY_FACE_REQUEST,
      payload,
    }),
    success: (payload: DeleteUserIdentityFaceSuccessAction) => ({
      type: manageUserIdentityActions.DELETE_USER_IDENTITY_FACE_SUCCESS,
      payload,
    }),
    error: (error: DeleteUserIdentityFaceErrorAction) => ({
      type: manageUserIdentityActions.DELETE_USER_IDENTITY_FACE_ERROR,
      error,
    }),
    reset: () => ({
      type: manageUserIdentityActions.DELETE_USER_IDENTITY_FACE_RESET,
    }),
  },

  DELETE_USER_IDENTITY_DOCUMENT_REQUEST: 'MANAGE_USER_IDENTITY/DELETE_USER_IDENTITY_DOCUMENT_REQUEST',
  DELETE_USER_IDENTITY_DOCUMENT_SUCCESS: 'MANAGE_USER_IDENTITY/DELETE_USER_IDENTITY_DOCUMENT_SUCCESS',
  DELETE_USER_IDENTITY_DOCUMENT_ERROR: 'MANAGE_USER_IDENTITY/DELETE_USER_IDENTITY_DOCUMENT_ERROR',
  DELETE_USER_IDENTITY_DOCUMENT_RESET: 'MANAGE_USER_IDENTITY/DELETE_USER_IDENTITY_DOCUMENT_RESET',
  deleteUserIdentityDocumentAction: {
    request: (payload: DeleteUserIdentityDocumentRequestAction) => ({
      type: manageUserIdentityActions.DELETE_USER_IDENTITY_DOCUMENT_REQUEST,
      payload,
    }),
    success: (payload: DeleteUserIdentityDocumentSuccessAction) => ({
      type: manageUserIdentityActions.DELETE_USER_IDENTITY_DOCUMENT_SUCCESS,
      payload,
    }),
    error: (error: DeleteUserIdentityDocumentErrorAction) => ({
      type: manageUserIdentityActions.DELETE_USER_IDENTITY_DOCUMENT_ERROR,
      error,
    }),
    reset: () => ({
      type: manageUserIdentityActions.DELETE_USER_IDENTITY_DOCUMENT_RESET,
    }),
  },

  UPDATE_USER_IDENTITY_DOCUMENT_INFORMATION_REQUEST:
    'MANAGE_USER_IDENTITY/UPDATE_IDENTITY_DOCUMENT_INFORMATION_REQUEST',
  UPDATE_USER_IDENTITY_DOCUMENT_INFORMATION_SUCCESS:
    'MANAGE_USER_IDENTITY/UPDATE_IDENTITY_DOCUMENT_INFORMATION_SUCCESS',
  UPDATE_USER_IDENTITY_DOCUMENT_INFORMATION_ERROR: 'MANAGE_USER_IDENTITY/UPDATE_IDENTITY_DOCUMENT_INFORMATION_ERROR',
  updateUserIdentityDocumentInformationAction: {
    request: (payload: UpdateUserIdentityDocumentRequestAction) => ({
      type: manageUserIdentityActions.UPDATE_USER_IDENTITY_DOCUMENT_INFORMATION_REQUEST,
      payload,
    }),
    success: (payload: UpdateUserIdentityDocumentSuccessAction) => ({
      type: manageUserIdentityActions.UPDATE_USER_IDENTITY_DOCUMENT_INFORMATION_SUCCESS,
      payload,
    }),
    error: (error: UpdateUserIdentityDocumentErrorAction) => ({
      type: manageUserIdentityActions.UPDATE_USER_IDENTITY_DOCUMENT_INFORMATION_ERROR,
      error,
    }),
  },

  UPDATE_USER_IDENTITY_FACE_SUCCESS: 'MANAGE_USER_IDENTITY',
  updateUserIdentityFace: {
    success: (payload: UpdateUserIdentityFaceSuccessAction) => ({
      type: manageUserIdentityActions.UPDATE_USER_IDENTITY_FACE_SUCCESS,
      payload,
    }),
  },

  MANAGE_IDENTITY_FACE_VALIDATION_REQUEST: 'MANAGE_USER_IDENTITY/IDENTITY_FACE_VALIDATION_REQUEST',
  MANAGE_IDENTITY_FACE_VALIDATION_SUCCESS: 'MANAGE_USER_IDENTITY/IDENTITY_FACE_VALIDATION_SUCCESS',
  MANAGE_IDENTITY_FACE_VALIDATION_ERROR: 'MANAGE_USER_IDENTITY/IDENTITY_FACE_VALIDATION_ERROR',
  MANAGE_IDENTITY_FACE_VALIDATION_RESET: 'MANAGE_USER_IDENTITY/IDENTITY_FACE_VALIDATION_RESET',
  identityFaceValidationAction: {
    request: (payload: ManageIdentityImageValidationRequestAction) => ({
      type: manageUserIdentityActions.MANAGE_IDENTITY_FACE_VALIDATION_REQUEST,
      payload,
    }),
    success: (payload: ManageIdentityImageValidationSuccessAction) => ({
      type: manageUserIdentityActions.MANAGE_IDENTITY_FACE_VALIDATION_SUCCESS,
      payload,
    }),
    error: (error: ManageIdentityImageValidationErrorAction) => ({
      type: manageUserIdentityActions.MANAGE_IDENTITY_FACE_VALIDATION_ERROR,
      error,
    }),
    reset: () => ({
      type: manageUserIdentityActions.MANAGE_IDENTITY_FACE_VALIDATION_RESET,
    }),
  },

  MANAGE_IDENTITY_FACE_UPLOAD_REQUEST: 'MANAGE_USER_IDENTITY/IDENTITY_FACE_UPLOAD_REQUEST',
  MANAGE_IDENTITY_FACE_UPLOAD_SUCCESS: 'MANAGE_USER_IDENTITY/IDENTITY_FACE_UPLOAD_SUCCESS',
  MANAGE_IDENTITY_FACE_UPLOAD_ERROR: 'MANAGE_USER_IDENTITY/IDENTITY_FACE_UPLOAD_ERROR',
  MANAGE_IDENTITY_FACE_UPLOAD_RESET: 'MANAGE_USER_IDENTITY/IDENTITY_FACE_UPLOAD_RESET',
  identityFaceUploadAction: {
    request: (payload: ManageIdentityFaceUploadRequestAction) => ({
      type: manageUserIdentityActions.MANAGE_IDENTITY_FACE_UPLOAD_REQUEST,
      payload,
    }),
    success: (payload: ManageIdentityFaceUploadSuccessAction) => ({
      type: manageUserIdentityActions.MANAGE_IDENTITY_FACE_UPLOAD_SUCCESS,
      payload,
    }),
    error: (error: ManageIdentityFaceUploadErrorAction) => ({
      type: manageUserIdentityActions.MANAGE_IDENTITY_FACE_UPLOAD_ERROR,
      error,
    }),
    reset: () => ({
      type: manageUserIdentityActions.MANAGE_IDENTITY_FACE_UPLOAD_RESET,
    }),
  },

  IDENTITY_DOCUMENT_VALIDATION_REQUEST: 'MANAGE_USER_IDENTITY/IDENTITY_DOCUMENT_VALIDATION_REQUEST',
  IDENTITY_DOCUMENT_VALIDATION_SUCCESS: 'MANAGE_USER_IDENTITY/IDENTITY_DOCUMENT_VALIDATION_SUCCESS',
  IDENTITY_DOCUMENT_VALIDATION_ERROR: 'MANAGE_USER_IDENTITY/IDENTITY_DOCUMENT_VALIDATION_ERROR',
  IDENTITY_DOCUMENT_VALIDATION_RESET: 'MANAGE_USER_IDENTITY/IDENTITY_DOCUMENT_VALIDATION_RESET',
  identityDocumentValidationAction: {
    request: (payload: ManageIdentityImageValidationRequestAction) => ({
      type: manageUserIdentityActions.IDENTITY_DOCUMENT_VALIDATION_REQUEST,
      payload,
    }),
    success: (payload: ManageIdentityImageValidationSuccessAction) => ({
      type: manageUserIdentityActions.IDENTITY_DOCUMENT_VALIDATION_SUCCESS,
      payload,
    }),
    error: (error: ManageIdentityImageValidationErrorAction) => ({
      type: manageUserIdentityActions.IDENTITY_DOCUMENT_VALIDATION_ERROR,
      error,
    }),
    reset: () => ({
      type: manageUserIdentityActions.IDENTITY_DOCUMENT_VALIDATION_RESET,
    }),
  },

  IDENTITY_DOCUMENT_EXTRACTION_REQUEST: 'MANAGE_USER_IDENTITY/IDENTITY_DOCUMENT_EXTRACTION_REQUEST',
  IDENTITY_DOCUMENT_EXTRACTION_SUCCESS: 'MANAGE_USER_IDENTITY/IDENTITY_DOCUMENT_EXTRACTION_SUCCESS',
  IDENTITY_DOCUMENT_EXTRACTION_ERROR: 'MANAGE_USER_IDENTITY/IDENTITY_DOCUMENT_EXTRACTION_ERROR',
  IDENTITY_DOCUMENT_EXTRACTION_RESET: 'MANAGE_USER_IDENTITY/IDENTITY_DOCUMENT_EXTRACTION_RESET',
  identityDocumentExtractionAction: {
    request: (payload: ManageIdentityDocumentExtractionRequestAction) => ({
      type: manageUserIdentityActions.IDENTITY_DOCUMENT_EXTRACTION_REQUEST,
      payload,
    }),
    success: (payload: ManageIdentityDocumentExtractionSuccessAction) => ({
      type: manageUserIdentityActions.IDENTITY_DOCUMENT_EXTRACTION_SUCCESS,
      payload,
    }),
    error: (error: ManageIdentityDocumentExtractionErrorAction) => ({
      type: manageUserIdentityActions.IDENTITY_DOCUMENT_EXTRACTION_ERROR,
      error,
    }),
    reset: () => ({
      type: manageUserIdentityActions.IDENTITY_DOCUMENT_EXTRACTION_RESET,
    }),
  },
  IDENTITY_DOCUMENT_CONFIRMATION_REQUEST: 'MANAGE_USER_IDENTITY/IDENTITY_DOCUMENT_CONFIRMATION_REQUEST',
  IDENTITY_DOCUMENT_CONFIRMATION_SUCCESS: 'MANAGE_USER_IDENTITY/IDENTITY_DOCUMENT_CONFIRMATION_SUCCESS',
  IDENTITY_DOCUMENT_CONFIRMATION_ERROR: 'MANAGE_USER_IDENTITY/IDENTITY_DOCUMENT_CONFIRMATION_ERROR',
  IDENTITY_DOCUMENT_CONFIRMATION_RESET: 'MANAGE_USER_IDENTITY/IDENTITY_DOCUMENT_CONFIRMATION_RESET',
  identityDocumentConfirmationAction: {
    request: (payload: ManageIdentityDocumentConfirmationRequestAction) => ({
      type: manageUserIdentityActions.IDENTITY_DOCUMENT_CONFIRMATION_REQUEST,
      payload,
    }),
    success: (payload: ManageIdentityDocumentConfirmationSuccessAction) => ({
      type: manageUserIdentityActions.IDENTITY_DOCUMENT_CONFIRMATION_SUCCESS,
      payload,
    }),
    error: (error: ManageIdentityDocumentConfirmationErrorAction) => ({
      type: manageUserIdentityActions.IDENTITY_DOCUMENT_CONFIRMATION_ERROR,
      error,
    }),
    reset: () => ({
      type: manageUserIdentityActions.IDENTITY_DOCUMENT_CONFIRMATION_RESET,
    }),
  },
};

export default manageUserIdentityActions;
