import { all, call, takeEvery, put } from 'redux-saga/effects';

import actions from './actions';
import appAPI from './api';

import { ResponseData } from 'src/helpers/httpClient';

function* fetch_app_data() {
  const response: ResponseData = yield call(appAPI.fetchAppData);
  yield put({
    type: actions.FETCH_APP_DATA_SUCCESS,
    payload: response.data,
  });
}

function* fetch_b2b_content_statistic() {
  const response: ResponseData = yield call(appAPI.fetchB2BContentStatistic);
  yield put({
    type: actions.FETCH_B2B_CONTENT_STATISTIC_SUCCESS,
    payload: response.data,
  });
}

function* fetch_watermark_period() {
  const response: ResponseData = yield call(appAPI.fetchWatermarkPeriod);
  yield put({
    type: actions.FETCH_WATERMARK_PERIOD_SUCCESS,
    payload: response.data,
  });
}

export default function* rootSaga() {
  yield all([takeEvery(actions.FETCH_APP_DATA_REQUEST, fetch_app_data)]);
  yield all([takeEvery(actions.FETCH_B2B_CONTENT_STATISTIC_REQUEST, fetch_b2b_content_statistic)]);
  yield all([takeEvery(actions.FETCH_WATERMARK_PERIOD_REQUEST, fetch_watermark_period)]);
}
