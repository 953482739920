import React from 'react';

import ComponentErrorBoundary from '@components/templates/errorPage/errorBoundary';

type ErrorBoundaryState = {
  hasError: boolean;
  errorMessage: string;
};

type ErrorBoundaryProps = {
  children: React.ReactNode;
};

class ErrorBoundary extends React.Component<ErrorBoundaryProps, ErrorBoundaryState> {
  constructor(props: ErrorBoundaryProps) {
    super(props);
    this.state = { hasError: false, errorMessage: '' };
  }

  static getDerivedStateFromError(error: Error): Partial<ErrorBoundaryState> {
    return { hasError: true };
  }

  componentDidCatch(error: Error, errorInfo: React.ErrorInfo) {
    this.setState({ errorMessage: error.message });
  }

  render() {
    const { hasError, errorMessage } = this.state;
    if (hasError) {
      return <ComponentErrorBoundary />;
    }
    const { children } = this.props;

    return children;
  }
}

export default ErrorBoundary;
