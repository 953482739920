import { Record } from 'immutable';

import Actions from './actions';
import { InteractiveContentAPIQueryProps, InteractiveContentSubmissionAPIQueryProps } from './interface';

import { SubmitInteractionAnswerResponse } from '@interfaces/learning';
import { updateQuery } from 'src/helpers/utils';

export type InteractiveContentStateType = {
  // TODO: move state interactive content from learning to interactiveContent
  interactiveContent: InteractiveContentAPIQueryProps | null;
  isInteractiveContentSubmissionLoading: boolean;
  interactiveContentSubmission: InteractiveContentSubmissionAPIQueryProps | null;
  interactiveContentSubmissionError: any | null;
  interactiveContentSubmissionStatus: any | null;

  interactiveContentAnswerDetail: SubmitInteractionAnswerResponse['detail'] | null;

  isInteractiveContentSubmitSuccess: boolean;
  interactionResultSubmit: SubmitInteractionAnswerResponse | null;

  isInteractiveContentStartSuccess: boolean;
  isInteractiveContentCloseSuccess: boolean;
};

type InteractiveContentReducerType = {
  type: string;
  data: any;
  status: any;
  error: boolean;
};

const initState = new (Record<InteractiveContentStateType>({
  interactiveContent: null,
  isInteractiveContentSubmissionLoading: false,
  interactiveContentSubmission: null,
  interactiveContentSubmissionError: false,
  interactiveContentSubmissionStatus: null,
  interactionResultSubmit: null,
  interactiveContentAnswerDetail: null,
  isInteractiveContentStartSuccess: false,
  isInteractiveContentSubmitSuccess: false,
  isInteractiveContentCloseSuccess: false,
}))();

export default function reducer(state = initState, { type, data, status }: InteractiveContentReducerType) {
  switch (type) {
    case Actions.CLEAN_INTERACTIVE_CONTENT_SUBMISSION:
      return state
        .set('isInteractiveContentSubmitSuccess', false)
        .set('interactiveContentSubmissionError', false)
        .set('interactiveContentSubmissionStatus', null)
        .set('interactionResultSubmit', null)
        .set('interactiveContentAnswerDetail', null);
    case Actions.INTERACTIVE_CONTENT_START_REQUEST:
      return state
        .set('isInteractiveContentSubmissionLoading', true)
        .set('isInteractiveContentStartSuccess', false)
        .set('isInteractiveContentSubmitSuccess', false)
        .set('isInteractiveContentCloseSuccess', false)
        .set('interactionResultSubmit', null)
        .set('interactiveContentAnswerDetail', null);
    case Actions.INTERACTIVE_CONTENT_START_SUCCESS:
      return state
        .set('isInteractiveContentSubmissionLoading', false)
        .set('interactiveContentSubmission', data)
        .set('interactiveContentSubmissionStatus', status)
        .set('interactiveContentSubmissionError', false)
        .set('isInteractiveContentStartSuccess', true);
    case Actions.INTERACTIVE_CONTENT_START_ERROR:
      return state
        .set('isInteractiveContentSubmissionLoading', false)
        .set('interactiveContentSubmissionStatus', status)
        .set('interactiveContentSubmissionError', true);
    case Actions.INTERACTIVE_CONTENT_SUBMIT_REQUEST:
      return state
        .set('isInteractiveContentSubmissionLoading', true)
        .set('isInteractiveContentStartSuccess', false)
        .set('isInteractiveContentSubmitSuccess', false)
        .set('isInteractiveContentCloseSuccess', false)
        .set('interactionResultSubmit', null);
    case Actions.INTERACTIVE_CONTENT_SUBMIT_SUCCESS:
      return state
        .set('isInteractiveContentSubmissionLoading', false)
        .set('interactiveContentSubmission', null)
        .set('interactiveContentSubmissionStatus', status)
        .set('interactiveContentSubmissionError', false)
        .set('isInteractiveContentSubmitSuccess', true)
        .set('interactionResultSubmit', data);
    case Actions.INTERACTIVE_CONTENT_SUBMIT_ERROR:
      return state
        .set('isInteractiveContentSubmissionLoading', false)
        .set('interactiveContentSubmissionStatus', status)
        .set('interactiveContentSubmissionError', true);
    case Actions.INTERACTIVE_CONTENT_SEND_ANSWER_REQUEST:
      return state
        .set('isInteractiveContentSubmissionLoading', true)
        .set('isInteractiveContentStartSuccess', false)
        .set('isInteractiveContentSubmitSuccess', false)
        .set('isInteractiveContentCloseSuccess', false)
        .set('interactionResultSubmit', null);
    case Actions.INTERACTIVE_CONTENT_SEND_ANSWER_SUCCESS:
      return state
        .set('isInteractiveContentSubmissionLoading', false)
        .set('interactiveContentSubmission', null)
        .set('interactiveContentSubmissionStatus', status)
        .set('interactiveContentSubmissionError', false)
        .set('isInteractiveContentSubmitSuccess', true)
        .set('interactiveContentAnswerDetail', data);
    case Actions.INTERACTIVE_CONTENT_SEND_ANSWER_ERROR:
      return state
        .set('isInteractiveContentSubmissionLoading', false)
        .set('interactiveContentSubmissionStatus', status)
        .set('interactiveContentSubmissionError', true);
    case Actions.INTERACTIVE_CONTENT_CLOSE_REQUEST:
      return state
        .set('isInteractiveContentSubmissionLoading', true)
        .set('isInteractiveContentStartSuccess', false)
        .set('isInteractiveContentSubmitSuccess', false)
        .set('isInteractiveContentCloseSuccess', false)
        .set('interactionResultSubmit', null);
    case Actions.INTERACTIVE_CONTENT_CLOSE_SUCCESS:
      return state
        .set('isInteractiveContentSubmissionLoading', false)
        .set('interactiveContentSubmission', null)
        .set('interactiveContentSubmissionStatus', status)
        .set('interactiveContentSubmissionError', false)
        .set('isInteractiveContentCloseSuccess', true);
    case Actions.INTERACTIVE_CONTENT_CLOSE_ERROR:
      return state
        .set('isInteractiveContentSubmissionLoading', false)
        .set('interactiveContentSubmissionStatus', status)
        .set('interactiveContentSubmissionError', true);
    default:
      return state;
  }
}
