import config from 'src/config';
import Http from 'src/helpers/httpClient';

const api = {
  fetcUniversityFilter() {
    const apiUrl = `${config.API_DOMAIN}/v1/universities/get-university-filter`;
    return Http.get(apiUrl);
  },
};

export default api;
