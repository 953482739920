const actions = {
  FETCH_UNIVERSITY_FILTER_REQUEST: 'degree/FETCH_DEGREE_UNIVERSITY_FILTER_REQUEST',
  FETCH_UNIVERSITY_FILTER_SUCCESS: 'degree/FETCH_DEGREE_UNIVERSITY_FILTER_SUCCESS',
  FETCH_UNIVERSITY_FILTER_ERROR: 'degree/FETCH_DEGREE_UNIVERSITY_FILTER_ERROR',

  fetch_university_filter: (query?: any) => ({
    type: actions.FETCH_UNIVERSITY_FILTER_REQUEST,
    query,
  }),
};

export default actions;
