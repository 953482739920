import { all, call, takeEvery, put, fork } from 'redux-saga/effects';

import actions from './actions';
import degreeAPI from './api';

import { ICoreApiResponse } from '@interfaces/common';
import { ResponseDataV2 } from 'src/helpers/httpClient';

export function* fetch_my_degree_list() {
  yield takeEvery(actions.FETCH_MY_DEGREE_LIST_REQUEST, function*(payload: any) {
    const apiResponse: ResponseDataV2<ICoreApiResponse<any, any>> = yield call(
      degreeAPI.fetchMyDegreeList,
      payload.query,
    );
    if (apiResponse.data?.status === '000') {
      yield put({
        type: actions.FETCH_MY_DEGREE_LIST_SUCCESS,
        data: apiResponse.data.data.list,
        status: apiResponse.data.status,
      });
    } else {
      yield put({
        type: actions.FETCH_MY_DEGREE_LIST_ERROR,
      });
    }
  });
}

export default function* rootSaga() {
  yield all([fork(fetch_my_degree_list)]);
}
