import {
  IdentityDocumentConfirmationErrorAction,
  IdentityDocumentConfirmationRequestAction,
  IdentityDocumentConfirmationSuccessAction,
  IdentityDocumentExtractionErrorAction,
  IdentityDocumentExtractionRequestAction,
  IdentityDocumentExtractionSuccessAction,
  IdentityDocumentInformationErrorAction,
  IdentityDocumentInformationRequestAction,
  IdentityDocumentInformationSuccessAction,
  IdentityFaceComparisonErrorAction,
  IdentityFaceComparisonRequestAction,
  IdentityFaceComparisonSuccessAction,
  IdentityImageValidationErrorAction,
  IdentityImageValidationRequestAction,
  IdentityImageValidationSuccessAction,
} from '@redux/userIdentity/type';

const userIdentityActions = {
  RESET_ALL_STATE: 'USER_IDENTITY/RESET_ALL_STATE',
  resetAllStateAction: () => ({
    type: userIdentityActions.RESET_ALL_STATE,
  }),
  IDENTITY_DOCUMENT_VALIDATION_REQUEST: 'USER_IDENTITY/IDENTITY_DOCUMENT_VALIDATION_REQUEST',
  IDENTITY_DOCUMENT_VALIDATION_SUCCESS: 'USER_IDENTITY/IDENTITY_DOCUMENT_VALIDATION_SUCCESS',
  IDENTITY_DOCUMENT_VALIDATION_ERROR: 'USER_IDENTITY/IDENTITY_DOCUMENT_VALIDATION_ERROR',
  IDENTITY_DOCUMENT_VALIDATION_RESET: 'USER_IDENTITY/IDENTITY_DOCUMENT_VALIDATION_RESET',
  identityDocumentValidationAction: {
    request: (payload: IdentityImageValidationRequestAction) => ({
      type: userIdentityActions.IDENTITY_DOCUMENT_VALIDATION_REQUEST,
      payload,
    }),
    success: (payload: IdentityImageValidationSuccessAction) => ({
      type: userIdentityActions.IDENTITY_DOCUMENT_VALIDATION_SUCCESS,
      payload,
    }),
    error: (error: IdentityImageValidationErrorAction) => ({
      type: userIdentityActions.IDENTITY_DOCUMENT_VALIDATION_ERROR,
      error,
    }),
    reset: () => ({
      type: userIdentityActions.IDENTITY_DOCUMENT_VALIDATION_RESET,
    }),
  },
  IDENTITY_FACE_VALIDATION_REQUEST: 'USER_IDENTITY/IDENTITY_FACE_VALIDATION_REQUEST',
  IDENTITY_FACE_VALIDATION_SUCCESS: 'USER_IDENTITY/IDENTITY_FACE_VALIDATION_SUCCESS',
  IDENTITY_FACE_VALIDATION_ERROR: 'USER_IDENTITY/IDENTITY_FACE_VALIDATION_ERROR',
  IDENTITY_FACE_VALIDATION_RESET: 'USER_IDENTITY/IDENTITY_FACE_VALIDATION_RESET',
  identityFaceValidationAction: {
    request: (payload: IdentityImageValidationRequestAction) => ({
      type: userIdentityActions.IDENTITY_FACE_VALIDATION_REQUEST,
      payload,
    }),
    success: (payload: IdentityImageValidationSuccessAction) => ({
      type: userIdentityActions.IDENTITY_FACE_VALIDATION_SUCCESS,
      payload,
    }),
    error: (error: IdentityImageValidationErrorAction) => ({
      type: userIdentityActions.IDENTITY_FACE_VALIDATION_ERROR,
      error,
    }),
    reset: () => ({
      type: userIdentityActions.IDENTITY_FACE_VALIDATION_RESET,
    }),
  },
  IDENTITY_DOCUMENT_EXTRACTION_REQUEST: 'USER_IDENTITY/IDENTITY_DOCUMENT_EXTRACTION_REQUEST',
  IDENTITY_DOCUMENT_EXTRACTION_SUCCESS: 'USER_IDENTITY/IDENTITY_DOCUMENT_EXTRACTION_SUCCESS',
  IDENTITY_DOCUMENT_EXTRACTION_ERROR: 'USER_IDENTITY/IDENTITY_DOCUMENT_EXTRACTION_ERROR',
  IDENTITY_DOCUMENT_EXTRACTION_RESET: 'USER_IDENTITY/IDENTITY_DOCUMENT_EXTRACTION_RESET',
  identityDocumentExtractionAction: {
    request: (payload: IdentityDocumentExtractionRequestAction) => ({
      type: userIdentityActions.IDENTITY_DOCUMENT_EXTRACTION_REQUEST,
      payload,
    }),
    success: (payload: IdentityDocumentExtractionSuccessAction) => ({
      type: userIdentityActions.IDENTITY_DOCUMENT_EXTRACTION_SUCCESS,
      payload,
    }),
    error: (error: IdentityDocumentExtractionErrorAction) => ({
      type: userIdentityActions.IDENTITY_DOCUMENT_EXTRACTION_ERROR,
      error,
    }),
    reset: () => ({
      type: userIdentityActions.IDENTITY_DOCUMENT_EXTRACTION_RESET,
    }),
  },
  IDENTITY_DOCUMENT_CONFIRMATION_REQUEST: 'USER_IDENTITY/IDENTITY_DOCUMENT_CONFIRMATION_REQUEST',
  IDENTITY_DOCUMENT_CONFIRMATION_SUCCESS: 'USER_IDENTITY/IDENTITY_DOCUMENT_CONFIRMATION_SUCCESS',
  IDENTITY_DOCUMENT_CONFIRMATION_ERROR: 'USER_IDENTITY/IDENTITY_DOCUMENT_CONFIRMATION_ERROR',
  IDENTITY_DOCUMENT_CONFIRMATION_RESET: 'USER_IDENTITY/IDENTITY_DOCUMENT_CONFIRMATION_RESET',
  identityDocumentConfirmationAction: {
    request: (payload: IdentityDocumentConfirmationRequestAction) => ({
      type: userIdentityActions.IDENTITY_DOCUMENT_CONFIRMATION_REQUEST,
      payload,
    }),
    success: (payload: IdentityDocumentConfirmationSuccessAction) => ({
      type: userIdentityActions.IDENTITY_DOCUMENT_CONFIRMATION_SUCCESS,
      payload,
    }),
    error: (error: IdentityDocumentConfirmationErrorAction) => ({
      type: userIdentityActions.IDENTITY_DOCUMENT_CONFIRMATION_ERROR,
      error,
    }),
    reset: () => ({
      type: userIdentityActions.IDENTITY_DOCUMENT_CONFIRMATION_RESET,
    }),
  },
  IDENTITY_FACE_COMPARISON_REQUEST: 'USER_IDENTITY/IDENTITY_FACE_COMPARISON_REQUEST',
  IDENTITY_FACE_COMPARISON_SUCCESS: 'USER_IDENTITY/IDENTITY_FACE_COMPARISON_SUCCESS',
  IDENTITY_FACE_COMPARISON_ERROR: 'USER_IDENTITY/IDENTITY_FACE_COMPARISON_ERROR',
  IDENTITY_FACE_COMPARISON_RESET: 'USER_IDENTITY/IDENTITY_FACE_COMPARISON_RESET',
  identityFaceComparisonAction: {
    request: (payload: IdentityFaceComparisonRequestAction) => ({
      type: userIdentityActions.IDENTITY_FACE_COMPARISON_REQUEST,
      payload,
    }),
    success: (payload: IdentityFaceComparisonSuccessAction) => ({
      type: userIdentityActions.IDENTITY_FACE_COMPARISON_SUCCESS,
      payload,
    }),
    error: (error: IdentityFaceComparisonErrorAction) => ({
      type: userIdentityActions.IDENTITY_FACE_COMPARISON_ERROR,
      error,
    }),
    reset: () => ({
      type: userIdentityActions.IDENTITY_FACE_COMPARISON_RESET,
    }),
  },

  IDENTITY_DOCUMENT_INFORMATION_REQUEST: 'USER_IDENTITY/IDENTITY_DOCUMENT_INFORMATION_REQUEST',
  IDENTITY_DOCUMENT_INFORMATION_SUCCESS: 'USER_IDENTITY/IDENTITY_DOCUMENT_INFORMATION_SUCCESS',
  IDENTITY_DOCUMENT_INFORMATION_ERROR: 'USER_IDENTITY/IDENTITY_DOCUMENT_INFORMATION_ERROR',
  IDENTITY_DOCUMENT_INFORMATION_RESET: 'USER_IDENTITY/IDENTITY_DOCUMENT_INFORMATION_RESET',
  identityDocumentInformationAction: {
    request: (payload: IdentityDocumentInformationRequestAction) => ({
      type: userIdentityActions.IDENTITY_DOCUMENT_INFORMATION_REQUEST,
      payload,
    }),
    success: (payload: IdentityDocumentInformationSuccessAction) => ({
      type: userIdentityActions.IDENTITY_DOCUMENT_INFORMATION_SUCCESS,
      payload,
    }),
    error: (error: IdentityDocumentInformationErrorAction) => ({
      type: userIdentityActions.IDENTITY_DOCUMENT_INFORMATION_ERROR,
      error,
    }),
    reset: () => ({
      type: userIdentityActions.IDENTITY_DOCUMENT_INFORMATION_RESET,
    }),
  },
};

export default userIdentityActions;
