import { all, call, put, takeEvery } from 'redux-saga/effects';

import { ICoreApiResponse } from '@interfaces/common';
import userIdentityActions from '@redux/userIdentity/action';
import userIdentityAPI from '@redux/userIdentity/api';
import {
  IdentityDocumentConfirmationRequestAction,
  IdentityDocumentConfirmationState,
  IdentityDocumentConfirmationStatusResponse,
  IdentityDocumentExtractionRequestAction,
  IdentityDocumentExtractionState,
  IdentityDocumentExtractionStatusResponse,
  IdentityDocumentInformationRequestAction,
  IdentityDocumentInformationState,
  IdentityDocumentInformationStatusResponse,
  IdentityFaceComparisonRequestAction,
  IdentityFaceComparisonState,
  IdentityFaceComparisonStatusResponse,
  IdentityImageValidationRequestAction,
  IdentityImageValidationState,
  IdentityImageValidationStatusResponse,
} from '@redux/userIdentity/type';

const {
  identityDocumentValidationAction,
  identityFaceValidationAction,
  identityDocumentExtractionAction,
  identityDocumentConfirmationAction,
  identityFaceComparisonAction,
  identityDocumentInformationAction,
} = userIdentityActions;

function* identityDocumentValidation(action: {
  type: typeof userIdentityActions;
  payload: IdentityImageValidationRequestAction;
}) {
  // TODO: revert SKL-12199 after find root cause of identity extraction time out
  try {
    yield put(identityDocumentExtractionAction.reset());

    const apiResponse: ICoreApiResponse<
      IdentityImageValidationState,
      IdentityImageValidationStatusResponse
    > = yield call(userIdentityAPI.identityImageValidation, action.payload);

    if (apiResponse?.status === 200) {
      yield put(
        identityDocumentExtractionAction.request({
          userIdentityImageValidationId: apiResponse.data?.userIdentityImageValidationId!,
        }),
      );
      yield put(
        identityDocumentValidationAction.success({
          ...apiResponse.data!,
          ...action.payload,
        }),
      );
    } else {
      switch (apiResponse?.status) {
        case 'USER_FACE_COMPARISON_INVALID_PARAMETER_ERROR':
          yield put(
            identityDocumentValidationAction.success({
              isQualityPassed: false,
              isBrightnessPassed: false,
              isSharpnessPassed: false,
              isNotMultiFaces: true,
              userIdentityImageValidationId: -1,
              ...action.payload,
            }),
          );
          yield put(
            identityDocumentExtractionAction.success({
              isTaxIdMatched: false,
              isCitizenIdDetected: false,
              firstNameTH: '',
              lastNameTH: '',
              firstNameEN: '',
              lastNameEN: '',
              citizenId: '',
              birthDate: new Date(),
              detectResult: '',
              userIdentityImageValidationId: -1,
            }),
          );
          break;
        case 'FACE_COMPARISON_ERROR':
        default:
          yield put(identityDocumentValidationAction.error(apiResponse));
          yield put(identityDocumentExtractionAction.error(apiResponse));
      }
    }
  } catch (error) {
    console.error('[identityDocumentValidation]', error);
    yield put(identityDocumentValidationAction.error(error));
    yield put(identityDocumentExtractionAction.error(error));
  }
}

function* identityFaceValidation(action: {
  type: typeof userIdentityActions;
  payload: IdentityImageValidationRequestAction;
}) {
  const apiResponse: ICoreApiResponse<IdentityImageValidationState, IdentityImageValidationStatusResponse> = yield call(
    userIdentityAPI.identityImageValidation,
    action.payload,
  );

  if (apiResponse?.status === 200) {
    yield put(
      identityFaceValidationAction.success({
        ...apiResponse.data!,
        ...action.payload,
      }),
    );
  } else {
    switch (apiResponse?.status) {
      case 'USER_FACE_COMPARISON_INVALID_PARAMETER_ERROR':
        yield put(
          identityFaceValidationAction.success({
            isQualityPassed: false,
            isBrightnessPassed: false,
            isSharpnessPassed: false,
            isNotMultiFaces: true,
            userIdentityImageValidationId: -1,
            ...action.payload,
          }),
        );
        break;
      case 'FACE_COMPARISON_ERROR':
      default:
        yield put(identityFaceValidationAction.error(apiResponse));
    }
  }
}

function* identityDocumentExtraction(action: {
  type: typeof userIdentityActions;
  payload: IdentityDocumentExtractionRequestAction;
}) {
  const apiResponse: ICoreApiResponse<
    IdentityDocumentExtractionState,
    IdentityDocumentExtractionStatusResponse
  > = yield call(userIdentityAPI.identityDocumentExtraction, action.payload);

  // TODO: revert SKL-12199 after find root cause of identity extraction time out
  try {
    if (apiResponse?.status === 200) {
      yield put(
        identityDocumentExtractionAction.success({
          ...apiResponse.data!,
          ...action.payload,
        }),
      );
    } else {
      yield put(
        identityDocumentExtractionAction.error({
          isTaxIdMatched: false,
          ...action.payload,
        }),
      );
    }
  } catch (error) {
    console.error('[identityDocumentExtraction]', error);
    identityDocumentExtractionAction.error({
      isTaxIdMatched: false,
      ...action.payload,
    });
  }
}

function* identityDocumentConfirmation(action: {
  type: typeof userIdentityActions;
  payload: IdentityDocumentConfirmationRequestAction;
}) {
  const apiResponse: ICoreApiResponse<
    IdentityDocumentConfirmationState,
    IdentityDocumentConfirmationStatusResponse
  > = yield call(userIdentityAPI.identityDocumentConfirmation, action.payload);

  if (apiResponse?.status === 200) {
    yield put(
      identityDocumentConfirmationAction.success({ ...action.payload, isMatched: !!apiResponse.data?.isMatched }),
    );
  } else {
    yield put(identityDocumentConfirmationAction.error(apiResponse));
  }
}

function* identityFaceComparison(action: {
  type: typeof userIdentityActions;
  payload: IdentityFaceComparisonRequestAction;
}) {
  const apiResponse: ICoreApiResponse<IdentityFaceComparisonState, IdentityFaceComparisonStatusResponse> = yield call(
    userIdentityAPI.identityFaceComparison,
    action.payload,
  );

  if (apiResponse?.status === 200) {
    yield put(
      identityFaceComparisonAction.success({
        ...action.payload,
        isPassed: !!apiResponse.data?.isPassed,
      }),
    );
  } else {
    yield put(identityFaceComparisonAction.error(apiResponse));
  }
}

function* identityDocumentInformation(action: {
  type: typeof userIdentityActions;
  payload: IdentityDocumentInformationRequestAction;
}) {
  const apiResponse: ICoreApiResponse<
    IdentityDocumentInformationState,
    IdentityDocumentInformationStatusResponse
  > = yield call(userIdentityAPI.identityDocumentInformation, action.payload);

  if (apiResponse?.status === 200) {
    yield put(
      identityDocumentInformationAction.success({
        ...action.payload,
        ...apiResponse.data!,
      }),
    );
  } else {
    yield put(identityDocumentInformationAction.error(apiResponse));
  }
}

export default function* userIdentitySaga() {
  yield all([
    takeEvery(userIdentityActions.IDENTITY_DOCUMENT_VALIDATION_REQUEST, identityDocumentValidation),
    takeEvery(userIdentityActions.IDENTITY_FACE_VALIDATION_REQUEST, identityFaceValidation),
    takeEvery(userIdentityActions.IDENTITY_DOCUMENT_EXTRACTION_REQUEST, identityDocumentExtraction),
    takeEvery(userIdentityActions.IDENTITY_DOCUMENT_CONFIRMATION_REQUEST, identityDocumentConfirmation),
    takeEvery(userIdentityActions.IDENTITY_FACE_COMPARISON_REQUEST, identityFaceComparison),
    takeEvery(userIdentityActions.IDENTITY_DOCUMENT_INFORMATION_REQUEST, identityDocumentInformation),
  ]);
}
