import * as Sentry from '@sentry/nextjs';

const ignoreErrors = [
  /(AbortError: The play\(\) request was interrupted by a call to pause\(\).)/g,
  /(AbortError: The play\(\) request was interrupted because video-only background media was paused to save power.)/g,
  /(AbortError: The play\(\) request was interrupted by a new load request.)/g,
  /(NotAllowedError: play\(\) failed because the user didn't interact with the document first.)/g,
  /(NotAllowedError: The request is not allowed by the user agent or the platform in the current context, possibly because the user denied permission.)/g,
  /(NotAllowedError: The play method is not allowed by the user agent or the platform in the current context, possibly because the user denied permission.)/g,
  'AbortError: The operation was aborted.',
  'NotSupportedError: The operation is not supported.',
  'Cannot perform Construct on a detached ArrayBuffer',
];

export const initSentry = () => {
  Sentry.init({
    dsn: process.env.NEXT_PUBLIC_SENTRY_DSN,
    environment: process.env.NEXT_PUBLIC_ENV_NAME,
    enabled: process.env.NEXT_PUBLIC_SENTRY_ENABLE === 'true',
    // Adjust this value in production, or use tracesSampler for greater control
    tracesSampleRate: 1.0,
    // ...
    // Note: if you want to override the automatic release value, do not set a
    // `release` value here - use the environment variable `SENTRY_RELEASE`, so
    // that it will also get attached to your source maps
    beforeSend(event, hint) {
      if (event && event.message && ignoreErrors.includes(event.message)) {
        // Don't send the event to Sentry
        return null;
      }
      return event;
    },
    ignoreErrors,
  });
};
