import { CertificateType } from '@helpers/constants';
import { ICountries, IUserOfficialProfile } from '@interfaces/user';

export interface IUserState {
  networkInfo?: INetworkInfo;
  isPendingCreateOfficialProfile: boolean;
  isCreateOfficialProfileError: boolean;
  createOfficialProfileError?: CreateOfficialProfileStatusCode;

  officialProfile: IUserOfficialProfile | null;
  haveOfficialProfile: boolean;
  haveStudentId: boolean;
  isPendingGetOfficialProfile: boolean;
  isCreateOfficialProfileSuccess: boolean;
  isGetOfficialProfileError: boolean;
  getOfficialProfileError?: string;

  countries: ICountries[];
  isPendingGetCountries: boolean;
  isGetCountriesError: boolean;
  getCountriesError?: string;

  otp?: {
    otpRef: string;
    expiredAt: Date;
  };
  otpType?: string;
  isPendingGetOtp: boolean;
  isGetOtpError: boolean;
  getOtpError?: string;

  myCertificates: GetMyCertificates[];
  getMyCertificatesError?: string;
  myCertificateLoading?: boolean;
}

interface INetworkInfo {
  ip: string;
  datetime: string;
}

export interface ICreateOfficialProfilePayload {
  officialPrefixTh: string;
  officialFirstname: string;
  officialLastname: string;
  officialPrefixEn: string;
  officialFirstnameEn: string;
  officialLastnameEn: string;
  citizenId?: string;
  passportNumber?: string;
  passportCountryId?: number;
  birthday: string;
  phone: string;
  phoneCountryId: number;
  otpCode: string;
  otpRef: string;
  courseIdOrIdName?: string;
}

export interface IGetOtpPayload {
  email: string;
  refId?: number;
  otpType?: string;
}

export interface IGetOfficialProfile {
  officialPrefixTh?: string;
  officialFirstname?: string;
  officialLastname?: string;
  officialPrefixEn?: string;
  officialFirstnameEn?: string;
  officialLastnameEn?: string;
  citizenId?: string;
  birthday?: Date;
  phone?: string;
  phoneCountryId?: number;
  email: string;
  haveOfficialProfile: boolean;
}

export interface ICountry {
  id: number;
  name: string;
  code: string;
  dialCode: string;
}

export type GetMyCertificates = {
  courseId?: number;
  courseName?: string;
  certificateName: string;
  certificateType: CertificateType;
  certificateUrl: string;
  receiveDate: Date;
  degreeName?: string;
  facultyName?: string;
  majorName?: string;
  majorSubject?: string;
  universityId?: number;
  universityName?: string;
};

export enum GetMyCertificatesStatusCode {
  SUCCESS = '000',
}

export enum CreateOfficialProfileStatusCode {
  SUCCESS = '000',
  CITIZEN_ID_IS_DUPLICATED = 'CITIZEN_ID_IS_DUPLICATED',
  PASSPORT_NUMBER_IS_DUPLICATED = 'PASSPORT_NUMBER_IS_DUPLICATED',
  OTP_NOT_FOUND = 'OTP_NOT_FOUND',
}

export enum GetCountriesStatusCode {
  SUCCESS = '000',
}

export enum GetOfficialProfileStatusCode {
  SUCCESS = '000',
}

export type GetMyCertificatesParams = {
  keyword?: string;
  certificateType?: CertificateType;
  universityId?: number;
};
