import config from 'src/config';
import Http from 'src/helpers/httpClient';

const END_POINT = 'v1/product/bundles';

export const BUNDLE_END_POINT = {
  BUNDLES: `${END_POINT}`,
  BUNDLE: (id: string) => `${END_POINT}/${id}`,
  ENROLLMENTS: (id: string) => `${END_POINT}/${id}/enrollment`,
};

export default {
  fetchBundles(path: string) {
    const apiUrl = `${config.API_DOMAIN}/${path}`;
    return Http.get(apiUrl, { isPublic: true });
  },
  fetchBundle(id: string) {
    const apiUrl = `${config.API_DOMAIN}/${BUNDLE_END_POINT.BUNDLE(id)}`;
    return Http.get(apiUrl);
  },
  fetchEnrollmentBundleByIdOrIdName(id: string) {
    const apiUrl = `${config.API_DOMAIN}/${BUNDLE_END_POINT.ENROLLMENTS(id)}`;
    return Http.get(apiUrl);
  },
};
