import config from 'src/config';

import httpClient, { RequestConfig } from '@helpers/httpClient';
import {
  FetchCourseDetailGlancesRequestAction,
  HttpFetchCourseDetailGlancesApiResponse,
  HttpFetchCourseDetailSeoApiResponse,
  FetchCourseDetailRequestAction,
  FetchCourseDetailSeoRequestAction,
  FetchCourseEnrollmentRequestAction,
  HttpFetchCourseDetailApiResponse,
  HttpFetchCourseEnrollmentApiResponse,
  HttpFetchCourseDetailCurriculumApiResponse,
  FetchCourseDetailCurriculumRequestAction,
  FetchCourseReviewListRequestAction,
  HttpFetchCourseReviewListApiResponse,
  FetchCourseReviewSummaryRequestAction,
  HttpFetchCourseReviewSummaryApiResponse,
  CreateOrUpdateCourseReviewRequestAction,
  HttpCreateOrUpdateCourseReviewApiResponse,
  FetchCourseReviewMeRequestAction,
  HttpFetchCourseReviewMeApiResponse,
  FetchCourseDiscussionListRequestAction,
  HttpFetchCourseDiscussionListApiResponse,
  CreateCourseDiscussionRequestAction,
  AddCourseDiscussionReplyRequestAction,
  HttpAddCourseDiscussionReplyApiResponse,
  FetchCurriculumVideoPreviewRequestAction,
  HttpFetchCurriculumVideoPreviewApiResponse,
  FetchCourseSuggestionsRequestAction,
  HttpFetchCourseSuggestionsApiResponse,
} from '@redux/course/type/courseDetailType';
import { FetchCourseSeminarRequestAction, HttpFetchCourseSeminarApiResponse } from '@redux/course/type/seminarType';

const ENDPOINT = '/v2/courses';

export const COURSE_ENDPOINT = {
  CURRICULUM_VIDEO_PREVIEW: (courseIdOrIdName: string, chapterId: number) =>
    `${ENDPOINT}/${courseIdOrIdName}/video/${chapterId}/preview`,
  SEMINAR: (courseIdOrIdName: string) => `${ENDPOINT}/${courseIdOrIdName}/seminars`,
};

const courseApi = {
  fetchCourseDetailSeo: async (
    payload: FetchCourseDetailSeoRequestAction,
  ): Promise<HttpFetchCourseDetailSeoApiResponse> => {
    const apiUrl = new URL(`/v2/courses/${payload.courseIdOrIdName}/seo`, config.API_DOMAIN);
    return httpClient
      .get(apiUrl.toString())
      .then((res) => res.data)
      .catch((error) => error);
  },

  fetchCourseDetail: async (payload: FetchCourseDetailRequestAction): Promise<HttpFetchCourseDetailApiResponse> => {
    const apiUrl = new URL(`/v2/courses/${payload.courseIdOrIdName}`, config.API_DOMAIN);
    return httpClient
      .get(apiUrl.toString())
      .then((res) => res.data)
      .catch((error) => error);
  },

  fetchCourseEnrollment: async (
    payload: FetchCourseEnrollmentRequestAction,
  ): Promise<HttpFetchCourseEnrollmentApiResponse> => {
    const apiUrl = new URL(`/v1/enrollments/courses/${payload.courseId}`, config.API_DOMAIN);
    return httpClient
      .get(apiUrl.toString())
      .then((res) => res.data)
      .catch((error) => error);
  },

  fetchCourseDetailGlances: async (
    payload: FetchCourseDetailGlancesRequestAction,
  ): Promise<HttpFetchCourseDetailGlancesApiResponse> => {
    const apiUrl = new URL(`/v2/courses/${payload.courseIdOrIdName}/glances`, config.API_DOMAIN);
    return httpClient
      .get(apiUrl.toString())
      .then((res) => res.data)
      .catch((error) => error);
  },

  fetchCourseDetailCurriculum: async (
    payload: FetchCourseDetailCurriculumRequestAction,
  ): Promise<HttpFetchCourseDetailCurriculumApiResponse> => {
    const apiUrl = new URL(`/v2/courses/${payload.courseIdOrIdName}/curriculum`, config.API_DOMAIN);
    return httpClient
      .get(apiUrl.toString())
      .then((res) => res.data)
      .catch((error) => error);
  },

  fetchCourseReviewList: async (
    payload: FetchCourseReviewListRequestAction,
  ): Promise<HttpFetchCourseReviewListApiResponse> => {
    const { courseIdOrIdName, ...params } = payload;
    const apiUrl = new URL(`/v1/courses/${courseIdOrIdName}/reviews`, config.API_DOMAIN);
    const requestConfig: RequestConfig = {
      params,
    };
    return httpClient
      .get(apiUrl.toString(), requestConfig)
      .then((res) => res.data)
      .catch((error) => error);
  },

  fetchCourseReviewMe: async (
    payload: FetchCourseReviewMeRequestAction,
  ): Promise<HttpFetchCourseReviewMeApiResponse> => {
    const { courseIdOrIdName, ...params } = payload;
    const apiUrl = new URL(`/v2/courses/${courseIdOrIdName}/review/me`, config.API_DOMAIN);
    const requestConfig: RequestConfig = {
      params,
    };
    return httpClient
      .get(apiUrl.toString(), requestConfig)
      .then((res) => res.data)
      .catch((error) => error);
  },

  fetchCourseReviewSummary: async (
    payload: FetchCourseReviewSummaryRequestAction,
  ): Promise<HttpFetchCourseReviewSummaryApiResponse> => {
    const apiUrl = new URL(`/v1/courses/${payload.courseIdOrIdName}/reviews/summaries`, config.API_DOMAIN);
    return httpClient
      .get(apiUrl.toString())
      .then((res) => res.data)
      .catch((error) => error);
  },

  createOrUpdateCourseReview: async (
    payload: CreateOrUpdateCourseReviewRequestAction,
  ): Promise<HttpCreateOrUpdateCourseReviewApiResponse> => {
    const { courseIdOrIdName, ...body } = payload;
    const apiUrl = new URL(`/v1/courses/${courseIdOrIdName}/reviews`, config.API_DOMAIN);
    return httpClient
      .patch(apiUrl.toString(), body)
      .then((res) => res.data)
      .catch((error) => error);
  },

  fetchCourseDiscussionList: async (
    payload: FetchCourseDiscussionListRequestAction,
  ): Promise<HttpFetchCourseDiscussionListApiResponse> => {
    const { courseIdOrIdName, ...params } = payload;
    const apiUrl = new URL(`/v2/courses/${courseIdOrIdName}/discussions`, config.API_DOMAIN);
    const requestConfig: RequestConfig = {
      params,
    };
    return httpClient
      .get(apiUrl.toString(), requestConfig)
      .then((res) => res.data)
      .catch((error) => error);
  },

  createCourseDiscussion: async (
    payload: CreateCourseDiscussionRequestAction,
  ): Promise<HttpCreateOrUpdateCourseReviewApiResponse> => {
    const { courseIdOrIdName, ...body } = payload;
    const apiUrl = new URL(`/v2/courses/${courseIdOrIdName}/discussions`, config.API_DOMAIN);
    return httpClient
      .post(apiUrl.toString(), body)
      .then((res) => res.data)
      .catch((error) => error);
  },

  addCourseDiscussionReply: async (
    payload: AddCourseDiscussionReplyRequestAction,
  ): Promise<HttpAddCourseDiscussionReplyApiResponse> => {
    const { courseIdOrIdName, discussionId, ...body } = payload;
    const apiUrl = new URL(`/v2/courses/${courseIdOrIdName}/discussions/${discussionId}/replies`, config.API_DOMAIN);
    return httpClient
      .post(apiUrl.toString(), body)
      .then((res) => res.data)
      .catch((error) => error);
  },

  fetchCurriculumVideoPreview: async (
    payload: FetchCurriculumVideoPreviewRequestAction,
  ): Promise<HttpFetchCurriculumVideoPreviewApiResponse> => {
    const apiUrl = new URL(
      COURSE_ENDPOINT.CURRICULUM_VIDEO_PREVIEW(payload.courseIdOrIdName, payload.chapterId),
      config.API_DOMAIN,
    );
    return httpClient
      .get(apiUrl.toString())
      .then((res) => res.data)
      .catch((error) => error);
  },

  fetchCourseSeminar: async (payload: FetchCourseSeminarRequestAction): Promise<HttpFetchCourseSeminarApiResponse> => {
    const apiUrl = new URL(COURSE_ENDPOINT.SEMINAR(payload.courseIdOrIdName), config.API_DOMAIN);
    return httpClient
      .get(apiUrl.toString())
      .then((res) => res.data)
      .catch((error) => error);
  },

  fetchCourseSuggestions: async (
    payload: FetchCourseSuggestionsRequestAction,
  ): Promise<HttpFetchCourseSuggestionsApiResponse> => {
    const apiUrl = new URL(`/v2/courses/${payload.courseIdOrIdName}/suggestions`, config.API_DOMAIN);
    return httpClient
      .get(apiUrl.toString())
      .then((res) => res.data)
      .catch((error) => error);
  },
};

export default courseApi;
