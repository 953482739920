import { NextPage } from 'next';
import { AppProps } from 'next/app';
import Head from 'next/head';
import { useRouter } from 'next/router';
import Script from 'next/script';
import { useEffect, createRef, ReactText } from 'react';
// eslint-disable-next-line prettier/prettier
import type { ReactElement, ReactNode } from 'react';
import { Provider } from 'react-redux';

import Alert from '@mui/material/Alert';
import CssBaseline from '@mui/material/CssBaseline';
import Snackbar from '@mui/material/Snackbar';
import { ThemeProvider } from '@mui/material/styles';
import { appWithTranslation } from 'next-i18next';
import { SnackbarProvider } from 'notistack';
import TagManager from 'react-gtm-module';
import config from 'src/config';
import { initialDataDog } from 'src/datadog.client.config';
import { checkIsAuthSuccess, setRedirectAfterLogin } from 'src/helpers/auth';
import { ROUTES } from 'src/helpers/constants';
import { useAppData, useProfile, useToggleState } from 'src/helpers/use';
import { setUTMCookie } from 'src/helpers/utils';
import createStore from 'src/redux/store';
import theme from 'src/theme';
import { ThemeProvider as StyledThemeProvider } from 'styled-components';
import { SWRConfig } from 'swr';

import '../styles/globals.css';
import ErrorBoundary from '@components/ErrorBoundary';

initialDataDog();

export type NextPageWithLayout<P = object, IP = P> = NextPage<P, IP> & {
  getLayout?: (page: ReactElement) => ReactNode,
  coupon?: boolean;
  scrollToTop?: boolean;
};

type AppPropsWithLayout = AppProps & {
  Component: NextPageWithLayout;
};

const store = createStore();

const MyApp = (props: AppPropsWithLayout) => {
  const { Component, pageProps } = props;

  const getLayout = Component.getLayout ?? ((page) => page);

  const [open, setOpen, setClose] = useToggleState();
  const { profile, isLoading } = useProfile();
  const { appData } = useAppData();
  const notiStackRef = createRef<SnackbarProvider>();
  const router = useRouter();

  useEffect(() => {
    // Remove the server-side injected CSS.
    const jssStyles = document.querySelector('#jss-server-side');
    if (jssStyles) {
      // @ts-expect-error: Object is possibly 'null'.
      jssStyles.parentElement.removeChild(jssStyles);
    }

    // show toast message when from after login or register
    if (checkIsAuthSuccess()) {
      setOpen();
    }
  }, [setOpen]);

  useEffect(() => {
    if (!isLoading && appData !== undefined) {
      TagManager.dataLayer({
        dataLayer: {
          sha256_user_id: profile ? profile.hashId: 'Guest',
          userData: {
            sha256_email_address: profile ? profile.hashEmail : 'Guest',
          },
          role: profile ? profile.role : 'Guest',
          enableCookiePolicy: appData.enableCookiePolicy,
        },
      });
    }
  }, [isLoading, appData, profile]);

  useEffect(() => {
    if (document.URL.includes(ROUTES.MERGE_USER)) {
      return;
    }
    // set cookie for redirect after login
    setRedirectAfterLogin(document.URL);
  }, [router]);

  useEffect(() => {
    setUTMCookie(router.query);
  }, [router.query]);

  const onClickDismiss = (key: ReactText) => {
    if (notiStackRef.current) notiStackRef.current.closeSnackbar(key);
  };

  const composeGTMQueryString = (): string => {
    const gtmSearchParams = new URLSearchParams();
    if (config.GTM_ID) {
      gtmSearchParams.append('id', config.GTM_ID);
    }

    if (config.GTM_AUTH) {
      gtmSearchParams.append('gtm_auth', config.GTM_AUTH);
    }

    if (config.GTM_PREVIEW) {
      gtmSearchParams.append('gtm_preview', config.GTM_PREVIEW);
    }

    gtmSearchParams.append('gtm_cookies_win', 'x');

    return gtmSearchParams.toString()
  }

  return (
    <>
      <Head>
        <title>SkillLane</title>
        <meta name="viewport" content="minimum-scale=1, initial-scale=1, width=device-width" />
      </Head>
      <Script id="gtm" strategy="afterInteractive">
        {`
            (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
            new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
            j=d.createElement(s);j.async=true;
            j.src="${config.GTM_CDN_HOST}/gtm.js?"+i;
            f.parentNode.insertBefore(j,f);})(window,document,'script','dataLayer','${composeGTMQueryString()}');
          `
        }
        
      </Script>

      <ThemeProvider theme={theme}>
        <StyledThemeProvider theme={theme}>
          <SWRConfig
            value={{
              revalidateOnFocus: false,
            }}
          >
            <Provider store={store}>
              {/* CssBaseline kickstart an elegant, consistent, and simple baseline to build upon. */}
              <CssBaseline />
              <Snackbar
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                open={open}
                autoHideDuration={3000}
                onClose={setClose}
              >
                <Alert onClose={setClose} variant="filled" severity="success">
                  เข้าสู่ระบบสำเร็จ
                </Alert>
              </Snackbar>
              <SnackbarProvider
                ref={notiStackRef}
                autoHideDuration={3000}
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                // @ts-expect-error: ignore type for work around send data via message props
                content={(key, { message, severity, isHideCloseIcon }) => (
                  <Alert
                    variant="filled"
                    severity={severity}
                    onClose={!isHideCloseIcon ? () => onClickDismiss(key) : undefined}
                    style={{ textAlign: 'center' }}
                  >
                    {message}
                  </Alert>
                )}
              >
                <ErrorBoundary>
                  {getLayout(<Component {...pageProps} />)}
                </ErrorBoundary>
              </SnackbarProvider>
            </Provider>
          </SWRConfig>
        </StyledThemeProvider>
      </ThemeProvider>

    </>
  );
};

export default appWithTranslation(MyApp);
