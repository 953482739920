import config from 'src/config';
import { CPDEnrollmentForm } from 'src/helpers/constants';
import Http from 'src/helpers/httpClient';
import {
  removeSpaceFromText,
  convertObjectToQueryString,
  dateFormatThai,
  getBundleLicenseFieldNames,
} from 'src/helpers/utils';
import {
  ApplicantTypeEnum,
  CPDLicenseTypeEnum,
  CPDRegulatorTypeEnum,
  ICPDCourse,
  LicenseRenewalTypeEnum,
} from 'src/interfaces';

const END_POINT = 'v1/cpd';

export const CPD_END_POINT: any = {
  courseCPDEnrollmentForCitizen: (id: number, citizenId: string, roundId?: string) =>
    `${END_POINT}/users/${citizenId}/courses/${id}${roundId ? `?roundId=${roundId}` : ''}`,
  bundleCPDEnrollmentForCitizen: (productId: number, citizenId: string, roundId?: string) =>
    `${END_POINT}/users/${citizenId}/bundle/${productId}${roundId ? `?roundId=${roundId}` : ''}`,
  cpdRoundCourse: (id: number | null) => `${END_POINT}/courses/${id}/rounds`,
  COURSES: `${END_POINT}/courses`,
  PRODUCTS: `${END_POINT}/products`,
};

const DEFAULT_SORT_BY = 'published_at';

export type CPDCourseQuery = {
  regulatorType?: CPDRegulatorTypeEnum;
  licenseType?: string;
  applicantType?: string;
  citizenId?: string;
  orderBy?: string;
  sortBy?: string;
  licenseRenewal?: string;
};

const getOICExtendYearType = (
  licenseRenewal: LicenseRenewalTypeEnum,
  isSelectOICLifeLicense: boolean,
  isSelectOICNonLifeLicense: boolean,
): CPDLicenseTypeEnum | undefined => {
  if (licenseRenewal !== LicenseRenewalTypeEnum.FourthPlusRenewal) return undefined;

  if (isSelectOICLifeLicense && isSelectOICNonLifeLicense) return CPDLicenseTypeEnum.BOTH;
  if (isSelectOICLifeLicense) return CPDLicenseTypeEnum.LIFE;
  if (isSelectOICNonLifeLicense) return CPDLicenseTypeEnum.NON_LIFE;

  return undefined;
};

const getOICReductionType = (licenseRenewal: LicenseRenewalTypeEnum, isDeduct: boolean): 'YES' | 'NO' | undefined => {
  if (licenseRenewal !== LicenseRenewalTypeEnum.FourthPlusRenewal) return undefined;

  return isDeduct ? 'YES' : 'NO';
};

const getCpdEnrollmentData = (
  cpdCourse: ICPDCourse,
  data: {
    productSkuId: number;
    tsiLicenseType: string | null;
    tsiLicenseName: string | null;
    tsiLicenseNo: string | null;
    tsiStartDate: string | null;
    tsiEndDate: string | null;
    oicLifeLicenseNo: string | null;
    oicLifeStartDate: string | null;
    oicLifeEndDate: string | null;
    oicNonLifeLicenseNo: string | null;
    oicNonLifeStartDate: string | null;
    oicNonLifeEndDate: string | null;
    roundDate: string | Date | null;
    expiredDate: string | Date | null;
    isSelectOICLifeLicense: boolean;
    isSelectOICNonLifeLicense: boolean;
    roundId: string | null;
  },
) => ({
  productSkuId: cpdCourse.product.product_skus[0].id,
  tsiLicenseName: data.tsiLicenseName,
  tsiLicenseNo: data.tsiLicenseNo,
  tsiLicenseType: data.tsiLicenseType ? data.tsiLicenseType.split('_')[0] : undefined,
  tsiStartDate: removeSpaceFromText(data.tsiStartDate as string),
  tsiEndDate: removeSpaceFromText(data.tsiEndDate as string),
  oicLifeLicenseNo: data.oicLifeLicenseNo,
  oicLifeStartDate: removeSpaceFromText(data.oicLifeStartDate as string),
  oicLifeEndDate: removeSpaceFromText(data.oicLifeEndDate as string),
  oicNonLifeLicenseNo: data.oicNonLifeLicenseNo,
  oicNonLifeStartDate: removeSpaceFromText(data.oicNonLifeStartDate as string),
  oicNonLifeEndDate: removeSpaceFromText(data.oicNonLifeEndDate as string),
  roundId: data.roundId,
  roundDate: dateFormatThai(new Date(data.roundDate as string)),
  expiredDate: dateFormatThai(new Date(data.expiredDate as string)),
  oicExtendYearType: getOICExtendYearType(
    cpdCourse.licenseRenewal,
    data.isSelectOICLifeLicense,
    data.isSelectOICNonLifeLicense,
  ),
  oicReductionType: getOICReductionType(cpdCourse.licenseRenewal, cpdCourse.isDeduct),
  applicantType: cpdCourse.applicantType,
  isDeduct: cpdCourse.isDeduct,
});

export default {
  fetchCPDEnrollment(courseId: number | null, citizenId: string | null, roundId?: string) {
    if (!courseId || !citizenId) return;
    const apiUrl = `${config.API_DOMAIN}/${CPD_END_POINT.courseCPDEnrollmentForCitizen(courseId, citizenId, roundId)}`;
    return Http.get(apiUrl, { isPublic: false });
  },
  fetchCPDBundleEnrollment(productId?: number, citizenId?: string, roundId?: string) {
    if (!productId || !citizenId) return;
    const apiUrl = `${config.API_DOMAIN}/${CPD_END_POINT.bundleCPDEnrollmentForCitizen(productId, citizenId, roundId)}`;
    return Http.get(apiUrl, { isPublic: false });
  },
  fetchCPDRoundCourse(id: number | null) {
    if (!id) return;
    const apiUrl = `${config.API_DOMAIN}/${CPD_END_POINT.cpdRoundCourse(id)}`;
    return Http.get(apiUrl, { isPublic: false });
  },
  createTemporaryCpdEnrollmentFormData(id: number, userId: number, data: CPDEnrollmentForm, productSkuId: number) {
    const getOICExtendYearType = (
      licenseRenewal: LicenseRenewalTypeEnum,
      isSelectOICLifeLicense: boolean,
      isSelectOICNonLifeLicense: boolean,
    ): CPDLicenseTypeEnum | undefined => {
      if (licenseRenewal !== LicenseRenewalTypeEnum.FourthPlusRenewal) return undefined;

      if (isSelectOICLifeLicense && isSelectOICNonLifeLicense) return CPDLicenseTypeEnum.BOTH;
      if (isSelectOICLifeLicense) return CPDLicenseTypeEnum.LIFE;
      if (isSelectOICNonLifeLicense) return CPDLicenseTypeEnum.NON_LIFE;

      return undefined;
    };

    const getOICReductionType = (
      licenseRenewal: LicenseRenewalTypeEnum,
      isDeduct: boolean,
    ): 'YES' | 'NO' | undefined => {
      if (licenseRenewal !== LicenseRenewalTypeEnum.FourthPlusRenewal) return undefined;

      return isDeduct ? 'YES' : 'NO';
    };

    const payload = {
      ...data,
      cpdEnrollments: [
        {
          productSkuId,
          tsiLicenseNo: data.tsiLicenseNo,
          tsiLicenseName: data.tsiLicenseName,
          tsiLicenseType: data.tsiLicenseType ? data.tsiLicenseType.split('_')[0] : undefined,
          tsiStartDate: removeSpaceFromText(data.tsiStartDate as string),
          tsiEndDate: removeSpaceFromText(data.tsiEndDate as string),
          oicLifeLicenseNo: data.oicLifeLicenseNo,
          oicLifeStartDate: removeSpaceFromText(data.oicLifeStartDate as string),
          oicLifeEndDate: removeSpaceFromText(data.oicLifeEndDate as string),
          oicNonLifeLicenseNo: data.oicNonLifeLicenseNo,
          oicNonLifeStartDate: removeSpaceFromText(data.oicNonLifeStartDate as string),
          oicNonLifeEndDate: removeSpaceFromText(data.oicNonLifeEndDate as string),
          roundId: data.roundId,
          roundDate: dateFormatThai(new Date(data.roundDate as string)),
          expiredDate: dateFormatThai(new Date(data.expiredDate as string)),
          oicExtendYearType: getOICExtendYearType(
            data.licenseRenewal,
            data.isSelectOICLifeLicense,
            data.isSelectOICNonLifeLicense,
          ),
          oicReductionType: getOICReductionType(data.licenseRenewal, data.isDeduct),
          applicantType: data.applicantType,
          isDeduct: data.isDeduct,
        },
      ],
    };
    const apiUrl = `${config.API_DOMAIN}/${END_POINT}/users/${userId}/products/${id}/temp`;
    return Http.post(apiUrl, payload);
  },
  createTemporaryBundleCpdEnrollmentFormData(
    id: number,
    userId: number,
    data: CPDEnrollmentForm,
    cpdCourses: ICPDCourse[],
  ) {
    const cpdEnrollments = cpdCourses.map((course) => {
      const isBrokerLifeCourse =
        course.applicantType === ApplicantTypeEnum.BROKER && course.licenseTypes[0] === CPDLicenseTypeEnum.LIFE;
      const isBrokerNonLifeCourse =
        course.applicantType === ApplicantTypeEnum.BROKER && course.licenseTypes[0] === CPDLicenseTypeEnum.NON_LIFE;
      const isAgentLifeCourse =
        course.applicantType === ApplicantTypeEnum.AGENT && course.licenseTypes[0] === CPDLicenseTypeEnum.LIFE;
      const isAgentNonLifeCourse =
        course.applicantType === ApplicantTypeEnum.AGENT && course.licenseTypes[0] === CPDLicenseTypeEnum.NON_LIFE;
      const isBrokerOICFourthPlusCourse =
        course.applicantType === ApplicantTypeEnum.BROKER &&
        course.licenseRenewal === LicenseRenewalTypeEnum.FourthPlusRenewal;
      const isAgentOICFourthPlusCourse =
        course.applicantType === ApplicantTypeEnum.AGENT &&
        course.licenseRenewal === LicenseRenewalTypeEnum.FourthPlusRenewal;

      let tsiLicenseType: string | null = null;
      let tsiLicenseName: string | null = null;
      let tsiLicenseNo: string | null = null;
      let tsiStartDate: string | null = null;
      let tsiEndDate: string | null = null;

      let oicLifeLicenseNo: string | null = null;
      let oicLifeStartDate: string | null = null;
      let oicLifeEndDate: string | null = null;
      let isSelectOICLifeLicense: boolean = false;
      let oicNonLifeLicenseNo: string | null = null;
      let oicNonLifeStartDate: string | null = null;
      let oicNonLifeEndDate: string | null = null;
      let isSelectOICNonLifeLicense: boolean = false;

      let roundId = null;
      let roundDate = null;
      let expiredDate = null;

      const selectedCourseRound = data.courses.find((item) => Number(item.courseId) === course.id);

      if (selectedCourseRound) {
        roundId = selectedCourseRound.roundId;
        roundDate = selectedCourseRound.roundDate;
        expiredDate = selectedCourseRound.expiredDate;
      }

      // TODO : will refactor
      if (course.regulator === CPDRegulatorTypeEnum.TSI) {
        tsiLicenseType = data.tsiLicenseType;
        tsiLicenseName = data.tsiLicenseName;
        tsiLicenseNo = data.tsiLicenseNo;
        tsiStartDate = data.tsiStartDate;
        tsiEndDate = data.tsiEndDate;
      } else if (isBrokerOICFourthPlusCourse) {
        if (data.isSelectBrokerOICLifeLicense) {
          oicLifeLicenseNo = data.oicBrokerLifeLicenseNo;
          oicLifeStartDate = data.oicBrokerLifeStartDate;
          oicLifeEndDate = data.oicBrokerLifeEndDate;
          isSelectOICLifeLicense = data.isSelectBrokerOICLifeLicense;
        }

        if (data.isSelectBrokerOICNonLifeLicense) {
          oicNonLifeLicenseNo = data.oicBrokerNonLifeLicenseNo;
          oicNonLifeStartDate = data.oicBrokerNonLifeStartDate;
          oicNonLifeEndDate = data.oicBrokerNonLifeEndDate;
          isSelectOICNonLifeLicense = data.isSelectBrokerOICNonLifeLicense;
        }
      } else if (isAgentOICFourthPlusCourse) {
        if (data.isSelectAgentOICLifeLicense) {
          oicLifeLicenseNo = data.oicAgentLifeLicenseNo;
          oicLifeStartDate = data.oicAgentLifeStartDate;
          oicLifeEndDate = data.oicAgentLifeEndDate;
          isSelectOICLifeLicense = data.isSelectAgentOICLifeLicense;
        }

        if (data.isSelectAgentOICNonLifeLicense) {
          oicNonLifeLicenseNo = data.oicAgentNonLifeLicenseNo;
          oicNonLifeStartDate = data.oicAgentNonLifeStartDate;
          oicNonLifeEndDate = data.oicAgentNonLifeEndDate;
          isSelectOICNonLifeLicense = data.isSelectAgentOICNonLifeLicense;
        }
      } else if (isBrokerLifeCourse) {
        oicLifeLicenseNo = data.oicBrokerLifeLicenseNo;
        oicLifeStartDate = data.oicBrokerLifeStartDate;
        oicLifeEndDate = data.oicBrokerLifeEndDate;
        isSelectOICLifeLicense = data.isSelectBrokerOICLifeLicense;
      } else if (isBrokerNonLifeCourse) {
        oicNonLifeLicenseNo = data.oicBrokerNonLifeLicenseNo;
        oicNonLifeStartDate = data.oicBrokerNonLifeStartDate;
        oicNonLifeEndDate = data.oicBrokerNonLifeEndDate;
        isSelectOICNonLifeLicense = data.isSelectBrokerOICNonLifeLicense;
      } else if (isAgentLifeCourse) {
        oicLifeLicenseNo = data.oicAgentLifeLicenseNo;
        oicLifeStartDate = data.oicAgentLifeStartDate;
        oicLifeEndDate = data.oicAgentLifeEndDate;
        isSelectOICLifeLicense = data.isSelectAgentOICLifeLicense;
      } else if (isAgentNonLifeCourse) {
        oicNonLifeLicenseNo = data.oicAgentNonLifeLicenseNo;
        oicNonLifeStartDate = data.oicAgentNonLifeStartDate;
        oicNonLifeEndDate = data.oicAgentNonLifeEndDate;
        isSelectOICNonLifeLicense = data.isSelectAgentOICNonLifeLicense;
      }

      return getCpdEnrollmentData(course, {
        productSkuId: course.product.product_skus[0].id,
        tsiLicenseType,
        tsiLicenseName,
        tsiLicenseNo,
        tsiStartDate,
        tsiEndDate,
        oicLifeLicenseNo,
        oicLifeStartDate,
        oicLifeEndDate,
        oicNonLifeLicenseNo,
        oicNonLifeStartDate,
        oicNonLifeEndDate,
        roundDate,
        expiredDate,
        roundId,
        isSelectOICLifeLicense,
        isSelectOICNonLifeLicense,
      });
    });

    const payload = {
      firstName: data.firstName,
      lastName: data.lastName,
      citizenId: data.citizenId,
      mobile: data.mobile,
      prefix: data.prefix,
      inputPrefix: data.inputPrefix,
      email: data.email,
      cpdEnrollments,
    };

    const apiUrl = `${config.API_DOMAIN}/${END_POINT}/users/${userId}/products/${id}/temp`;
    return Http.post(apiUrl, payload);
  },
  fetchTemporaryCpdEnrollmentFormData(id: number, userId: number) {
    const apiUrl = `${config.API_DOMAIN}/${END_POINT}/users/${userId}/products/${id}/temp`;
    return Http.get(apiUrl, { isPublic: false });
  },
  fetchCPDCourses(query?: CPDCourseQuery) {
    const apiUrl = `${config.API_DOMAIN}/${CPD_END_POINT.COURSES}?${
      query ? convertObjectToQueryString({ ...query, sortBy: query.sortBy || DEFAULT_SORT_BY }) : ''
    }`;
    return Http.get(apiUrl, { isPublic: true });
  },
  fetchCPDProducts(query?: CPDCourseQuery) {
    const apiUrl = `${config.API_DOMAIN}/${CPD_END_POINT.PRODUCTS}?${query ? convertObjectToQueryString(query!) : ''}`;
    return Http.get(apiUrl, { isPublic: true });
  },
};
