import { CoreApiResponse } from '@common/type/CoreApiResponseTypes';
import { isApiResponseSuccess } from '@common/utility/http';
import { all, call, put, takeEvery } from 'redux-saga/effects';

import { ICoreApiResponse } from '@interfaces/common';
import manageUserIdentityActions from '@redux/manageUserIdentity/action';
import manageUserIdentityAPI from '@redux/manageUserIdentity/api';
import {
  DeleteUserIdentityDocumentRequestAction,
  DeleteUserIdentityDocumentStatusResponse,
  DeleteUserIdentityDocumentSuccessAction,
  DeleteUserIdentityFaceRequestAction,
  DeleteUserIdentityFaceStatusResponse,
  DeleteUserIdentityFaceSuccessAction,
  ManageIdentityFaceUploadRequestAction,
  ManageIdentityFaceUploadStatusResponse,
  ManageIdentityFaceUploadSuccessAction,
  ManageIdentityImageValidationRequestAction,
  ManageIdentityImageValidationStatusResponse,
  ManageIdentityImageValidationSuccessAction,
  ManageIdentityDocumentConfirmationRequestAction,
  ManageIdentityDocumentConfirmationState,
  ManageIdentityDocumentConfirmationStatusResponse,
  ManageIdentityDocumentExtractionRequestAction,
  ManageIdentityDocumentExtractionState,
  ManageIdentityDocumentExtractionStatusResponse,
  ManageUserIdentityRequestAction,
  ManageUserIdentityStatusResponse,
  ManageUserIdentitySuccessAction,
  UpdateUserIdentityDocumentRequestAction,
  UpdateUserIdentityDocumentStatusResponse,
  UpdateUserIdentityDocumentSuccessAction,
} from '@redux/manageUserIdentity/type';

const {
  fetchUserIdentityInfoAction,
  deleteUserIdentityFaceAction,
  deleteUserIdentityDocumentAction,
  updateUserIdentityDocumentInformationAction,
  identityFaceValidationAction,
  identityFaceUploadAction,
  identityDocumentValidationAction,
  identityDocumentExtractionAction,
  identityDocumentConfirmationAction,
} = manageUserIdentityActions;

function* adminGetUserIdentityInformation(action: {
  type: typeof manageUserIdentityActions;
  payload: ManageUserIdentityRequestAction;
}) {
  const apiResponse: ICoreApiResponse<ManageUserIdentitySuccessAction, ManageUserIdentityStatusResponse> = yield call(
    manageUserIdentityAPI.adminGetUserIdentityInformation,
    action.payload,
  );

  if (apiResponse?.status === 200) {
    yield put(
      fetchUserIdentityInfoAction.success({
        ...apiResponse.data!,
      }),
    );
  } else {
    yield put(fetchUserIdentityInfoAction.error(apiResponse));
  }
}

function* adminDeleteUserIdentityFace(action: {
  type: typeof manageUserIdentityActions;
  payload: DeleteUserIdentityFaceRequestAction;
}) {
  const apiResponse: ICoreApiResponse<
    DeleteUserIdentityFaceSuccessAction,
    DeleteUserIdentityFaceStatusResponse
  > = yield call(manageUserIdentityAPI.adminDeleteUserIdentityFace, action.payload);

  if (apiResponse?.status === '000') {
    yield put(deleteUserIdentityFaceAction.success({}));
  } else {
    yield put(deleteUserIdentityFaceAction.error(apiResponse));
  }
}

function* adminDeleteUserIdentityDocument(action: {
  type: typeof manageUserIdentityActions;
  payload: DeleteUserIdentityDocumentRequestAction;
}) {
  const apiResponse: ICoreApiResponse<
    DeleteUserIdentityDocumentSuccessAction,
    DeleteUserIdentityDocumentStatusResponse
  > = yield call(manageUserIdentityAPI.adminDeleteUserIdentityDocument, action.payload);

  if (apiResponse?.status === 200) {
    yield put(deleteUserIdentityDocumentAction.success({}));
  } else {
    yield put(deleteUserIdentityDocumentAction.error(apiResponse));
  }
}

function* adminUpdateUserIdentityDocument(action: {
  type: typeof manageUserIdentityActions;
  payload: UpdateUserIdentityDocumentRequestAction;
}) {
  const apiResponse: ICoreApiResponse<
    UpdateUserIdentityDocumentSuccessAction,
    UpdateUserIdentityDocumentStatusResponse
  > = yield call(manageUserIdentityAPI.adminUpdateIdentityDocumentInformation, action.payload);

  if (apiResponse?.status === 200) {
    yield put(updateUserIdentityDocumentInformationAction.success(apiResponse.data!));
  } else {
    yield put(updateUserIdentityDocumentInformationAction.error(apiResponse));
  }
}

function* adminValidateUserIdentityFaceImage(action: {
  type: typeof manageUserIdentityActions;
  payload: ManageIdentityImageValidationRequestAction;
}) {
  const apiResponse: CoreApiResponse<
    ManageIdentityImageValidationSuccessAction,
    ManageIdentityImageValidationStatusResponse
  > = yield call(manageUserIdentityAPI.adminIdentityImageValidation, action.payload);
  if (isApiResponseSuccess(apiResponse.status)) {
    if (apiResponse.data?.isQualityPassed) {
      yield put(
        identityFaceUploadAction.request({
          userIdentityImageValidationId: apiResponse.data?.userIdentityImageValidationId,
          userId: action.payload.userId,
        }),
      );
    }

    yield put(identityFaceValidationAction.success(apiResponse.data!));
  } else {
    switch (apiResponse?.status) {
      case 'USER_FACE_COMPARISON_INVALID_PARAMETER_ERROR':
        yield put(
          identityFaceValidationAction.success({
            isQualityPassed: false,
            isBrightnessPassed: false,
            isSharpnessPassed: false,
            isNotMultiFaces: true,
            userIdentityImageValidationId: -1,
            ...action.payload,
          }),
        );

        break;
      case 'FACE_COMPARISON_ERROR':
      default:
        yield put(identityFaceValidationAction.error(apiResponse));
    }
  }
}

function* adminUserIdentityDocumentValidation(action: {
  type: typeof manageUserIdentityActions;
  payload: ManageIdentityImageValidationRequestAction;
}) {
  yield put(identityDocumentExtractionAction.reset());

  const apiResponse: CoreApiResponse<
    ManageIdentityImageValidationSuccessAction,
    ManageIdentityImageValidationStatusResponse
  > = yield call(manageUserIdentityAPI.adminIdentityImageValidation, action.payload);

  if (isApiResponseSuccess(apiResponse.status)) {
    yield put(
      identityDocumentExtractionAction.request({
        userIdentityImageValidationId: apiResponse.data?.userIdentityImageValidationId,
      }),
    );
    yield put(
      identityDocumentValidationAction.success({
        ...apiResponse.data!,
        ...action.payload,
      }),
    );
  } else {
    switch (apiResponse?.status) {
      case 'USER_FACE_COMPARISON_INVALID_PARAMETER_ERROR':
        yield put(
          identityDocumentValidationAction.success({
            isQualityPassed: false,
            isBrightnessPassed: false,
            isSharpnessPassed: false,
            isNotMultiFaces: true,
            userIdentityImageValidationId: -1,
            ...action.payload,
          }),
        );
        yield put(
          identityDocumentExtractionAction.success({
            isTaxIdMatched: false,
            isCitizenIdDetected: false,
            firstNameTH: '',
            lastNameTH: '',
            firstNameEN: '',
            lastNameEN: '',
            citizenId: '',
            birthDate: undefined,
            detectResult: '',
            userIdentityImageValidationId: -1,
          }),
        );
        break;
      case 'FACE_COMPARISON_ERROR':
      default:
        yield put(identityDocumentValidationAction.error(apiResponse));
        yield put(identityDocumentExtractionAction.error(apiResponse));
    }
  }
}

function* adminUploadUserIdentityFaceImage(action: {
  type: typeof manageUserIdentityActions;
  payload: ManageIdentityFaceUploadRequestAction;
}) {
  const apiResponse: ICoreApiResponse<
    ManageIdentityFaceUploadSuccessAction,
    ManageIdentityFaceUploadStatusResponse
  > = yield call(manageUserIdentityAPI.adminUploadUserIdentityFaceImage, action.payload);

  if (apiResponse?.status === '000') {
    yield put(identityFaceUploadAction.success(apiResponse.data!));
  } else {
    yield put(identityFaceUploadAction.error(apiResponse));
  }
}
function* adminIdentityDocumentExtraction(action: {
  type: typeof manageUserIdentityActions;
  payload: ManageIdentityDocumentExtractionRequestAction;
}) {
  const apiResponse: CoreApiResponse<
    ManageIdentityDocumentExtractionState,
    ManageIdentityDocumentExtractionStatusResponse
  > = yield call(manageUserIdentityAPI.adminIdentityDocumentExtraction, action.payload);

  if (isApiResponseSuccess(apiResponse.status)) {
    yield put(
      identityDocumentExtractionAction.success({
        ...apiResponse.data!,
        ...action.payload,
      }),
    );
  } else {
    yield put(
      identityDocumentExtractionAction.error({
        isTaxIdMatched: false,
        ...action.payload,
      }),
    );
  }
}

function* adminIdentityDocumentConfirmation(action: {
  type: typeof manageUserIdentityActions;
  payload: ManageIdentityDocumentConfirmationRequestAction;
}) {
  const apiResponse: CoreApiResponse<
    ManageIdentityDocumentConfirmationState,
    ManageIdentityDocumentConfirmationStatusResponse
  > = yield call(manageUserIdentityAPI.adminIdentityDocumentConfirmation, action.payload);

  if (isApiResponseSuccess(apiResponse.status)) {
    yield put(identityDocumentConfirmationAction.success({ ...apiResponse.data! }));
  } else {
    yield put(identityDocumentConfirmationAction.error(apiResponse));
  }
}

export default function* manageUserIdentitySaga() {
  yield all([takeEvery(manageUserIdentityActions.FETCH_USER_IDENTITY_INFO_REQUEST, adminGetUserIdentityInformation)]);
  yield all([takeEvery(manageUserIdentityActions.DELETE_USER_IDENTITY_FACE_REQUEST, adminDeleteUserIdentityFace)]);
  yield all([
    takeEvery(manageUserIdentityActions.MANAGE_IDENTITY_FACE_VALIDATION_REQUEST, adminValidateUserIdentityFaceImage),
  ]);
  yield all([
    takeEvery(manageUserIdentityActions.MANAGE_IDENTITY_FACE_UPLOAD_REQUEST, adminUploadUserIdentityFaceImage),
  ]);
  yield all([
    takeEvery(manageUserIdentityActions.DELETE_USER_IDENTITY_DOCUMENT_REQUEST, adminDeleteUserIdentityDocument),
  ]);
  yield all([
    takeEvery(
      manageUserIdentityActions.UPDATE_USER_IDENTITY_DOCUMENT_INFORMATION_REQUEST,
      adminUpdateUserIdentityDocument,
    ),
  ]);
  yield all([
    takeEvery(manageUserIdentityActions.IDENTITY_DOCUMENT_VALIDATION_REQUEST, adminUserIdentityDocumentValidation),
  ]);
  yield all([
    takeEvery(manageUserIdentityActions.IDENTITY_DOCUMENT_EXTRACTION_REQUEST, adminIdentityDocumentExtraction),
  ]);
  yield all([
    takeEvery(manageUserIdentityActions.IDENTITY_DOCUMENT_CONFIRMATION_REQUEST, adminIdentityDocumentConfirmation),
  ]);
}
