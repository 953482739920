import {
  CloseInteractionAnswerPayload,
  StartInteractionAnswerPayload,
  SubmitInteractionAnswerPayload,
} from '@interfaces/learning';

const actions = {
  CLEAN_INTERACTIVE_CONTENT_SUBMISSION: 'interactiveContent/CLEAN_INTERACTIVE_CONTENT_SUBMISSION',
  INTERACTIVE_CONTENT_START_REQUEST: 'interactiveContent/INTERACTIVE_CONTENT_START_REQUEST',
  INTERACTIVE_CONTENT_START_SUCCESS: 'interactiveContent/INTERACTIVE_CONTENT_START_SUCCESS',
  INTERACTIVE_CONTENT_START_ERROR: 'interactiveContent/INTERACTIVE_CONTENT_START_ERROR',
  INTERACTIVE_CONTENT_SEND_ANSWER_REQUEST: 'interactiveContent/INTERACTIVE_CONTENT_SEND_ANSWER_REQUEST',
  INTERACTIVE_CONTENT_SEND_ANSWER_SUCCESS: 'interactiveContent/INTERACTIVE_CONTENT_SEND_ANSWER_SUCCESS',
  INTERACTIVE_CONTENT_SEND_ANSWER_ERROR: 'interactiveContent/INTERACTIVE_CONTENT_SEND_ANSWER_ERROR',
  INTERACTIVE_CONTENT_SUBMIT_REQUEST: 'interactiveContent/INTERACTIVE_CONTENT_SUBMIT_REQUEST',
  INTERACTIVE_CONTENT_SUBMIT_SUCCESS: 'interactiveContent/INTERACTIVE_CONTENT_SUBMIT_SUCCESS',
  INTERACTIVE_CONTENT_SUBMIT_ERROR: 'interactiveContent/INTERACTIVE_CONTENT_SUBMIT_ERROR',
  INTERACTIVE_CONTENT_CLOSE_REQUEST: 'interactiveContent/INTERACTIVE_CONTENT_CLOSE_REQUEST',
  INTERACTIVE_CONTENT_CLOSE_SUCCESS: 'interactiveContent/INTERACTIVE_CONTENT_CLOSE_SUCCESS',
  INTERACTIVE_CONTENT_CLOSE_ERROR: 'interactiveContent/INTERACTIVE_CONTENT_CLOSE_ERROR',

  clean_interactive_content_submission: () => ({
    type: actions.CLEAN_INTERACTIVE_CONTENT_SUBMISSION,
  }),
  start_interactive_content: (interactiveContentId: number, payload: StartInteractionAnswerPayload) => ({
    type: actions.INTERACTIVE_CONTENT_START_REQUEST,
    interactiveContentId,
    payload,
  }),
  submit_interactive_content: (
    interactiveContentId: number,
    interactiveContentSubmissionId: number,
    payload: SubmitInteractionAnswerPayload,
  ) => ({
    type: actions.INTERACTIVE_CONTENT_SUBMIT_REQUEST,
    interactiveContentId,
    interactiveContentSubmissionId,
    payload,
  }),
  send_answer_interactive_content: (interactiveContentId: number, payload: SubmitInteractionAnswerPayload) => ({
    type: actions.INTERACTIVE_CONTENT_SEND_ANSWER_REQUEST,
    interactiveContentId,
    payload,
  }),
  close_interactive_content: (
    interactiveContentId: number,
    interactiveContentSubmissionId: number,
    payload: CloseInteractionAnswerPayload,
  ) => ({
    type: actions.INTERACTIVE_CONTENT_CLOSE_REQUEST,
    interactiveContentId,
    interactiveContentSubmissionId,
    payload,
  }),
};

export default actions;
