/* eslint-disable no-param-reassign */
import { Record } from 'immutable';

import { UserIdentityReduxState } from './userIdentity/type';

import { DefaultEventState, FailedStateOptions, LoadingStateOptions } from '@redux/type';

export const EventStateFieldNameEnum = {
  isLoading: 'isLoading',
  isSuccess: 'isSuccess',
  isError: 'isError',
  error: 'error',
  data: 'data',
};

export const defaultEventState = {
  [EventStateFieldNameEnum.isLoading]: false,
  [EventStateFieldNameEnum.isSuccess]: false,
  [EventStateFieldNameEnum.isError]: false,
  [EventStateFieldNameEnum.data]: null,
  [EventStateFieldNameEnum.error]: null,
};

export const defaultInitialState = <TData, TError>(initial = null) =>
  ({
    ...defaultEventState,
    data: initial,
    error: null,
  } as DefaultEventState<TData, TError>);

// eslint-disable-next-line max-len
export const loadingState = <T extends Object>(
  state: Record<T> & Readonly<T>,
  key: keyof T,
  options: LoadingStateOptions = {},
) => {
  state = state
    .setIn([key, EventStateFieldNameEnum.isLoading], true)
    .setIn([key, EventStateFieldNameEnum.isSuccess], false)
    .setIn([key, EventStateFieldNameEnum.isError], false)
    .setIn([key, EventStateFieldNameEnum.error], null);

  if (options.shouldResetData) {
    state = state.setIn([key, EventStateFieldNameEnum.data], null);
  }

  return state;
};

export const successState = <T extends Object>(state: Record<T> & Readonly<T>, key: keyof T) =>
  state
    .setIn([key, EventStateFieldNameEnum.isLoading], false)
    .setIn([key, EventStateFieldNameEnum.isSuccess], true)
    .setIn([key, EventStateFieldNameEnum.isError], false)
    .setIn([key, EventStateFieldNameEnum.error], null);

// eslint-disable-next-line max-len
export const successStateWithData = <T extends Object>(state: Record<T> & Readonly<T>, key: keyof T, data = null) =>
  successState(state, key).setIn([key, EventStateFieldNameEnum.data], data);

export const failedState = <T extends Object>(
  state: Record<T> & Readonly<T>,
  key: keyof T,
  error = null,
  options: FailedStateOptions = { shouldResetData: true },
) => {
  state = state
    .setIn([key, EventStateFieldNameEnum.isLoading], false)
    .setIn([key, EventStateFieldNameEnum.isSuccess], false)
    .setIn([key, EventStateFieldNameEnum.isError], true)
    .setIn([key, EventStateFieldNameEnum.error], error);

  if (options.shouldResetData) {
    state = state.setIn([key, EventStateFieldNameEnum.data], null);
  }
  return state;
};

// eslint-disable-next-line max-len
export const resetState = <T extends Object>(state: Record<T> & Readonly<T>, key: keyof T, initial = null) =>
  state.set(key, defaultInitialState(initial) as any);
