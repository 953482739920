import { isApiResponseSuccess } from '@common/utility/http';
import { all, call, put, takeEvery } from 'redux-saga/effects';

import bundleActions from '@redux/bundle/actions';
import bundleApi from '@redux/bundle/api';
import {
  FetchBundleCoursesRequestAction,
  FetchBundleDetailRequestAction,
  FetchBundleEnrollmentRequestAction,
  HttpFetchBundleCoursesApiResponse,
  HttpFetchBundleDetailApiResponse,
  HttpFetchBundleEnrollmentApiResponse,
} from '@redux/bundle/type/BundleDetailType';

const { fetchBundleDetailAction, fetchBundleCoursesAction, fetchBundleEnrollmentAction } = bundleActions;

function* fetchBundleDetailSaga(action: { type: typeof bundleActions; payload: FetchBundleDetailRequestAction }) {
  const apiResponse: HttpFetchBundleDetailApiResponse = yield call(bundleApi.fetchBundleDetail, action.payload);
  if (isApiResponseSuccess(apiResponse.status)) {
    yield put(fetchBundleDetailAction.success(apiResponse.data));
  } else {
    yield put(fetchBundleDetailAction.error(apiResponse));
  }
}

function* fetchBundleCoursesSaga(action: { type: typeof bundleActions; payload: FetchBundleCoursesRequestAction }) {
  const apiResponse: HttpFetchBundleCoursesApiResponse = yield call(bundleApi.fetchBundleCourses, action.payload);
  if (isApiResponseSuccess(apiResponse.status)) {
    yield put(fetchBundleCoursesAction.success(apiResponse.data));
  } else {
    yield put(fetchBundleCoursesAction.error(apiResponse));
  }
}

function* fetchBundleEnrollmentSaga(action: {
  type: typeof bundleActions;
  payload: FetchBundleEnrollmentRequestAction;
}) {
  const apiResponse: HttpFetchBundleEnrollmentApiResponse = yield call(bundleApi.fetchBundleEnrollment, action.payload);
  if (isApiResponseSuccess(apiResponse.status)) {
    yield put(fetchBundleEnrollmentAction.success(apiResponse.data));
  } else {
    yield put(fetchBundleEnrollmentAction.error(apiResponse));
  }
}

export default function* bundleSaga() {
  yield all([takeEvery(bundleActions.FETCH_BUNDLE_DETAIL_REQUEST, fetchBundleDetailSaga)]);
  yield all([takeEvery(bundleActions.FETCH_BUNDLE_COURSES_REQUEST, fetchBundleCoursesSaga)]);
  yield all([takeEvery(bundleActions.FETCH_BUNDLE_ENROLLMENT_REQUEST, fetchBundleEnrollmentSaga)]);
}
