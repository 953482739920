import createSagaMiddleware, { END, SagaMiddleware } from 'redux-saga';

import { createStore, applyMiddleware } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import thunk, { ThunkMiddleware } from 'redux-thunk';

import rootReducer from './reducer';
import rootSaga from './saga';

// import clientMiddleware from './middlewares/client';

const sagaMiddleware = createSagaMiddleware();

const bindMiddleware = (middleware: Array<ThunkMiddleware | SagaMiddleware<object>>) => {
  if (process.env.NODE_ENV !== 'production') {
    return composeWithDevTools(applyMiddleware(...middleware));
  }
  return applyMiddleware(...middleware);
};

function configureStore(initialState = {}) {
  const store: any = createStore(
    rootReducer,
    initialState,
    bindMiddleware([thunk, sagaMiddleware]),
    // bindMiddleware([clientMiddleware, thunk, sagaMiddleware]),
  );

  store.runSaga = () => {
    // Avoid running twice
    store.sagaTask = sagaMiddleware.run(rootSaga);
  };

  store.stopSaga = async () => {
    // Avoid running twice
    if (!store.sagaTask) return;
    store.dispatch(END);
    await store.sagaTask.done;
    store.sagaTask = null;
  };

  store.execSagaTasks = async (isServer: boolean, tasks: Function) => {
    // run saga
    store.runSaga();
    // dispatch saga tasks
    tasks(store.dispatch);
    // Stop running and wait for the tasks to be done
    await store.stopSaga();
    // Re-run on client side
    if (!isServer) {
      store.runSaga();
    }
  };

  // Initial run
  store.runSaga();

  return store;
}

export default configureStore;
