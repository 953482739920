import { CoreApiResponse } from '@common/type/CoreApiResponseTypes';
import config from 'src/config';
import httpClient from 'src/helpers/httpClient';
import { EditedUniversityEvaluationResult, EvaluationResultQuery } from 'src/types/enrollment';

import {
  CreateUserEnrollFreeRequestAction,
  CreateUserEnrollFreeState,
  CreateUserEnrollFreeStatusResponse,
} from './type';

const querySearchEvaluationResult = (query: EvaluationResultQuery) => {
  const { page, limit, email, studentId, courseName } = query;
  let queryString = '';

  if (email) {
    queryString += `email=${encodeURIComponent(email)}&`;
  }

  if (studentId) {
    queryString += `studentId=${studentId}&`;
  }

  if (courseName) {
    queryString += `courseName=${courseName}&`;
  }

  if (page) {
    queryString += `page=${page}&`;
  }

  if (limit) {
    queryString += `limit=${limit}`;
  }

  return queryString;
};

const api = {
  fetchUniversityEvaluationResultList(query: EvaluationResultQuery) {
    const apiUrl = `${config.API_DOMAIN}/v1/university/evaluation-results?${querySearchEvaluationResult(query)}`;
    return httpClient.get(apiUrl);
  },
  fetchUniversityEvaluationResultDetail(id: number) {
    const apiUrl = `${config.API_DOMAIN}/v1/university/evaluation-results/${id}`;
    return httpClient.get(apiUrl);
  },
  fetchCourseGrades(id: number) {
    const apiUrl = `${config.API_DOMAIN}/v1/courses/${id}/grades`;
    return httpClient.get(apiUrl);
  },
  updateUniversityEvaluationResult(data: EditedUniversityEvaluationResult) {
    const apiUrl = `${config.API_DOMAIN}/v1/university/evaluation-results/${data.id}`;
    return httpClient.put(apiUrl, data);
  },
  createUserEnrollFree: async (
    payload: CreateUserEnrollFreeRequestAction,
  ): Promise<CoreApiResponse<CreateUserEnrollFreeState, CreateUserEnrollFreeStatusResponse>> => {
    const apiUrl = new URL('/v1/enrollments/free', config.API_DOMAIN);
    return httpClient
      .post(apiUrl.toString(), {
        course_id: payload.courseId,
      })
      .then((res) => ({ ...res.data, status: '000' }))
      .catch((error) => error);
  },
};

export default api;
