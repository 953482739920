import axios, { AxiosRequestConfig } from 'axios';

import { getToken } from './auth';

export interface RequestConfig extends AxiosRequestConfig {
  isPublic?: boolean;
}

/** @deprecated use ResponseDataV2 instaed */
export type ResponseData = {
  data: any;
  status: number;
};

export type ResponseDataV2<IData> = {
  data?: IData;
  status: string | number;
};

axios.interceptors.request.use((config: RequestConfig) => {
  const { isPublic = false, ...newConfig } = config;
  if (!isPublic) {
    newConfig.headers.Authorization = `Bearer ${getToken()}`;
  }
  return newConfig;
});

const catchCallBack = (error: any) => {
  if (error?.response?.status === 500) {
    return {
      httpStatus: 500,
      status: 500,
      message: 'Server Error',
      error,
    };
  }
  const { message, domain, data } = error.response.data;
  return {
    httpStatus: error.response.status,
    status: error.response.status,
    message,
    domain,
    data,
  };
};

const httpClient = {
  get: async (url: string, config?: RequestConfig) => {
    try {
      const res = await axios.get(url, config);
      return res;
    } catch (err) {
      return catchCallBack(err);
    }
  },
  post: (url: string, data: any, config?: RequestConfig) =>
    axios
      .post(url, data, config)
      .then((res) => res)
      .catch((err) => catchCallBack(err)),
  put: (url: string, data: any, config?: RequestConfig) =>
    axios
      .put(url, data, config)
      .then((res) => res)
      .catch((err) => catchCallBack(err)),
  patch: (url: string, data: any, config?: RequestConfig) =>
    axios
      .patch(url, data, config)
      .then((res) => res)
      .catch((err) => catchCallBack(err)),
  delete: (url: string, config?: RequestConfig) =>
    axios
      .delete(url, config)
      .then((res) => res)
      .catch((err) => catchCallBack(err)),
};

export default httpClient;
