import { Record } from 'immutable';

import bundleActions from '@redux/bundle/actions';
import { BundleDetailReduxState } from '@redux/bundle/type/state';
import { defaultInitialState, failedState, loadingState, resetState, successStateWithData } from '@redux/state';

const initManageUserState = new (Record<BundleDetailReduxState>({
  bundleDetailSeoState: defaultInitialState(),
  bundleDetailState: defaultInitialState(),
  bundleCoursesState: defaultInitialState(),
  bundleEnrollmentState: defaultInitialState(),
}))();

export default function bundleReducer(state = initManageUserState, { type, payload, error }: any) {
  switch (type) {
    case bundleActions.FETCH_BUNDLE_DETAIL_SEO_SUCCESS:
      return successStateWithData(state, 'bundleDetailSeoState', payload);
    case bundleActions.FETCH_BUNDLE_DETAIL_SEO_ERROR:
      return failedState(state, 'bundleDetailSeoState', error);
    case bundleActions.FETCH_BUNDLE_DETAIL_SEO_RESET:
      return resetState(state, 'bundleDetailSeoState');

    case bundleActions.FETCH_BUNDLE_DETAIL_REQUEST:
      return loadingState(state, 'bundleDetailState');
    case bundleActions.FETCH_BUNDLE_DETAIL_SUCCESS:
      return successStateWithData(state, 'bundleDetailState', payload);
    case bundleActions.FETCH_BUNDLE_DETAIL_ERROR:
      return failedState(state, 'bundleDetailState', error);
    case bundleActions.FETCH_BUNDLE_DETAIL_RESET:
      return resetState(state, 'bundleDetailState');

    case bundleActions.FETCH_BUNDLE_COURSES_REQUEST:
      return loadingState(state, 'bundleCoursesState');
    case bundleActions.FETCH_BUNDLE_COURSES_SUCCESS:
      return successStateWithData(state, 'bundleCoursesState', payload);
    case bundleActions.FETCH_BUNDLE_COURSES_ERROR:
      return failedState(state, 'bundleCoursesState', error);
    case bundleActions.FETCH_BUNDLE_COURSES_RESET:
      return resetState(state, 'bundleCoursesState');

    case bundleActions.FETCH_BUNDLE_ENROLLMENT_REQUEST:
      return loadingState(state, 'bundleEnrollmentState');
    case bundleActions.FETCH_BUNDLE_ENROLLMENT_SUCCESS:
      return successStateWithData(state, 'bundleEnrollmentState', payload);
    case bundleActions.FETCH_BUNDLE_ENROLLMENT_ERROR:
      return failedState(state, 'bundleEnrollmentState', error);
    case bundleActions.FETCH_BUNDLE_ENROLLMENT_RESET:
      return resetState(state, 'bundleEnrollmentState');

    default:
      return state;
  }
}
