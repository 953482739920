import { Record } from 'immutable';

import actions from './actions';

import { DegreeState } from 'src/types/degree';

const initState = new (Record<DegreeState>({
  my_degree_list: null,
  my_degree_list_loading: false,
}))();

export default function reducer(state = initState, { type, data, status }: any) {
  switch (type) {
    case actions.FETCH_MY_DEGREE_LIST_REQUEST:
      return state.set('my_degree_list_loading', true);
    case actions.FETCH_MY_DEGREE_LIST_SUCCESS:
      return state.set('my_degree_list', data).set('my_degree_list_loading', false);
    case actions.FETCH_MY_DEGREE_LIST_ERROR:
      return state.set('my_degree_list', null).set('my_degree_list_loading', false);

    default:
      return state;
  }
}
