import config from 'src/config';
import Http from 'src/helpers/httpClient';
import { IdOrIdName } from 'src/types/common';

const querySearchMyDegreeList = (query: any) => {
  const { universityId } = query;
  let queryString = '';

  if (universityId) {
    queryString += `universityId=${encodeURIComponent(universityId)}&`;
  }

  return queryString;
};

const degreeApi = {
  fetchMyDegreeList(query?: any) {
    let queryString = '';
    if (query) {
      queryString = querySearchMyDegreeList(query);
    }
    const apiUrl = `${config.API_DOMAIN}/v1/degrees/my-degrees?${queryString}`;
    return Http.get(apiUrl);
  },
  getDegreeStatus(degreeIdName: IdOrIdName) {
    const apiUrl = `${config.API_DOMAIN}/v1/degrees/${degreeIdName}`;

    return Http.get(apiUrl);
  },
};

export default degreeApi;
