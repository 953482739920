import httpClient, { RequestConfig } from '@helpers/httpClient';
import { ICoreApiResponse } from '@interfaces/common';
import {
  DeleteUserIdentityDocumentRequestAction,
  DeleteUserIdentityDocumentStatusResponse,
  DeleteUserIdentityDocumentSuccessAction,
  DeleteUserIdentityFaceRequestAction,
  DeleteUserIdentityFaceStatusResponse,
  DeleteUserIdentityFaceSuccessAction,
  ManageIdentityFaceUploadRequestAction,
  ManageIdentityFaceUploadStatusResponse,
  ManageIdentityFaceUploadSuccessAction,
  ManageIdentityDocumentConfirmationErrorAction,
  ManageIdentityDocumentConfirmationRequestAction,
  ManageIdentityDocumentConfirmationSuccessAction,
  ManageIdentityDocumentExtractionErrorAction,
  ManageIdentityDocumentExtractionRequestAction,
  ManageIdentityDocumentExtractionSuccessAction,
  ManageIdentityImageValidationErrorAction,
  ManageIdentityImageValidationRequestAction,
  ManageIdentityImageValidationStatusResponse,
  ManageIdentityImageValidationSuccessAction,
  ManageUserIdentityRequestAction,
  ManageUserIdentityStatusResponse,
  ManageUserIdentitySuccessAction,
  UpdateUserIdentityDocumentRequestAction,
  UpdateUserIdentityDocumentStatusResponse,
  UpdateUserIdentityDocumentSuccessAction,
} from '@redux/manageUserIdentity/type';
import config from 'src/config';

const manageUserIdentityAPI = {
  adminGetUserIdentityInformation: async (
    payload: ManageUserIdentityRequestAction,
  ): Promise<ICoreApiResponse<ManageUserIdentitySuccessAction, ManageUserIdentityStatusResponse>> => {
    const apiUrl = new URL(`/v1/manage/users/${payload.userId}/identity-informations`, config.API_DOMAIN);
    return httpClient
      .get(apiUrl.toString())
      .then((res) => res.data)
      .catch((error) => error);
  },

  adminDeleteUserIdentityFace: async (
    payload: DeleteUserIdentityFaceRequestAction,
  ): Promise<ICoreApiResponse<DeleteUserIdentityFaceSuccessAction, DeleteUserIdentityFaceStatusResponse>> => {
    const apiUrl = new URL(
      `/v1/manage/users/identity-information/identity-face/${payload.userIdentityFaceId}`,
      config.API_DOMAIN,
    );
    return httpClient
      .delete(apiUrl.toString())
      .then((res) => res.data)
      .catch((error) => error);
  },

  adminDeleteUserIdentityDocument: async (
    payload: DeleteUserIdentityDocumentRequestAction,
  ): Promise<ICoreApiResponse<DeleteUserIdentityDocumentSuccessAction, DeleteUserIdentityDocumentStatusResponse>> => {
    const apiUrl = new URL(
      `/v1/manage/users/identity-information/identity-document/${payload.userDocumentIdentityId}`,
      config.API_DOMAIN,
    );
    return httpClient
      .delete(apiUrl.toString())
      .then((res) => res.data)
      .catch((error) => error);
  },

  adminUpdateIdentityDocumentInformation: async (
    payload: UpdateUserIdentityDocumentRequestAction,
  ): Promise<ICoreApiResponse<UpdateUserIdentityDocumentSuccessAction, UpdateUserIdentityDocumentStatusResponse>> => {
    const { userDocumentIdentityId, ...data } = payload;
    const apiUrl = new URL(
      `/v1/manage/users/identity-information/identity-document/${userDocumentIdentityId}`,
      config.API_DOMAIN,
    );
    return httpClient
      .put(apiUrl.toString(), data)
      .then((res) => res.data)
      .catch((error) => error);
  },
  adminIdentityImageValidation: async (
    payload: ManageIdentityImageValidationRequestAction,
  ): Promise<
    ICoreApiResponse<ManageIdentityImageValidationSuccessAction, ManageIdentityImageValidationErrorAction>
  > => {
    const apiUrl = new URL('/v1/manage/users/identity-verifications', config.API_DOMAIN);
    const requestConfig: RequestConfig = {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    };
    const formData = new FormData();
    formData.append('image', payload.image);
    formData.append('imageType', payload.imageType);
    formData.append('userId', payload.userId.toString());
    return httpClient
      .post(apiUrl.toString(), formData, requestConfig)
      .then((res) => res.data)
      .catch((error) => error);
  },

  adminUploadUserIdentityFaceImage: async (
    payload: ManageIdentityFaceUploadRequestAction,
  ): Promise<ICoreApiResponse<ManageIdentityFaceUploadSuccessAction, ManageIdentityFaceUploadStatusResponse>> => {
    const apiUrl = new URL('/v1/manage/users/identity-information/identity-face', config.API_DOMAIN);
    return httpClient
      .post(apiUrl.toString(), payload)
      .then((res) => res.data)
      .catch((error) => error);
  },
  adminIdentityDocumentExtraction: async (
    payload: ManageIdentityDocumentExtractionRequestAction,
  ): Promise<
    ICoreApiResponse<ManageIdentityDocumentExtractionSuccessAction, ManageIdentityDocumentExtractionErrorAction>
  > => {
    const apiUrl = new URL('/v1/manage/users/identity-extractions', config.API_DOMAIN);
    return httpClient
      .post(apiUrl.toString(), payload)
      .then((res) => res.data)
      .catch((error) => error);
  },
  adminIdentityDocumentConfirmation: async (
    payload: ManageIdentityDocumentConfirmationRequestAction,
  ): Promise<
    ICoreApiResponse<ManageIdentityDocumentConfirmationSuccessAction, ManageIdentityDocumentConfirmationErrorAction>
  > => {
    const apiUrl = new URL('/v1/manage/users/identity-confirmations', config.API_DOMAIN);
    return httpClient
      .post(apiUrl.toString(), payload)
      .then((res) => res.data)
      .catch((error) => error);
  },
};

export default manageUserIdentityAPI;
