import { Record } from 'immutable';

import actions from './actions';

import { UniversityState } from 'src/types/university';

const initState = new (Record<UniversityState>({
  university_filter: null,
  university_filter_loading: false,
}))();

export default function reducer(state = initState, { type, data, status }: any) {
  switch (type) {
    case actions.FETCH_UNIVERSITY_FILTER_REQUEST:
      return state.set('university_filter_loading', true);
    case actions.FETCH_UNIVERSITY_FILTER_SUCCESS:
      return state.set('university_filter', data).set('university_filter_loading', false);
    case actions.FETCH_UNIVERSITY_FILTER_ERROR:
      return state.set('university_filter', null).set('university_filter_loading', false);

    default:
      return state;
  }
}
