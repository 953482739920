import { all, call, takeEvery, put, fork } from 'redux-saga/effects';

import actions from './actions';
import universityAPI from './api';

import { ICoreApiResponse } from '@interfaces/common';
import { ResponseData, ResponseDataV2 } from 'src/helpers/httpClient';

export function* fetch_university_filter() {
  yield takeEvery(actions.FETCH_UNIVERSITY_FILTER_REQUEST, function*() {
    const apiResponse: ResponseDataV2<ICoreApiResponse<any, any>> = yield call(universityAPI.fetcUniversityFilter);
    if (apiResponse.data?.status === '000') {
      yield put({
        type: actions.FETCH_UNIVERSITY_FILTER_SUCCESS,
        data: apiResponse.data.data.list,
        status: apiResponse.data.status,
      });
    } else {
      yield put({
        type: actions.FETCH_UNIVERSITY_FILTER_ERROR,
      });
    }
  });
}

export default function* rootSaga() {
  yield all([fork(fetch_university_filter)]);
}
