import {
  FetchBundleCoursesErrorAction,
  FetchBundleCoursesRequestAction,
  FetchBundleCoursesSuccessAction,
  FetchBundleDetailErrorAction,
  FetchBundleDetailRequestAction,
  FetchBundleDetailSeoErrorAction,
  FetchBundleDetailSeoSuccessAction,
  FetchBundleDetailSuccessAction,
  FetchBundleEnrollmentErrorAction,
  FetchBundleEnrollmentRequestAction,
  FetchBundleEnrollmentSuccessAction,
} from '@redux/bundle/type/BundleDetailType';

const bundleActions = {
  FETCH_BUNDLE_DETAIL_SEO_SUCCESS: 'BUNDLE_DETAIL/FETCH_BUNDLE_DETAIL_SEO_SUCCESS',
  FETCH_BUNDLE_DETAIL_SEO_ERROR: 'BUNDLE_DETAIL/FETCH_BUNDLE_DETAIL_SEO_ERROR',
  FETCH_BUNDLE_DETAIL_SEO_RESET: 'BUNDLE_DETAIL/FETCH_BUNDLE_DETAIL_SEO_RESET',
  fetchBundleDetailSeoAction: {
    success: (payload: FetchBundleDetailSeoSuccessAction) => ({
      type: bundleActions.FETCH_BUNDLE_DETAIL_SEO_SUCCESS,
      payload,
    }),
    error: (payload: FetchBundleDetailSeoErrorAction) => ({
      type: bundleActions.FETCH_BUNDLE_DETAIL_SEO_ERROR,
      payload,
    }),
    reset: () => ({
      type: bundleActions.FETCH_BUNDLE_DETAIL_SEO_RESET,
    }),
  },

  FETCH_BUNDLE_DETAIL_REQUEST: 'BUNDLE_DETAIL/FETCH_BUNDLE_DETAIL_REQUEST',
  FETCH_BUNDLE_DETAIL_SUCCESS: 'BUNDLE_DETAIL/FETCH_BUNDLE_DETAIL_SUCCESS',
  FETCH_BUNDLE_DETAIL_ERROR: 'BUNDLE_DETAIL/FETCH_BUNDLE_DETAIL_ERROR',
  FETCH_BUNDLE_DETAIL_RESET: 'BUNDLE_DETAIL/FETCH_BUNDLE_DETAIL_RESET',
  fetchBundleDetailAction: {
    request: (payload: FetchBundleDetailRequestAction) => ({
      type: bundleActions.FETCH_BUNDLE_DETAIL_REQUEST,
      payload,
    }),
    success: (payload: FetchBundleDetailSuccessAction) => ({
      type: bundleActions.FETCH_BUNDLE_DETAIL_SUCCESS,
      payload,
    }),
    error: (payload: FetchBundleDetailErrorAction) => ({
      type: bundleActions.FETCH_BUNDLE_DETAIL_ERROR,
      payload,
    }),
    reset: () => ({
      type: bundleActions.FETCH_BUNDLE_DETAIL_RESET,
    }),
  },

  FETCH_BUNDLE_COURSES_REQUEST: 'BUNDLE_DETAIL/FETCH_BUNDLE_COURSES_REQUEST',
  FETCH_BUNDLE_COURSES_SUCCESS: 'BUNDLE_DETAIL/FETCH_BUNDLE_COURSES_SUCCESS',
  FETCH_BUNDLE_COURSES_ERROR: 'BUNDLE_DETAIL/FETCH_BUNDLE_COURSES_ERROR',
  FETCH_BUNDLE_COURSES_RESET: 'BUNDLE_DETAIL/FETCH_BUNDLE_COURSES_RESET',
  fetchBundleCoursesAction: {
    request: (payload: FetchBundleCoursesRequestAction) => ({
      type: bundleActions.FETCH_BUNDLE_COURSES_REQUEST,
      payload,
    }),
    success: (payload: FetchBundleCoursesSuccessAction) => ({
      type: bundleActions.FETCH_BUNDLE_COURSES_SUCCESS,
      payload,
    }),
    error: (payload: FetchBundleCoursesErrorAction) => ({
      type: bundleActions.FETCH_BUNDLE_COURSES_ERROR,
      payload,
    }),
    reset: () => ({
      type: bundleActions.FETCH_BUNDLE_COURSES_RESET,
    }),
  },

  FETCH_BUNDLE_ENROLLMENT_REQUEST: 'BUNDLE_DETAIL/FETCH_BUNDLE_ENROLLMENT_REQUEST',
  FETCH_BUNDLE_ENROLLMENT_SUCCESS: 'BUNDLE_DETAIL/FETCH_BUNDLE_ENROLLMENT_SUCCESS',
  FETCH_BUNDLE_ENROLLMENT_ERROR: 'BUNDLE_DETAIL/FETCH_BUNDLE_ENROLLMENT_ERROR',
  FETCH_BUNDLE_ENROLLMENT_RESET: 'BUNDLE_DETAIL/FETCH_BUNDLE_ENROLLMENT_RESET',
  fetchBundleEnrollmentAction: {
    request: (payload: FetchBundleEnrollmentRequestAction) => ({
      type: bundleActions.FETCH_BUNDLE_ENROLLMENT_REQUEST,
      payload,
    }),
    success: (payload: FetchBundleEnrollmentSuccessAction) => ({
      type: bundleActions.FETCH_BUNDLE_ENROLLMENT_SUCCESS,
      payload,
    }),
    error: (payload: FetchBundleEnrollmentErrorAction) => ({
      type: bundleActions.FETCH_BUNDLE_ENROLLMENT_ERROR,
      payload,
    }),
    reset: () => ({
      type: bundleActions.FETCH_BUNDLE_ENROLLMENT_RESET,
    }),
  },
};

export default bundleActions;
