const appActions = {
  FETCH_APP_DATA_REQUEST: 'FETCH_APP_DATA_REQUEST',
  FETCH_APP_DATA_SUCCESS: 'FETCH_APP_DATA_SUCCESS',
  FETCH_APP_DATA_ERROR: 'FETCH_APP_DATA_ERROR',

  FETCH_B2B_CONTENT_STATISTIC_REQUEST: 'FETCH_B2B_CONTENT_STATISTIC_REQUEST',
  FETCH_B2B_CONTENT_STATISTIC_SUCCESS: 'FETCH_B2B_CONTENT_STATISTIC_SUCCESS',
  FETCH_B2B_CONTENT_STATISTIC_ERROR: 'FETCH_B2B_CONTENT_STATISTIC_ERROR',

  FETCH_WATERMARK_PERIOD_REQUEST: 'FETCH_WATERMARK_PERIOD_REQUEST',
  FETCH_WATERMARK_PERIOD_SUCCESS: 'FETCH_WATERMARK_PERIOD_SUCCESS',
  FETCH_WATERMARK_PERIOD_ERROR: 'FETCH_WATERMARK_PERIOD_ERROR',

  SET_IS_USER_MENU_DRAWER_OPEN: 'SET_IS_USER_MENU_DRAWER_OPEN',

  SET_IS_NOTIFICATION_DIALOG_OPEN: 'SET_IS_NOTIFICATION_DIALOG_OPEN',

  SET_IS_LOADING_FIRST_PAGE: 'SET_IS_LOADING_FIRST_PAGE',

  SET_IS_DIALOG_OPEN: 'SET_IS_DIALOG_OPEN',

  TOGGLE_ADMIN_MENU_SIDER_COLLAPSED: 'TOGGLE_ADMIN_MENU_SIDER_COLLAPSED',
  TOGGLE_ADMIN_MENU_SIDER_OPEN_DRAWER: 'TOGGLE_ADMIN_MENU_SIDER_OPEN_DRAWER',

  fetch_app_data: () => ({
    type: appActions.FETCH_APP_DATA_REQUEST,
  }),

  fetch_b2b_content_statistic: () => ({
    type: appActions.FETCH_B2B_CONTENT_STATISTIC_REQUEST,
  }),

  fetch_watermark_period: () => ({
    type: appActions.FETCH_WATERMARK_PERIOD_REQUEST,
  }),

  set_user_menu_drawer_open: (isOpen: boolean) => ({
    type: appActions.SET_IS_USER_MENU_DRAWER_OPEN,
    payload: isOpen,
  }),

  set_notification_dialog_open: (isOpen: boolean) => ({
    type: appActions.SET_IS_NOTIFICATION_DIALOG_OPEN,
    payload: isOpen,
  }),

  set_is_loading_first_page: (loading: boolean) => ({
    type: appActions.SET_IS_LOADING_FIRST_PAGE,
    payload: loading,
  }),

  set_is_dialog_open: (isOpen: boolean) => ({
    type: appActions.SET_IS_DIALOG_OPEN,
    payload: isOpen,
  }),

  toggleAdminMenuSiderCollapsed: (isCollapsed: boolean) => ({
    type: appActions.TOGGLE_ADMIN_MENU_SIDER_COLLAPSED,
    payload: {
      isCollapsed,
    },
  }),

  toggleAdminMenuSiderDrawer: (isOpenDrawer: boolean) => ({
    type: appActions.TOGGLE_ADMIN_MENU_SIDER_OPEN_DRAWER,
    payload: {
      isOpenDrawer,
    },
  }),
};
export default appActions;
