import { pickBy } from 'lodash';

import config from 'src/config';
import Http from 'src/helpers/httpClient';
import { IdOrIdName } from 'src/types/common';

const END_POINT = 'v1/degree';

export const DEGREE_END_POINT = {
  COURSES: (idName: IdOrIdName) => `${END_POINT}/${idName}/degree-course`,
};

export default {
  fetchDegreeCourses(idName: IdOrIdName, params: Record<string, unknown>) {
    const apiUrl = `${config.API_DOMAIN}/${DEGREE_END_POINT.COURSES(idName)}`;

    return Http.get(apiUrl, { isPublic: true, params: pickBy(params, (v) => !!v) });
  },
};
